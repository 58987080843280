import { useState, useMemo, useCallback, useRef, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  Circle,
  MarkerClusterer,
} from "@react-google-maps/api";
// import Places from "./places";
// import Distance from "./distance";

export default function Map({ outTracking, retTracking }) {
  const [office, setOffice] = useState();
  const [trackingDirections, setTrackingDirections] = useState();
  const [directions, setDirections] = useState();
  const mapRef = useRef();
  const center = useMemo(() => ({ lat: 42.39, lng: -72.52 }), []);
  const options = useMemo(
    () => ({
      mapId: "b181cac70f27f5e6",
      disableDefaultUI: true,
      clickableIcons: false,
    }),
    []
  );
  const onLoad = useCallback((map) => (mapRef.current = map), []);
//   const houses = useMemo(() => generateHouses(center), [center]);

  useEffect(() => {
    const waypts = [];
    let originObj = {};
    let destinationObj = {};
    if( retTracking.length === 0 ){
      for (let i = 0; i < outTracking.length; i++) {
        if (i === 0) {

        // if (outTracking[i].detailed_status === "Shipment information sent to FedEx") {
          originObj.lat = outTracking[i].latitude;
          originObj.lng = outTracking[i].longitude;
        }
        if (i === outTracking.length - 1) {
          destinationObj.lat = outTracking[i].latitude;
          destinationObj.lng = outTracking[i].longitude;
        } else if (outTracking[i].detailed_status === "Arrived at FedEx location") {
          let obj = {
            location: {},
          };
          obj.location.lat = outTracking[i].latitude;
          obj.location.lng = outTracking[i].longitude;
          obj.stopover = true;
          waypts.push(obj);
        }
      }
    } else {
      for (let i = 0; i < retTracking.length; i++) {
        if (i === 0) {

        // if (retTracking[i].detailed_status === "Shipment information sent to FedEx") {
          originObj.lat = retTracking[i].latitude;
          originObj.lng = retTracking[i].longitude;
        }
        if (i === retTracking.length - 1) {
          destinationObj.lat = retTracking[i].latitude;
          destinationObj.lng = retTracking[i].longitude;
        } else if (retTracking[i].detailed_status === "Arrived at FedEx location") {
          let obj = {
            location: {},
          };
          obj.location.lat = retTracking[i].latitude;
          obj.location.lng = retTracking[i].longitude;
          obj.stopover = true;
          waypts.push(obj);
        }
      }
    }
    if (waypts.length === 0) return;

    const serv = new window.google.maps.DirectionsService();
    console.log(originObj, destinationObj, waypts)
    serv
      .route({
        origin: originObj,
        destination: destinationObj,
        waypoints: waypts,
        optimizeWaypoints: true,
        travelMode: window.google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        setDirections(response);
      })
      .catch((e) =>
       window.alert("Directions request failed due to " + e));
  }, []);


  return (
      <div className="map">
        <GoogleMap
          zoom={3}
          center={center}
          mapContainerClassName="tracking_details_map"
          // options={options}
          onLoad={onLoad}
        >
          {directions && (
            <DirectionsRenderer
              directions={directions}
              options={{
                polylineOptions: {
                  zIndex: 50,
                  strokeColor: "#1976D2",
                  strokeWeight: 5,
                },
              }}
            />
          )}
        </GoogleMap>
      </div>
  );
}