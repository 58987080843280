import axios from "axios";
import * as helper from "./serviceHelper";
import * as env from "./env";

const endpoint = env.url

let saveLocations = (id) => {

  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/address?userId=${id}`,
    withCredentials: false,
    crossdomain: true,
    headers: {"Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

export { saveLocations }