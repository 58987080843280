import axios from "axios";
import * as helper from "./serviceHelper";
import * as env from "./env";
const onGlobalSuccess = (response) => {
  /// Should not use if you need access to anything other than the data
  return response.data;
};

const onGlobalError = (err) => {
  return Promise.reject(err);
};

const endpoint = env.url

let login = (payload) =>{
    const config = {
      data: payload,
      method: "POST",
      url: "https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/auth/login",
      withCredentials: false,
      crossdomain: true,
      headers: { "Content-Type": "application/json", Accept: "application/json" }
    };
    return axios(config).then(onGlobalSuccess).catch(onGlobalError);
}

let getCurrent = (token) => {
  const config = {
    method: "GET",
    url: "https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/current",
    withCredentials: false,
    crossdomain: true,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

let authorizeUser = (token) =>{
  const config = {
    method: "GET",
    url: "https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/auth/me",
    withCredentials: false,
    crossdomain: true,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

let forgotPassword = (email) => {
  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:6hazS0TY/auth/magic-link?email=${email}`,
    withCredentials: false,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json", Accept: "application/json"
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let resetPassword = (token) => {
  const config = {
    method: "POST",
    url: "https://xehi-05cq-aqc1.n7.xano.io/api:6hazS0TY/auth/magic-login",
    withCredentials: false,
    crossdomain: true,
    data: token,
    headers: {
      "Content-Type": "application/json", Accept: "application/json"
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let getAddresses = (userId) => {
  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/address?userId=${userId}`,
    withCredentials: false,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json", Accept: "application/json"
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let createAddress = (payload) => {
  const config = {
    method: "POST",
    url: "https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/address/save",
    withCredentials: false,
    crossdomain: true,
    data: payload,
    headers: {
      "Content-Type": "application/json", Accept: "application/json"
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let organizationUsers = (id) => {
  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/users/organization/${id}`,
    withCredentials: false,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json", Accept: "application/json"
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let userDetails = (id) => {
  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/user/details/${id}`,
    withCredentials: false,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json", Accept: "application/json"
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let updateDetails = (payload, id, token) =>{
  const config = {
    data: payload,
    method: "POST",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/users/${id}`,
    withCredentials: false,
    crossdomain: true,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
}

let create = (payload, token) =>{
  const config = {
    data: payload,
    method: "POST",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/users`,
    withCredentials: false,
    crossdomain: true,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
}

let pageViews = (organization_id, token) => {
  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/settings/${organization_id}`,
    withCredentials: false,
    crossdomain: true,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let updateColumnVisibility = (payload, token) => {
  const config = {
    method: "POST",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/column_visibility/users/${payload.user_id}`,
    withCredentials: false,
    crossdomain: true,
    data: payload,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let columnVisibility = (user_id, token) => {
  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/column_visibility/users/${user_id}`,
    withCredentials: false,
    crossdomain: true,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let settings = (user_id, payload, token) =>{
  const config = {
    data: payload,
    method: "POST",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/settings/users/${user_id}`,
    withCredentials: false,
    crossdomain: true,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
}

let getSettings = (user_id, token) =>{
  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/settings/users/${user_id}`,
    withCredentials: false,
    crossdomain: true,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
}

export { login, getSettings, settings, authorizeUser, create, getCurrent, forgotPassword, organizationUsers, resetPassword, 
  getAddresses, updateDetails, createAddress, pageViews, userDetails, updateColumnVisibility, columnVisibility };