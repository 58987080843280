import { Wrapper } from "@googlemaps/react-wrapper";
import React, { useState } from "react";

const markers = [
  { lat: -25.363, lng: 131.044 },
  { lat: -15.363, lng: 122.044 },
];

const Map = ({ onClick, onIdle, children, outTracking, style, ...options }) => {
  const ref = React.useRef(null);
  const [map, setMap] = useState();

  React.useEffect(() => {
    if (ref.current && !map) {
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer();

      setMap(new window.google.maps.Map(ref.current, {}));

      const waypts = [];
      let originObj = {};
      let destinationObj = {};
      for (let i = 0; i < outTracking.length; i++) {
        if ((i === 0)) {
          originObj.lat = outTracking[i].latitude;
          originObj.lng = outTracking[i].longitude;
        }
        if ((i === outTracking.length - 1)) {
            destinationObj.lat = outTracking[i].latitude;
            destinationObj.lng = outTracking[i].longitude;
        } else if(i !== outTracking.length - 1 && i !== 0) {
          let obj = {
            location:{}
          };
          obj.location.lat = outTracking[i].latitude;
          obj.location.lng = outTracking[i].longitude;
          obj.stopover = true;
          waypts.push(obj);
        }
      }

      directionsService
        .route({
          origin: originObj,
          destination: destinationObj,
          waypoints: waypts,
          optimizeWaypoints: true,
          travelMode: window.google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
          setMap(directionsRenderer.setDirections(response));
        })
        .catch((e) => window.alert("Directions request failed due to " + e));
    }
  }, [ref, map]);

  React.useEffect(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  React.useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker = (options) => {
  const [marker, setMarker] = useState();
  const contentRef = React.useRef(null);

  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      const infowindow = new window.google.maps.InfoWindow({
        content: `daver`,
      });
      marker.setOptions(options);

      marker.addListener("click", () => {
        infowindow.open({
          anchor: marker,
          shouldFocus: false,
        });
      });
    }
  }, [marker, options]);

  return null;
};

function MapContainer({ returnData, outTracking }) {
  const [returnTracking] = useState(returnData);
  const [out_Tracking] = useState(outTracking);

  return (
    <div style={{ position: "relative", height: "300px", width: "100%" }}>
      <Wrapper apiKey={"AIzaSyCTa5kFhOMR5nKhKwpKZFI5Ei-b0jffuL4"}>
        <Map
          returnTracking={returnTracking}
          outTracking={out_Tracking}
          center={{ lat: -25.363, lng: 131.044 }}
          zoom={3}
          style={{ flexGrow: "1", height: "100%" }}
        >
          {/* {markers.map((marker) => {
            return <Marker position={marker} />;
          })} */}
        </Map>
      </Wrapper>
    </div>
  );
}

export default MapContainer;
