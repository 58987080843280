import React from "react";
import "@fortawesome/fontawesome-free/css/all.css"
import { Row, Container, Col } from "react-bootstrap";

const AddedDevice = (props) => {
    const onHandleDelete = () => {
        props.onHandleDelete(props)
    }

    return (
        <React.Fragment>
            <tr>
                <td><span className="text-limit">{props.devices.device}</span></td>
                <td>{props.devices.type}</td>
                <td className="quantity">{props.devices.qty}</td>
                <td key={props.devices.id} onClick={onHandleDelete}><i className="far fa-times-circle"></i></td>
            </tr>
        </React.Fragment>
    )
}

export default AddedDevice;