import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Col } from "shards-react";

import SidebarMainNavbar from "./SidebarMainNavbar.jsx";
import SidebarNavItems from "./SidebarNavItems.jsx";

import { Store } from "../../../flux";
import ProgramView from "./ProgramView.jsx";
import DashboardStore from "../../../flux/DashboardStore.js";

class MainSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuVisible: false,
      SidebarNavItems: "",
      currentUser: []
    };

    // this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    // console.log("DidMount", this.props, this.state)
    this.onChange()
    // Store.addChangeListener(this.onChange);
  }

  componentDidUpdate = (prevState) =>{
    // console.log(prevState, "DidUpdate", this.props, this.state)
}

  componentWillUnmount() {
    // Store.removeChangeListener(this.onChange);
  }

  onChange = () => {
    // this.props.currentUser.role === "Admin" ? this.getAdminSidebarItems() :
    // this.props.currentUser.role === "Standard User" ? this.getStandardSidebarItems() :
    // this.props.currentUser.role === "Super" ? this.getSuperSidebarItems() : console.log(this.props, "error sideBar")
    this.getStandardSidebarItems();
  }

  getAdminSidebarItems = () =>{
    this.setState({  
      ...this.state,
        menuVisible: Store.getMenuState(),
        SidebarNavItems: Store.getAdminSidebarItems()
      });
  }

  getStandardSidebarItems = () =>{
    this.setState({  
      ...this.state,
        menuVisible: Store.getMenuState(),
        SidebarNavItems: Store.getStandardSidebarItems()
      });
  }

  getSuperSidebarItems = () =>{
    this.setState({  
      ...this.state,
        menuVisible: Store.getMenuState(),
        SidebarNavItems: Store.getSuperSidebarItems()
      });
  }

  render() {
    const classes = classNames(
      "main-sidebar",
      "px-0",
      "col-12",
      this.state.menuVisible && "open"
    );

    return (
      <Col
        tag="aside"
        className={classes}
        lg={{ size: 2 }}
        md={{ size: 3 }}
      >
        <SidebarMainNavbar hideLogoText={this.props.hideLogoText} />
        <SidebarNavItems currentUser={this.props.currentUser}/>
        <ProgramView currentUser={this.props.currentUser}/>
      </Col>
    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: false
};

export default MainSidebar;
