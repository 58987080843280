import React from "react";

export function Support() {
    return (
        <React.Fragment>
            <div id="audit_container" className="container-fluid moblyz_table">
                <h2>Support</h2>
                <h4>We're here to help</h4>
                {/* <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://forms.monday.com/forms/6cb487de0dd7dec58a68995900ba2093?r=use1' sandbox='allow-scripts allow-modal' />"}} /> */}
                <iframe src="https://forms.monday.com/forms/embed/6cb487de0dd7dec58a68995900ba2093?r=use1" width="1050" height="1200" sandbox='allow-scripts allow-modal' />
                <p>We're also available at <a href="mailto:support@moblyz.com">support@moblyz.com</a>.</p>
            </div>
        </React.Fragment>
    )
}