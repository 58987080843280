import React from "react";
import { Row, Col } from "react-bootstrap";
import ProgramAlert from "../features/program/ProgramAlert.jsx";
import Tracking from "../features/tracking/Tracking";
import DashboardStore from "../../flux/DashboardStore.js";
import * as DashboardActions from "../../flux/DashboardActions.js";
import Request from "../features/request/Request.jsx";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";

import "./share-components.css";
import Pickup from "../features/request/Pickup.jsx";
import PrintLabel from "../features/request/PrintLabel.jsx";
import ActivityOverview from "../features/home/ActivityOverview.jsx";
import { Link } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);
const locale = "en-US";
const dollarUSLocale = Intl.NumberFormat(locale);

class Overview extends React.Component {
  constructor(props) {
    super(props);
    // this.getPrograms = this.getPrograms.bind(this);

    this.state = {
      currentUser: "",
      orgInfo: "",
      chartData: false,
      device_count: 0,
      energy_saved: 0,
      total_value: 0,
      snapshot: [
        {
          gradeData: {
            // labels: ['A', 'B', 'C', 'D', 'F', 'S'],
            datasets: [
              {
                label: "Values Recovered",
                data: [2, 5, 3],
                backgroundColor: ["#B3FFAD", "#45E5C5", "#00C5DB"],
                borderColor: ["transparent"],
              },
            ],
          },
          countData: {
            // labels: ['A', 'B', 'C', 'D', 'F', 'S'],
            datasets: [
              {
                label: "Devices Recovered",
                data: [2, 5, 3, 7, 4],
                backgroundColor: ["#6782C2", "#9983C7", "#C583C1", "#E786B2", "#FF8F9E"],
                borderColor: ["transparent"],
              },
            ],
          },
          totalData: {
            // labels: ['A', 'B', 'C', 'D', 'F', 'S'],
            datasets: [
              {
                label: "Energy Saved",
                data: [2, 5],
                backgroundColor: ["#E2FF6E", "#6AE7A3"],
                borderColor: ["transparent"],
              },
            ],
          },
        },
      ],
      program: [],
    };
  }

  componentDidMount = () => {
    console.log(this.props.location, this.state, "didMount");
    //Method is called from Login used info is passed by Props
    if (this.props.currentUser !== undefined) {
      this.propsCurrentUser();
      this.setSnapshot();
    } //Method is called as refresh it gets user info from store
    else if (this.props === null) {
      this.getCurrentUser();
    }
    // DashboardStore.on("CHANGE", this.getPrograms);
    DashboardStore.on("CHANGEUSERSETTINGS", this.apiCallSnapshot);
    DashboardStore.on("CHANGESNAPSHOT", this.setSnapshot);
  };

  componentDidUpdate = (prevProps, prevState) => {
    console.log(this.props, prevProps, prevState, this.state, "update");
    console.log(DashboardStore.getUserSettings);
  };

  componentWillUnmount = () => {
    // console.log("WillUnMount", this.props, this.stata)
    DashboardStore.removeListener("CHANGEUSERSETTINGS", this.apiCallSnapshot);
    // DashboardStore.removeListener("CHANGE", this.getPrograms);
    DashboardStore.removeListener("CHANGESNAPSHOT", this.setSnapshot);
  };

  propsCurrentUser = () => {
    this.setState({
      currentUser: this.props.currentUser,
    });
  };

  apiCallSnapshot = () => {
    console.log(DashboardStore.getUserSettings()?.active_program_id);
    if (DashboardStore.getUserSettings()?.active_program_id !== 0) {
      let program_id = DashboardStore.getUserSettings()?.active_program_id;
      DashboardActions.apiCallGetSnapshot(program_id);
    }
  };
//   getPrograms = () => {
//     this.setState({
//       program: DashboardStore.getPrograms(),
//     });
//   };

  getCurrentUser = () => {
    let storeData = DashboardStore.getUserInfo();
    this.setState({
      currentUser: storeData[0].store,
    });
  };

  setSnapshot = () => {
    let data = DashboardStore.getSnapshot();
    //Code below added for refres of page
    if (data !== undefined) {
      this.getSnapshotSuccess(data);
      this.setState({
        chartData: true,
      });
    } else if ( data === undefined){
      this.apiCallSnapshot()
    }
  };

  num = (e) => {
    return 1;
  };

  getSnapshotSuccess = (res) => {
    let e = res;

    let grade = {
      // labels: ['A', 'B', 'C', 'D', 'F', 'S'],
      datasets: [
        {
          // data: [e.a_count, e.b_count, e.c_count, e.d_count, e.f_count, e.s_count],
          // backgroundColor: ["#B3FFAD", "#45E5C5", "#00C5DB", "#00A0DD", "#4C77C2"],
          data: [e.a_count, e.b_count, e.c_count],
          backgroundColor: ["#B3FFAD", "#45E5C5", "#00C5DB"],
          borderColor: ["transparent"],
        },
      ],
    };
    let count = {
      // labels: ['Desktop', 'Laptop', 'Other', 'Phone', 'Tablet'],
      datasets: [
        {
          data: [e.desktop_count, e.laptop_count, e.other_count, e.phone_count, e.tablet_count],
          backgroundColor: ["#6782C2", "#9983C7", "#C583C1", "#E786B2", "#FF8F9E"],
          borderColor: ["transparent"],
        },
      ],
    };

    let total = {
      // labels: ['Processing', 'Counted'],
      datasets: [
        {
          data: [e.device_expected - e.device_count, e.device_count],
          backgroundColor: ["#E2FF6E", "#6AE7A3"],
          borderColor: ["transparent"],
        },
      ],
    };
    this.setState((prevState) => {
      return {
        ...prevState,
        device_count: e.device_count,
        energy_saved: e.energy_saved,
        total_value: e.total_value,
        package_transit: e.package_transit,
        snapshot: {
          gradeData: grade,
          countData: count,
          totalData: total,
        },
      };
    });
  };

  getSnapshotError = (e) => {
    console.log(e);
  };

  render() {
    return (
      <React.Fragment>
        <div id="home" className="container-fluid">
          <Row>
            <Col sm={9} className="content">
              <Row>
                <Col>{/* <ProgramAlert currentUser={this.state.currentUser} /> */}</Col>
              </Row>
              <Row xs="auto">
                <Col sm={4}>
                  <div className="graph-div">
                    <div className="upper-div">
                      <div className="left-inner-icon">
                        <h3 className="icomoon white icon-1">
                          <br />
                        </h3>
                      </div>
                    </div>
                    <div className="heading-content-div vertical">
                      <p className="paragraph _00">
                        Value Recovered
                        <br />
                      </p>
                      <h4 id="snapinfoVR" className="heading-4 up-0 down--32">
                        ${dollarUSLocale.format(this.state.total_value)}
                      </h4>
                    </div>
                    <h3 className="icomoon _00 blue-primary">
                      <br />
                    </h3>
                    <div className="graph-placeholder">
                      <div className="w-embed">
                        <div className="chartholder-3" id="canvas-holderG">
                          <div className="chartjs-size-monitor">
                            <div className="chartjs-size-monitor-expand">
                              <div className=""></div>
                            </div>
                            <div className="chartjs-size-monitor-shrink">
                              <div className=""></div>
                            </div>
                          </div>
                          {/* <Doughnut data={this.state.snapshot.gradeData} className="chart" /> */}
                          {this.state.chartData === true ? (
                            <Doughnut data={this.state.snapshot.gradeData} className="chart" />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="legends">
                      <div className="legends-main-div">
                        <div className="legends-circle _1-1"></div>
                        <p className="paragraph _00 _12">
                          A<br />
                        </p>
                      </div>
                      <div className="legends-main-div">
                        <div className="legends-circle _1-2"></div>
                        <p className="paragraph _00 _12">
                          B<br />
                        </p>
                      </div>
                      <div className="legends-main-div">
                        <div className="legends-circle _1-3"></div>
                        <p className="paragraph _00 _12">
                          C<br />
                        </p>
                      </div>
                      {/* <div className="legends-main-div">
                        <div className="legends-circle _1-4"></div>
                        <p className="paragraph _00 _12">
                          D<br />
                        </p>
                      </div>
                      <div className="legends-main-div">
                        <div className="legends-circle _1-5"></div>
                        <p className="paragraph _00 _12">
                          F<br />
                        </p>
                      </div> */}
                    </div>
                    <div className="bottom-div">
                      {/* <a className="audit-white-button w-inline-block" onClick={this.handleAuditBtn}> */}
                      <div className="audit-report-button button">
                        <Link to="/audit">
                          <p className="paragraph-12 _00 right-10 blue">
                            Audit Report
                            <br />
                          </p>
                        </Link>
                        <p className="icomoon arrow-icon">
                          <br />
                        </p>
                      </div>
                      {/* </a> */}
                    </div>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="graph-div">
                    <div className="upper-div">
                      <div className="left-inner-icon pink">
                        <h3 className="icomoon white icon-1">
                          <br />
                        </h3>
                      </div>
                    </div>
                    <div className="heading-content-div vertical">
                      <p className="paragraph _00">
                        Devices Recovered
                        <br />
                      </p>
                      <h4 id="snapinfoDR" className="heading-4 up-0 down--32">
                        {this.state.device_count}
                      </h4>
                    </div>
                    <h3 className="icomoon _00 hot-pink">
                      <br />
                    </h3>
                    <div className="graph-placeholder pink">
                      <div className="w-embed">
                        <div className="chartholder-3" id="canvas-holderT">
                          <div className="chartjs-size-monitor">
                            <div className="chartjs-size-monitor-expand">
                              <div className=""></div>
                            </div>
                            <div className="chartjs-size-monitor-shrink">
                              <div className=""></div>
                            </div>
                          </div>
                          {this.state.chartData === true ? (
                            <Doughnut data={this.state.snapshot.countData} className="chart" />
                          ) : (
                            ""
                          )}

                          {/* <Doughnut data={this.state.snapshot.countData} className="chart" /> */}
                          {/* <canvas id="chart-T" width="294" height="147" className="chartjs-render-monitor"></canvas> */}
                        </div>
                      </div>
                    </div>
                    <div className="legends">
                      <div className="legends-main-div">
                        <div className="legends-circle _2-1"></div>
                        <p className="paragraph _00 _12">
                          phone
                          <br />
                        </p>
                      </div>
                      <div className="legends-main-div">
                        <div className="legends-circle _2-2"></div>
                        <p className="paragraph _00 _12">
                          tablet
                          <br />
                        </p>
                      </div>
                      <div className="legends-main-div">
                        <div className="legends-circle _2-3"></div>
                        <p className="paragraph _00 _12">
                          laptop
                          <br />
                        </p>
                      </div>
                      <div className="legends-main-div">
                        <div className="legends-circle _2-4"></div>
                        <p className="paragraph _00 _12">
                          desktop
                          <br />
                        </p>
                      </div>
                      <div className="legends-main-div">
                        <div className="legends-circle _2-5"></div>
                        <p className="paragraph _00 _12">
                          other
                          <br />
                        </p>
                      </div>
                    </div>
                    <div className="bottom-div">
                      {/* <a className="audit-white-button w-inline-block" onClick={this.handleAuditBtn}> */}
                      <div className="audit-report-button button">
                        <Link to="/audit">
                          <p className="paragraph-12 _00 right-10 blue">
                            Audit Report
                            <br />
                          </p>
                        </Link>
                        <p className="icomoon arrow-icon">
                          <br />
                        </p>
                      </div>
                      {/* </a> */}
                    </div>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="graph-div">
                    <div className="upper-div">
                      <div className="left-inner-icon green">
                        <h3 className="icomoon white icon-1">
                          <br />
                        </h3>
                      </div>
                    </div>
                    <div className="heading-content-div vertical">
                      <p className="paragraph _00">
                        Energy Saved (kwh)
                        <br />
                      </p>
                      <h4 id="snapinfoES" className="heading-4 up-0 down--32">
                        {this.state.energy_saved}
                      </h4>
                    </div>
                    <h3 className="icomoon _00 medium-spring">
                      <br />
                    </h3>
                    <div className="graph-placeholder green">
                      <div className="w-embed w-script">
                        <div className="chartholder-3" id="canvas-holder">
                          <div className="chartjs-size-monitor">
                            <div className="chartjs-size-monitor-expand">
                              <div className=""></div>
                            </div>
                            <div className="chartjs-size-monitor-shrink">
                              <div className=""></div>
                            </div>
                          </div>
                          {this.state.chartData === true ? (
                            <Doughnut data={this.state.snapshot.totalData} className="chart" />
                          ) : (
                            ""
                          )}
                          {/* <canvas id="chart-RR" className="chartjs-render-monitor"></canvas> */}
                        </div>
                      </div>
                    </div>
                    <div className="legends">
                      <div className="legends-main-div">
                        <div className="legends-circle _3-1"></div>
                        <p className="paragraph _00 _12">
                          reused
                          <br />
                        </p>
                      </div>
                      <div className="legends-main-div">
                        <div className="legends-circle _3-2"></div>
                        <p className="paragraph _00 _12">
                          recycled
                          <br />
                        </p>
                      </div>
                    </div>
                    <div className="bottom-div">
                      {/* <a className="audit-white-button w-inline-block" onClick={this.handleEnvBtn}> */}
                      <div className="audit-report-button button">
                        <Link to="/environmental">
                          <p className="paragraph-12 _00 right-10 blue">Envi. Impact Report</p>
                        </Link>
                        <p className="icomoon arrow-icon">
                          <br />
                        </p>
                      </div>
                      {/* </a> */}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <ActivityOverview />
              </Row>
            </Col>
            <Col sm={3}>
              <div className="graph-div last-div">
                <div className="upper-div">
                  <div className="left-inner-icon blue">
                    <h3 className="icomoon white icon-1">
                      <br />
                    </h3>
                  </div>
                </div>
                <div className="heading-content-div">
                  <p className="paragraph _00 down-32">
                    In Transit
                    <br />
                  </p>
                </div>
                <div className="div-block-4">
                  <p className="shipment-placeholder">
                    <span>
                      <strong id="snapinfoIT">{this.state.package_transit}</strong>{" "}
                    </span>
                    {this.state.package_transit > 1 ? "Shipments" : "Shipment"}
                  </p>
                </div>
                <div className="bottom-div">
                  <a href="/tracking" className="audit-white-button w-inline-block">
                    <div className="audit-report-button button">
                      <p className="paragraph-12 _00 right-10 blue">View Tracking</p>
                      <p className="icomoon arrow-icon">
                        <br />
                      </p>
                    </div>
                  </a>
                </div>
              </div>
              <div className="quick-links-div blue">
                <Request currentUser={this.state.currentUser} />
                <Pickup currentUser={this.state.currentUser} />
                <PrintLabel currentUser={this.state.currentUser} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mini">
                <h2>Recent Activity</h2>
                <Tracking mini="1" />
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
export default Overview;
