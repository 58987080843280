import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { InputGroup, DatePicker, InputGroupAddon, InputGroupText } from "shards-react";
import Select from "react-select";
import * as addressServices from "../../../services/addressServices.js";
import { Formik, Field } from "formik";
import "./audit.css";
import moment from "moment";

class AuditFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        tracking_reference: "",
        tracking_number: "",
        serial_number: "",
      },
      addressOptions: [],
      conditionOptions: [
        { value: 1, label: "A" },
        { value: 2, label: "B" },
        { value: 3, label: "C" },
        { value: 4, label: "D" },
        { value: 5, label: "F" },
      ],
      deviceOptions: [
        { value: 1, label: "Laptop" },
        { value: 2, label: "Phone" },
        { value: 3, label: "Desktop" },
        { value: 4, label: "Other" },
      ],
      lockOptions: [
        { value: 1, label: "Locked" },
        { value: 2, label: "Unlocked" },
      ],
      grade: "",
      device_type_id: 0,
      address_id: 0,
      location_name:"",
      lock: "",
      shipping_status_id: 0,
      start_date: "",
      end_date: "",
    };
  }

  componentDidMount = () => {
    // console.log(this.props, this.state, "didMount")
    this.getSavedLocation();
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    // console.log(prevProps, prevState, snapshot, "update")
  };

  getSavedLocation = () => {
    let id = this.props.userId;
    addressServices.saveLocations(id).then(this.onLocationSuccess).catch(this.onLocationError);
  };

  onLocationSuccess = (response) => {
      console.log(response, "RESPONSE")
    let data = response.addresses;
    let mappedAddress = data.map((item, index) => {
      return <option key={item.id} value={item.location_name}>{item.location_name}</option>;
    });
    this.setState({
      addressOptions: mappedAddress,
    });
  };

  onLocationError = (e) => {
    console.log(e);
  };

  handleStartDateChange = (value) => {
    console.log(value, "startDate");
    this.setState({
      start_date: value,
    });
  };

  handleEndDateChange = (value) => {
    this.setState({
      end_date: value,
    });
  };

  handleAddressChange = (selectedOption) => {
    this.setState({ location_name: selectedOption.target.value });
  };

  handleConditionChange = (selectedOption) => {
    this.setState({ grade: selectedOption.target.value });
  };

  handleDeviceChange = (selectedOption) => {
    this.setState({ device_type_id: selectedOption.target.value });
  };

  handleLockChange = (selectedOption) => {
    this.setState({ lock: selectedOption.target.value });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => {
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      };
    });
  };

  handleSubmit = () => {
    console.log(this.state.grade.length, "Grade")
    let filters = {
      start_date: this.state.start_date !== "" ? moment(this.state.start_date).format("l") : "",
      end_date: this.state.end_date !== "" ? moment(this.state.end_date).format("l") : "",
      location_name: this.state.location_name,
      tracking_reference: this.state.formData.tracking_reference,
      tracking_number: this.state.formData.tracking_number,
      grade: this.state.grade,
      serial_number: this.state.formData.serial_number,
      device_type_id: this.state.device_type_id,
      lock: this.state.lock,
    };
    this.props.onHandleFilters(filters);
  };

  render() {
    return (
      <React.Fragment>
        <div className="audit-filters">
          <Formik
            enableReinitialize={true}
            initialValues={this.state.formData}
            onSubmit={this.handleSubmit}
          >
            {(props) => {
              const { values, touched, errors, handleSubmit, isValid, isSubmitting } = props;
              return (
                <div className="modal-body">
                  <div className="request-packaging-shipping-label w-form">
                    <form
                      id="wf-form-Report-Filters"
                      name="wf-form-Report-Filters"
                      data-name="Report Filters"
                      method="get"
                      className="form registration _00"
                      aria-label="Report Filters"
                    >
                      <div className="w-layout-grid input-fields-grid three up-extra">
                        <div>
                          <label for="Shipped-from">Shipped from</label>
                          {/* <Select
                            //value={this.state.address_id}
                            placeholder="Saved Loations"
                            onChange={this.handleAddressChange}
                            options={this.state.addressOptions}
                            isSearchable={true}
                            className="select-2 w-select"
                            id="location-input"
                          /> */}
                          <select
                            id="Status-3"
                            name="Status-3"
                            data-name="Status 3"
                            className="select w-select"
                            defaultValue={this.props.location_name}
                            onChange={this.handleAddressChange}
                          >
                            <option value="0">Saved Locations Options</option>
                            {this.state.addressOptions}
                          </select>
                          {/* <select id="Shipped-from" name="Shipped-from" data-name="Shipped From" className="select-2 w-select">
                                                <option value="Saved locations">- Saved locations -</option>
                                                <option value="Option # 1">Option # 1</option>
                                                <option value="Option # 2">Option # 2</option>
                                            </select> */}
                        </div>
                        <div>
                          <label for="Start-Date">Audited within Start Date</label>
                          <DatePicker
                            selected={this.state.start_date}
                            onChange={this.handleStartDateChange}
                            placeholderText={
                              this.props.start_date.length !== 0
                                ? `${this.props.start_date}`
                                : "mm/dd/yyyy"
                            }
                            dropdownMode="select"
                            className="input-field inline-label w-input"
                          />
                          {/* <input type="text" className="input-field-2 w-input" maxlength="256" name="Start-Date" data-name="Start Date" placeholder="" id="Start-Date"/> */}
                        </div>
                        <div>
                          <label for="End-Date">End Date</label>
                          <br />
                          <DatePicker
                            selected={this.state.end_date}
                            onChange={this.handleEndDateChange}
                            placeholderText={
                              this.props.end_date !== "" ? this.props.end_date : "mm/dd/yyyy"
                            }
                            dropdownMode="select"
                            className="input-field inline-label w-input"
                          />
                          {/* <input type="text" className="input-field-2 w-input" maxlength="256" name="End-Date" data-name="End Date" placeholder="" id="End-Date"/> */}
                        </div>
                        <div>
                          <label for="Condition">
                            <strong>Condition</strong>
                          </label>
                          {/* <Select
                            //value={this.state.address_id}
                            placeholder="Grade"
                            onChange={this.handleConditionChange}
                            options={this.state.conditionOptions}
                            className="select-2 w-select"
                            // isSearchable={true}
                          /> */}

                          <select
                            id="Condition"
                            defaultValue={this.props.grade}
                            name="Condition"
                            data-name="Condition"
                            onChange={this.handleConditionChange}
                            className="select-2 w-select"
                          >
                            <option value="Grade">Grade</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                            <option value="F (Scrap)">F (Scrap)</option>
                          </select>
                        </div>
                        <div>
                          <label for="Device-type">
                            <strong>Device type</strong>
                            <br />
                          </label>
                          {/* <Select
                            //value={this.state.address_id}
                            placeholder="Device Type"
                            onChange={this.handleDeviceChange}
                            options={this.state.deviceOptions}
                            className="select-2 w-select"
                            // isSearchable={true}
                          /> */}
                          <select
                            id="Device-type"
                            name="Device-type"
                            data-name="Device Type"
                            className="select-2 w-select"
                            onChange={this.handleDeviceChange}
                            defaultValue={this.props.device_type_id}
                          >
                            <option value="0">Device Type</option>
                            <option value="1">Laptop</option>
                            <option value="2">Phone</option>
                            <option value="3">Desktop</option>
                            <option value="4">Tablet</option>
                          </select>
                        </div>
                        <div>
                          <label for="Lock-status">
                            <strong>Lock status</strong>
                            <br />
                          </label>
                          {/* <Select
                            placeholder="Lock Status"
                            onChange={this.handleLockChange}
                            options={this.state.lockOptions}
                            className="select-2 w-select"
                          /> */}
                          <select
                            id="Lock-status"
                            name="Lock-status"
                            data-name="Lock Status"
                            className="select-2 w-select"
                            onChange={this.handleLockChange}
                          >
                            <option value="0">Select lock status</option>
                            <option value="1">Unlocked</option>
                            <option value="2">Locked</option>
                          </select>
                        </div>
                        <div>
                          <label for="Tracking-Number">
                            <strong>Tracking Number</strong>
                            <br />
                          </label>
                          <Form.Group className="" controlId="">
                            <Field
                              type="text"
                              name="tracking_number"
                              values={values.tracking_number}
                              onChange={this.handleChange}
                              placeholder={this.props.tracking_number.length !== 0 ? this.props.tracking_number : "Tracking Number"}
                              className="input-field-2 w-input"
                            />
                            {errors.tracking_number && touched.tracking_number && (
                              <span className="input-feedback">{errors.tracking_number}</span>
                            )}
                          </Form.Group>
                          {/* <input type="text" className="input-field-2 w-input" maxlength="256" name="Tracking-Number" data-name="Tracking Number" placeholder="Tracking Number" id="Tracking-Number"/> */}
                        </div>
                        <div>
                          <label for="Device-serial-number">
                            <strong>Device serial number</strong>
                            <br />
                          </label>
                          <Form.Group className="" controlId="">
                            <Field
                              type="text"
                              name="serial_number"
                              values={values.serial_number}
                              onChange={this.handleChange}
                              placeholder={this.props.serial_number.length !== 0 ? this.props.serial_number : "Device Serial Number"}
                              className="input-field-2 w-input"
                            />
                            {errors.serial_number && touched.serial_number && (
                              <span className="input-feedback">{errors.serial_number}</span>
                            )}
                          </Form.Group>

                          {/* <input type="text" className="input-field-2 w-input" maxlength="256" name="Device-serial-number" data-name="Device Serial Number" placeholder="Serial Number" id="Device-serial-number"/> */}
                        </div>
                        <div>
                          <label for="Tracking-Reference">
                            <strong>Tracking Reference</strong>
                            <br />
                          </label>
                          <Form.Group className="" controlId="">
                            <Field
                              type="text"
                              name="tracking_reference"
                              values={values.tracking_reference}
                              onChange={this.handleChange}
                              placeholder={this.props.tracking_reference.length !== 0 ? this.props.tracking_reference : "Tracking Reference"}
                              className="input-field-2 w-input"
                            />
                            {errors.tracking_reference && touched.tracking_reference && (
                              <span className="input-feedback">{errors.tracking_reference}</span>
                            )}
                          </Form.Group>
                          {/* <input type="text" className="input-field-2 w-input" maxlength="256" name="Tracking-Reference" data-name="Tracking Reference" placeholder="Reference note or number" id="Tracking-Reference"/> */}
                        </div>
                      </div>
                      <button
                        id="submit"
                        // type="submit"
                        type="button"
                        onClick={this.handleSubmit}
                        // name="submit"
                        // disabled={!isValid || isSubmitting}
                        className="primary-button-2 full form-button w-button"
                      >
                        Search
                      </button>
                      {/* <input type="submit" value="Search" data-wait="Please wait..." className="primary-button-2 full form-button w-button"/> */}
                    </form>
                    <div
                      className="success-message-2 w-form-done"
                      tabindex="-1"
                      role="region"
                      aria-label="Report Filters success"
                    >
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div
                      className="w-form-fail"
                      tabindex="-1"
                      role="region"
                      aria-label="Report Filters failure"
                    >
                      <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

export default AuditFilters;
