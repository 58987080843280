import React, { Component } from 'react'
import { Container, Card, CardBody, CardFooter } from "shards-react";
import { Modal } from "react-bootstrap"
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap"
import { Formik, Field } from "formik"
import * as yup from 'yup';
import "./pickup.css"
import AddedDevice from './AddedDevice';
import * as requestServices from "../../../services/requestServices.js"
import DashboardStore from '../../../flux/DashboardStore';
import swal from 'sweetalert';
import { Address } from '../../shared-components/common/Address';

const schema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),

});

class Pickup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                address1: "",
                address2: "",
                city: "",
                state: "",
                postal_code: "",
                country: "",
                nickname: "",
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                loading_dock: false,
                cases: false,
                chargers: false,
                ready: false,
                reference: "",
            },
            verifyAddress: false,
            agree: false,
            location_name: "",
            pickupModal: false,
            device: "",
            qty: "",
            type: "",
            devices_array: [],
            currentId: 0,
            mappedDevices: [],
            options: [],
            deviceOptions: [
                { id: "0", value: "0", name: "Select type" },
                { id: "DO1", value: "Phone", name: "Phone" },
                { id: "DO2", value: "Tablet", name: "Tablet" },
                { id: "DO3", value: "Laptop", name: "Laptop" },
                { id: "DO4", value: "Desktop", name: "Desktop" },
                { id: "DO5", value: "Accessory Boxes", name: "Accessory Boxes" },

            ]
        }
    }

    componentDidMount = () => {
        // console.log(this.props.location, this.state, "didMount")
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        // console.log(prevProps, prevState, snapshot, this.props, "update")
    }

    componentWillUnmount = () => {
        // console.log("WillUnMount", this.props, this.stata)
    }

    getDeviceOptions = () => {
        this.setState({
            deviceOptions: [
                { id: "0", value: "0", name: "Select type" },
                { id: "DO1", value: "Phone", name: "Phone" },
                { id: "DO2", value: "Tablet", name: "Tablet" },
                { id: "DO3", value: "Laptop", name: "Laptop" },
                { id: "DO4", value: "Desktop", name: "Desktop" },
                { id: "DO5", value: "Accessory Boxes", name: "Accessory Boxes" },

            ],
            options: [],
            qty: ""
        })
    }

    getTabletType = () => {
        this.setState({
            options: [
                { id: "0", value: "0", name: "Size/Model" },
                { id: "T1", value: "Regular", name: "Regular" },
                { id: "T2", value: "Large", name: "Large" }
            ]

        })
    }

    getLaptopType = () => {
        this.setState({
            options: [
                { id: "0", name: "Size/Model" },
                { id: "L0", name: '11" Laptop' },
                { id: "L1", name: '13" Laptop' },
                { id: "L2", name: '15" Laptop' },
            ]

        })
    }

    getDesktopType = () => {
        this.setState({
            options: [
                { id: "0", name: "Size/Model" },
                { id: "D1", name: '21" iMac' },
                { id: "D2", name: '24" iMac' },
                { id: "D3", name: '27" iMac' },
                { id: "D4", name: 'Mac Mini' }
            ]

        })
    }

    getPhoneType = () => {
        this.setState({
            options: [
                { id: "0", name: "Size/Model" },
                { id: "P1", name: "Standard" },
                { id: "P2", name: "Extra Large" }
            ],
            //type: "Default"

        })
    }

    getDisplayType = () => {
        this.setState({
            options: [
                { id: "0", name: "Size/Model" },
                { id: "Dis1", name: '27"' },
                { id: "Dis2", name: 'Less than 27"' }
            ]

        })
    }

    getOtherType = () => {
        this.setState({
            options: [
                { id: "0", name: "Choose items..." },
                { id: "Other1", name: 'ULine 24x20x16' },
                { id: "Other2", name: 'ULine 24x20x12' },
                { id: "Other3", name: 'ULine 18x18x18' }

            ]

        })
    }

    deviceChosen = (e) => {
        let value = e.target.value;

        if (value === "Tablet") {
            this.getTabletType()
        } else if (value === "Laptop") {
            this.getLaptopType()
        } else if (value === "Desktop") {
            this.getDesktopType()
        } else if (value === "Phone") {
            this.getPhoneType()
        } else if (value === "Display") {
            this.getDisplayType()
        } else if (value === "Accessory Boxes") {
            this.getOtherType()
        } else if (value === "Select type") {
            this.getDeviceOptions()
        }

        this.setState({
            device: value
        })
    }

    handleType = (e) => {

        let value = e.target.value;
        this.setState({
            type: value
        })
    }

    handleQty = (e) => {
        let value = e.target.value;

        this.setState({
            qty: value,
            //btnEnable: false
        })
    }

    addDevice = (e) => {
        let id = this.state.currentId + 1

        let devices = {
            id: id,
            device: this.state.device,
            type: this.state.type,
            qty: parseInt(this.state.qty)
        }

        let newArray = [...this.state.devices_array, devices]
        this.getAddedDevices(newArray)
        this.setState({
            devices_array: newArray,
            device: "",
            options: [],
            type: "",
            qty: "",
            currentId: id,
            deviceOptions: [
                { id: "0", value: "0", name: "Select type" },
                { id: "DO1", value: "Phone", name: "Phone" },
                { id: "DO2", value: "Tablet", name: "Tablet" },
                { id: "DO3", value: "Laptop", name: "Laptop" },
                { id: "DO4", value: "Desktop", name: "Desktop" },
                { id: "DO5", value: "Accessory Boxes", name: "Accessory Boxes" },

            ]
        })
        document.getElementById('selectDevice').selectedIndex = 0;
        document.getElementById('qtybox').value = 0;
    }

    getAddedDevices = (devicesArray) => {
        let data = devicesArray;
        this.setState({
            mappedDevices: data.map(this.mapDevice)
        })
    }

    mapDevice = data => (
        <AddedDevice
            mapped={this.state.mappedDevices}
            key={data.id}
            devices={data}
            onHandleDelete={this.onHandleDelete}
        />
    );

    onHandleDelete = (e) => {
        let id = e.devices.id;
        let currentDeviceCards = this.state.mappedDevices
        let currentDevices = this.state.devices_array

        let newDeviceCards = currentDeviceCards.filter(data => data.props.devices.id != id)
        let newDevices = currentDevices.filter(data => data.id != id)

        this.setState({
            mappedDevices: newDeviceCards,
            devices_array: newDevices
        })
    }

    handlePickupModal = () => {
        this.setState({
            pickupModal: !this.state.pickupModal,
            // formData: {},
            verifyAddress: false,
        })
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        this.setState((prevState) => {
            return {
                ...prevState,
                formData: {
                    ...prevState.formData,
                    [name]: value,
                },
            };
        });
    }

    onSubmitRequest = (e) => {
        let programId = DashboardStore.getUserSettings().active_program_id
        let payload = {
            program_id: programId,
            request_type_id: 3,
            reference: this.state.formData.reference,
            address: {
                location_name: this.state.location_name,
                address1: this.state.formData.address1,
                address2: this.state.formData.address2,
                city: this.state.formData.city,
                state: this.state.formData.state,
                postal_code: this.state.formData.postal_code,
                country: this.state.formData.country
            },
            contact_list: [
                {
                    first_name: this.state.formData.first_name,
                    last_name: this.state.formData.last_name,
                    email: this.state.formData.email,
                    phone: this.state.formData.phone,
                }
            ],
            pickup_info: {
                loading_dock: this.state.formData.loading_dock,
                cases: this.state.formData.cases,
                chargers: this.state.formData.chargers,
                ready: this.state.formData.ready,
            },
            device_list: this.state.devices_array
        }
        // if(this.state.agree === true){

        requestServices
            .request(payload)
            .then(this.onRequestSucces)
            .catch(this.onRequestError)
        e.reset()
        // }

    }

    onRequestSucces = (e) => {
        swal({
            title: "Pick up Request Sent",
            // text: err,
            icon: "success",
            buttons: true
        })
        this.setState({
            pickupModal: false,
            formData: {},
            mappedDevices: [],
            devices_array: [],
            verifyAddress: false
        })
    }

    onRequestError = (e) => {
        swal({
            title: "Please try again!",
            // text: err,
            icon: "error",
            buttons: true,
        })
        this.setState({
            pickupModal: false,
            mappedDevices: [],
            devices_array: []
        })
    }

    onHandleLoqate = (e) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                formData: {
                    ...prevState.formData,
                    address1: e.Line1,
                    address2: e.Line2,
                    city: e.City,
                    state: e.ProvinceCode,
                    postal_code: e.PostalCode,
                    country: e.CountryIso2,
                    first_name: this.state.formData.first_name,
                    last_name: this.state.formData.last_name,
                    phone: this.state.formData.phone,
                    reference: this.state.formData.reference,
                    email: this.state.formData.email,
                    loading_dock: this.state.formData.loading_dock,
                    cases: this.state.formData.cases,
                    chargers: this.state.formData.chargers,
                    ready: this.state.formData.ready,
                },
                verifyAddress: true
            };
        });
    };

    handleAgreeCheck = (e) => {
        this.setState({
            agree: !this.state.agree
        })
    }

    render() {
        const { deviceOptions } = this.state;

        const deviceOptionList = deviceOptions.length > 0 && deviceOptions.map((item, i) => {
            return (
                <option key={i} value={item.name}>{item.name}</option>
            )
        }, this);

        const { options } = this.state;

        const optionsList = options.length > 0 && options.map((item, i) => {
            return (
                <option key={i} value={item.name}>{item.name}</option>
            )
        }, this);
        return (
            <React.Fragment>
                <button className="quick-links-inner-div" onClick={this.handlePickupModal}>
                    <div className="div-block-5">
                        <div className="transparent-inner-icon">
                            <h4 className="icomoon _00">&#xe953;</h4>
                        </div>
                        <div className="div-block-6">
                            <p className="paragraph _00 bold down-6">Request Pickup</p>
                            <p className="paragraph _00 left _14">We'll coordinate pickup and packaging of your devices.</p>
                        </div>
                    </div>
                    <div className="upper-div"></div>
                </button>
                <Modal
                    size="lg"
                    show={this.state.pickupModal}
                    onHide={this.handlePickupModal}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="transparent-inner-icon">
                                <h2 className="icomoon _00 print-label">&#xe953;</h2>
                                <h4>Pickup Team Request</h4>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            enableReinitialize={true}
                            validationSchema={schema}
                            initialValues={this.state.formData}
                            onSubmit={this.onSubmitRequest}
                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleSubmit,
                                    onSubmitRequest,
                                    isValid,
                                    isSubmitting,
                                    isInvalid
                                } = props;
                                return (
                                    <Form onSubmit={handleSubmit}>
                                        <div id="wf-form-Pickup-Team-Request" name="wf-form-Pickup-Team-Request" data-name="Pickup Team Request" className="form registration _00" aria-label="Pickup Team Request">
                                            <div className="form-heading-div flex form">
                                                <div className="form-flex">
                                                    <h5 className="form-heading">On-Site Pickup Information<br /></h5>
                                                </div>
                                            </div>
                                            <div className="w-layout-grid input-fields-grid">
                                                <div>
                                                    <label for="first_name">First Name</label>
                                                    <Field
                                                        name="first_name"
                                                        required
                                                        onChange={this.handleChange}
                                                        values={this.state.formData.first_name}
                                                        placeholder="First Name"
                                                        className="input-field w-input"
                                                    />
                                                </div>
                                                <div>
                                                    <label for="last_name">Last Name</label>
                                                    <Field
                                                        required
                                                        name="last_name"
                                                        onChange={this.handleChange}
                                                        values={this.state.formData.last_name}
                                                        placeholder="Last Name"
                                                        className="input-field w-input"
                                                    />
                                                </div>
                                                <div>
                                                    <label for="email">Email</label>
                                                    <Field
                                                        type="email"
                                                        name="email"
                                                        onChange={this.handleChange}
                                                        values={this.state.formData.email}
                                                        placeholder="name@company.com"
                                                        required
                                                        className="input-field w-input"
                                                    />
                                                </div>
                                                <div>
                                                    <label for="phone">Phone</label>
                                                    <Field
                                                        type="tel"
                                                        name="phone"
                                                        onChange={this.handleChange}
                                                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                                        values={this.state.formData.phone}
                                                        placeholder="### - ### - ####"
                                                        required
                                                        className="input-field w-input"
                                                    />
                                                </div>
                                                <div>
                                                    <label for="address1"><strong>Address</strong></label>
                                                    {this.state.verifyAddress === false ?
                                                        <Address
                                                            className="loqate-dropdowns"
                                                            required
                                                            value={this.state.formData.address1}
                                                            onHandleLoqate={this.onHandleLoqate}
                                                        />
                                                        :
                                                        <Field
                                                            name="address1"
                                                            onChange={this.handleChange}
                                                            values={values.address1}
                                                            placeholder="Address"
                                                            required
                                                            className="input-field w-input"
                                                        />
                                                    }
                                                </div>
                                                <div>
                                                    <label for="address2">Suite Number</label>
                                                    <Field
                                                        name="address2"
                                                        onChange={this.handleChange}
                                                        values={this.state.formData.address2}
                                                        placeholder="Suite/Unit"
                                                        className="input-field w-input"
                                                    />
                                                </div>
                                                <div>
                                                    <label for="city"><strong>City</strong><br /></label>
                                                    <Field
                                                        name="city"
                                                        onChange={this.handleChange}
                                                        values={this.state.formData.city}
                                                        placeholder="City"
                                                        required
                                                        className="input-field w-input"
                                                    />
                                                </div>
                                                <div>
                                                    <label for="state"><strong>State/Province</strong><br /></label>
                                                    <Field
                                                        name="state"
                                                        onChange={this.handleChange}
                                                        values={this.state.formData.state}
                                                        placeholder="State"
                                                        pattern="[A-Za-z]{2}"
                                                        required
                                                        className="input-field w-input"
                                                    />
                                                </div>
                                                <div>
                                                    <label for="postal_code"><strong>Postal Code</strong><br /></label>
                                                    <Field
                                                        // type="string"
                                                        name="postal_code"
                                                        onChange={this.handleChange}
                                                        values={this.state.formData.postal_code}
                                                        placeholder="Postal Code"
                                                        required
                                                        className="input-field w-input"
                                                    />
                                                </div>
                                                <div>
                                                    <label for="country" className="field-label">Country *</label>
                                                    <Field
                                                        name="country"
                                                        onChange={this.handleChange}
                                                        values={this.state.formData.country}
                                                        pattern="[A-Za-z]{2}"
                                                        placeholder="Country"
                                                        required
                                                        className="input-field w-input"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label for="Tracking-Reference-2">What type of devices are you sending and how many of each type?<br /></label>
                                                <form className="w-layout-grid input-fields-grid answer-spacing _1">
                                                    <div className="div-block-7 up-24">
                                                        <label for="location-3">Device Type</label>
                                                        <select className="select w-select" placeholder="Select Device Type" id="selectDevice" onChange={this.deviceChosen} required>
                                                            {deviceOptionList}
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <label for="location-3">Size Type</label>
                                                        <select className="select w-select" placeholder="" id="selectType" onChange={this.handleType} required>
                                                            {optionsList}
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <label for="Manufacturer-Name-3">Quantity</label>
                                                        {(this.state.device === "Choose...") ?
                                                            <input className="input-field w-input" type="number" placeholder="" id="qtybox" onChange={this.handleQty} required></input> :
                                                            <input className="input-field w-input" type="number" placeholder="" id="qtybox" onChange={this.handleQty} required></input>
                                                        }
                                                    </div>
                                                    <button id="btn_add" className="secondary-button save-form _2 w-button" data-verify="Submit" value="Submit" type="button" onClick={this.addDevice}>Add</button>
                                                </form>
                                                <table className="mapped_devices">
                                                    <th>Device Type</th><th>Size</th><th className="quantity">Quantity</th><th></th>
                                                    {this.state.mappedDevices}
                                                </table>
                                            </div>
                                            <div className="radio-div">
                                                <label for="cases">Are any devices in cases?<br /></label>
                                                <div className="form-flex">
                                                    <Form.Check
                                                        required
                                                        inline
                                                        label="Yes"
                                                        value={true}
                                                        onChange={this.handleChange}
                                                        name="cases"
                                                        type="radio"
                                                        id='inline-dock-1'
                                                    />
                                                    <Form.Check
                                                        required
                                                        inline
                                                        label="No"
                                                        value={false}
                                                        onChange={this.handleChange}
                                                        name="cases"
                                                        type="radio"
                                                        id='inline-dock-2'
                                                    />
                                                </div>
                                            </div>
                                            <div className="radio-div">
                                                <label for="chargers">Will you be including chargers with the devices?<br /></label>
                                                <div className="form-flex">
                                                    <Form.Check
                                                        required
                                                        inline
                                                        label="Yes"
                                                        value={true}
                                                        onChange={this.handleChange}
                                                        name="chargers"
                                                        type="radio"
                                                        id='inline-dock-1'
                                                    />
                                                    <Form.Check
                                                        required
                                                        inline
                                                        label="No"
                                                        value={false}
                                                        onChange={this.handleChange}
                                                        name="chargers"
                                                        type="radio"
                                                        id='inline-dock-2'
                                                    />
                                                </div>
                                            </div>
                                            <div className="radio-div">
                                                <label for="ready">Are all of the devices ready?<br /></label>
                                                <div className="form-flex">
                                                    <Form.Check
                                                        required
                                                        inline
                                                        label="Yes"
                                                        value={true}
                                                        onChange={this.handleChange}
                                                        name="ready"
                                                        type="radio"
                                                        id='inline-dock-1'
                                                    />
                                                    <Form.Check
                                                        required
                                                        inline
                                                        label="No"
                                                        value={false}
                                                        onChange={this.handleChange}
                                                        name="ready"
                                                        type="radio"
                                                        id='inline-dock-2'
                                                    />
                                                </div>
                                            </div>
                                            <div className="radio-div">
                                                <label for="loading_dock">Is there a loading dock at the pick-up location?<br /></label>
                                                <div className="form-flex">
                                                    <Form.Check
                                                        required
                                                        inline
                                                        label="Yes"
                                                        name="loading_dock"
                                                        onChange={this.handleChange}
                                                        value={true}
                                                        type="radio"
                                                        id='inline-dock-1'
                                                    />
                                                    <Form.Check
                                                        required
                                                        inline
                                                        label="No"
                                                        name="loading_dock"
                                                        onChange={this.handleChange}
                                                        value={false}
                                                        type="radio"
                                                        id='inline-dock-2'
                                                    />
                                                </div>
                                            </div>
                                            <label classname="w-checkbox checkbox-field">
                                                <div classname="w-checkbox-input w-checkbox-input--inputType-custom checkbox w--redirected-checked">
                                                    <input
                                                        type="checkbox"
                                                        id="agree"
                                                        required
                                                        name="agree"
                                                        value={this.state.agree}
                                                        onChange={this.handleAgreeCheck}
                                                    />
                                                    <span classname="checkbox-label w-form-label" htmlFor="agree">
                                                        I agree to review our device policy here, <a href="#">click here PDF</a>
                                                    </span>
                                                </div>
                                            </label>
                                            <button
                                                type="submit"
                                                id="submit"
                                                name="submit"
                                                disabled={!isValid || isSubmitting}
                                                data-wait="Please wait..."
                                                className="primary-button full form-button w-button">Request Pickup</button>
                                        </div>
                                    </Form>
                                )
                            }
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        )
    }
}

export default Pickup
