import React from "react";
import { Nav } from "shards-react";
import SidebarNavItem from "../mainSidebar/SidebarNavItem.js";
import { Store } from "../../../flux";
import DashboardStore from "../../../flux/DashboardStore.js";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      userNavItems: "",
      currentUser: []
    }
  }

  componentDidMount() {
    // console.log("DidMount", this.props, this.state)
    let currentUser = DashboardStore.getUserInfo()
    console.log(currentUser, "SideItems")
    this.setStandardSidebarItems();
    /* hide for now
    currentUser[0].store.role === "Admin" ? this.setAdminSidebarItems() :
      currentUser[0].store.role === "Standard User" ? this.setStandardSidebarItems() :
        currentUser[0].store.role === "Super" ? this.setSuperSidebarItems() : console.log("DidMount error sideBar")
        */

    // Store.addChangeListener(this.onChange);
    // this.onCurrentUser()
  }
  componentDidUpdate = (prevState) => {
    // console.log(prevState, "DidUpdate", this.props, this.state)
  }
  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
    // Store.removeChangeListener(this.onCurrentUser)
  }

  onChange = () => {
    this.props.currentUser.role === "Admin" ? this.setAdminSidebarItems() :
      this.props.currentUser.role === "Standard User" ? this.setStandardSidebarItems() :
        this.props.currentUser.role === "Super" ? this.setSuperSidebarItems() : console.log("error CHANGE sideBar")
  }

  setAdminSidebarItems = () => {
    this.setState({
      ...this.state,
      userNavItems: Store.getAdminSidebarItems()
    });
  }

  setStandardSidebarItems = () => {
    this.setState({
      ...this.state,
      userNavItems: Store.getStandardSidebarItems()
    });
  }

  setSuperSidebarItems = () => {
    this.setState({
      ...this.state,
      userNavItems: Store.getSuperSidebarItems()
    });
  }


  onCurrentUser = () => {
    let storeData = DashboardStore.getUserInfo()
    let userInfo = storeData[0].store
    this.setState({
      currentUser: userInfo
    })
  }

  render() {
    const { userNavItems: items } = this.state;
    return (
      <div className="nav-wrapper">
        {this.state.userNavItems.length > 0 ?
          <Nav className="nav--no-borders flex-column">
            {items.map((item, idx) => (
              <SidebarNavItem key={idx} item={item} />
            ))}
          </Nav> : <div></div>}

      </div>
    )
  }
}

export default SidebarNavItems;
