import React from 'react';
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import sampleImage from './logo192.png';
import './vendors.css';

const VendorRow = (props) => {
    const { id, name, description } = props.item;
  
    return (
        <Card className="vendor-card">
            <div className='row'>
                <div className="col-md-2">
                    <img src={sampleImage} alt="Sample Image" height="150" />
                </div>
                <div className="col-md-8 vendor-detail">
                    <Link to={`/vendors/${id}`}>{name}</Link>
                    <p>{description}</p>
                </div>
                
            </div>
        </Card>
    
    );
}

export default VendorRow;