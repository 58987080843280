import React, { Component } from "react";
import { Col, Card } from "react-bootstrap";
import { Timeline, TimelineEvent } from "@mailtop/horizontal-timeline";
import { FaBug, FaRegCalendarCheck, FaRegFileAlt } from "react-icons/fa";
import GoogleMapReact from "google-map-react";
import moment from "moment";
import { Container } from "shards-react";
import DetailsTrackingCard from "./DetailsTrackingCard.jsx";
import MapContainer from "./GoogleMapV2";
import { useLoadScript } from "@react-google-maps/api";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import WrapMap from "../../shared-components/common/WrapMap.jsx";

const SHIPPING_STATUS_EXCEPTION = 1;
const SHIPPING_STATUS_ARRIVED_AT_COUNTRY = 2;
const SHIPPING_STATUS_IN_TRANSIT1 = 3;
const SHIPPING_STATUS_PROBLEM_RESOLVED = 4;
const SHIPPING_STATUS_ARRIVAL_SCAN = 5;
const SHIPPING_STATUS_DELAYED_CUSTOMS = 6;
const SHIPPING_STATUS_ADDRESSEE_NOT_AVAILABLE = 7;
const SHIPPING_STATUS_RETURNING_TO_SENDER = 8;
const SHIPPING_STATUS_INFO_RECEIVED = 9;
const SHIPPING_STATUS_DEPARTURE_SCAN = 10;
const SHIPPING_STATUS_DELIVERED = 11;
const SHIPPING_STATUS_DELAYED_EXTERNAL = 12;
const SHIPPING_STATUS_AVILABLE_FOR_PICKUP = 13;
const SHIPPING_STATUS_OUT_FOR_DELIVERY = 14;
const SHIPPING_STATUS_ACCEPTANCE_SCAN = 15;
const SHIPPING_STATUS_IN_TRANSIT = 16;
const SHIPPING_STATUS_DELIVERY_SCHEDULED = 17;
const SHIPPING_STATUS_REQUEST_RECEIVED = 18;
const SHIPPING_STATUS_PACKAGING_ASSEMBLED = 19;

const BOX_STATUS_PACKAGING_REQUEST_RECEIVED = 1;
const BOX_STATUS_PACKAGING_ASSEMBLED = 9;
const BOX_STATUS_PACKAGING_IN_TRANSIT = 2;
const BOX_STATUS_PACKAGING_DELIVERED = 3;
const BOX_STATUS_RETURN_IN_TRANSIT = 4;
const BOX_STATUS_RETURN_DELIVERED = 5;
const BOX_STATUS_RETURN_REQUEST_RECEIVED = 6;
const BOX_STATUS_PROCESSED = 8;
const BOX_STATUS_PAID = 10;

const render = (status) => {
  if (status === Status.LOADING) return <p>Loading...</p>;
  if (status === Status.FAILURE) return <p>Error...</p>;
  return null;
};

export default class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      device_name: null,
      device_info: null,
      tracking_path: null,
      mappedOutbound: [],
      mappedReturn: [],
      contact_info: null,
      address: null,
      openOutbound: false,
      openReturn: false,
      device_list: [],
    };
  }
  static defaultProps = {
    center: { lat: 59.95, lng: 30.33 },
    zoom: 11,
  };

  componentDidMount = () => {
    const details = this.props.details;
    let device_name = null;
    let device_info = null;
    // get outbound package path
    const outbound_path = this.map_package_path(false, details._out_tracking_status);
    // get return package path
    const return_path = this.map_package_path(true, details._return_tracking_status);

    switch (details.device_list.length) {
      case 0:
        {
          if (details._request_device_quantity.length > 0) {
            
            const devices_request = details._request_device_quantity.map( (device_group) => {
              return `${device_group.quantity} x ${device_group.name} (${device_group.type})<br/>`;
            });
            device_info = devices_request.toString().replace(/,/g, '');
          }
          else {
            device_info = "Device information is not yet available.";
          }
          
          break;
        }
      case 1:
        device_name = details.device_list[0].model;
        device_info = `${details.device_list[0].serial_number}<br/><br/>${details.device_list[0].imei}`;
        break;
      default:
        device_info = `${details.device_list.length} devices processed`;
        break;
    }

    this.setState({
      tracking_path: new Map([...outbound_path, ...return_path]),
      address: details._request ? details._request.address : null,
      contact_info:
        details._request && details._request.contact_list ? details._request.contact_list[0] : null,
      device_list: details.device_list.map((device) => {
        return (
          <tr>
            <td>{device.model}</td>
            <td>{device.imei}</td>
            <td>{device.serial_number}</td>
            <td>{device.fmi}</td>
            <td>{device.grade}</td>
            <td>
              {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                device.value
              )}
            </td>
          </tr>
        );
      }),
      device_info: device_info,
      device_name: device_name
    });
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    console.log(prevProps, prevState, snapshot, "update");
  };

  onHandleDetails = (e) => {
    console.log(e, "Details");
  };

  /* 
    --------------------------------------------------------- 
    name:   map_package_path
    desc:   record package statuses and dates
    parms:
            is_return: is it a return path?
            tracking_list: list of tracking statuses
    return: mapped progress of the package
    ---------------------------------------------------------
     */
    map_package_path = (is_return, tracking_list) => {
        // if nothing to map, bail.
        if (tracking_list.length === 0 && is_return) {
            return new Map();
        }

    let result_package_path = new Map();

    // if it's an out-going route, then add Request Initiated and Packaging Assembled.
    if (!is_return && this.props.details.out_tracking_number) {
      result_package_path.set(BOX_STATUS_PACKAGING_REQUEST_RECEIVED, moment(this.props.details.created_at).format("L"));
      result_package_path.set(BOX_STATUS_PACKAGING_ASSEMBLED, moment(this.props.details.created_at).format("L"));
    }

    tracking_list.map((tracking_update) => {
      let box_status = 0;
      let recorded_in_transit_update = false;

      switch (tracking_update.shipping_status_id) {
        /*  we will revisit this logic later.  For now, we are going to force */
        // case SHIPPING_STATUS_REQUEST_RECEIVED:
        // case SHIPPING_STATUS_ARRIVAL_SCAN:
        // case SHIPPING_STATUS_ACCEPTANCE_SCAN:
        // case SHIPPING_STATUS_INFO_RECEIVED:
        //   box_status = is_return
        //     ? BOX_STATUS_RETURN_IN_TRANSIT
        //     : BOX_STATUS_PACKAGING_REQUEST_RECEIVED;
        //   break;
        // case SHIPPING_STATUS_PACKAGING_ASSEMBLED:
        //   box_status = BOX_STATUS_PACKAGING_ASSEMBLED;
        //   break;
        case SHIPPING_STATUS_DELIVERED:
          box_status = is_return ? BOX_STATUS_RETURN_DELIVERED : BOX_STATUS_PACKAGING_DELIVERED;
          break;
        default:
          // save the update if in-transit state has not already been recorded
          if (!recorded_in_transit_update) {
            box_status = is_return ? BOX_STATUS_RETURN_IN_TRANSIT : BOX_STATUS_PACKAGING_IN_TRANSIT;
            recorded_in_transit_update = true;
          }
          break;
      }
      if (box_status > 0) {
        result_package_path.set(box_status, moment(tracking_update.status_date).format("L"));
      }
    });
    // if a device has been processed from this package, use the process date of the 1st device
    if (this.props.details.device_list.length > 0) {
      result_package_path.set(
        BOX_STATUS_PROCESSED,
        moment(this.props.details.device_list[0].processed_date).format("L")
      );
    }
    return result_package_path;
  };

  handleOutbound = (e) => {
    const data = this.props.details._out_tracking_status;
    this.setState({
      mappedOutbound: data.map((tracking_update) => (
        <DetailsTrackingCard key={tracking_update.id} data={tracking_update} />
      )),
    });
    this.state.openOutbound = !this.state.openOutbound;
  };

  handleReturn = (e) => {
    const data = this.props.details._return_tracking_status;
    this.setState({
      mappedReturn: data.map((tracking_update) => (
        <DetailsTrackingCard key={tracking_update.id} data={tracking_update} />
      )),
    });
    this.state.openReturn = !this.state.openReturn;
  };

  //   render = (status: Status) => {
  //     return <h1>{status}</h1>;
  //   };

  render() {
    return (
      <React.Fragment>
        <div className="div-block-16">
          <div className="trackingmod w-clearfix">
            <div className="outroute">
              <div
                className={
                  (this.state.tracking_path &&
                  this.state.tracking_path.has(BOX_STATUS_PACKAGING_DELIVERED)
                    ? "highlighted"
                    : "") + " stepcircle step4 wf-section"
                }
              >
                <div className="statusta4">
                  Packaging delivered
                  <br />‍
                  <span className="statusdatetxt text-span-5">
                    {this.state.tracking_path &&
                    this.state.tracking_path.has(BOX_STATUS_PACKAGING_DELIVERED)
                      ? this.state.tracking_path.get(BOX_STATUS_PACKAGING_DELIVERED)
                      : ""}
                  </span>
                </div>
                <h2 className="icomoon _00 white-tracking">
                  <br />
                </h2>
              </div>
              <div
                className={
                  (this.state.tracking_path &&
                  this.state.tracking_path.has(BOX_STATUS_PACKAGING_IN_TRANSIT)
                    ? "highlighted"
                    : "") + " stepcircle step3 wf-section"
                }
              >
                <h2 className="icomoon _00 white-tracking">
                  <br />
                </h2>
                <div className="statusta3">
                  Packaging in route
                  <br />
                  <span className="statusdatetxt text-span-5">
                    {this.state.tracking_path &&
                    this.state.tracking_path.has(BOX_STATUS_PACKAGING_IN_TRANSIT)
                      ? this.state.tracking_path.get(BOX_STATUS_PACKAGING_IN_TRANSIT)
                      : ""}
                  </span>
                </div>
              </div>
              <div
                className={
                  (this.state.tracking_path &&
                  this.state.tracking_path.has(BOX_STATUS_PACKAGING_ASSEMBLED)
                    ? "highlighted"
                    : "") + " stepcircle step2 wf-section"
                }
              >
                <div className="statusta2">
                  Packaging assembled
                  <br />
                  <span className="statusdatetxt text-span-5">
                    {this.state.tracking_path &&
                    this.state.tracking_path.has(BOX_STATUS_PACKAGING_ASSEMBLED)
                      ? this.state.tracking_path.get(BOX_STATUS_PACKAGING_ASSEMBLED)
                      : ""}
                  </span>
                </div>
                <h2 className="icomoon _00 white-tracking">
                  <br />
                </h2>
              </div>
              <div
                className={
                  (this.state.tracking_path &&
                  this.state.tracking_path.has(BOX_STATUS_PACKAGING_REQUEST_RECEIVED)
                    ? "highlighted"
                    : "") + " stepcircle step1 wf-section"
                }
              >
                <div className="statustag">
                  Request initiated
                  <br />
                  <span className="statusdatetxt text-span-5">
                    {this.state.tracking_path &&
                    this.state.tracking_path.has(BOX_STATUS_PACKAGING_REQUEST_RECEIVED)
                      ? this.state.tracking_path.get(BOX_STATUS_PACKAGING_REQUEST_RECEIVED)
                      : ""}
                  </span>
                </div>
                <h2 className="icomoon _00 white-tracking">
                  <br />
                </h2>
              </div>
              <div className="outline6">
                <div className="topsteparrow" />
              </div>
              <div className="outline2 stepline" />
              <div className="outline4">
                <div className="outtracktag">
                  <span className="outtracktaglabel">Outbound Tracking #</span>
                  <br />
                  <span className="text-span outtracknumtxt">{this.props.details.out_tracking_number}</span>
                  <br />
                </div>
              </div>
              <div className="outline5" />
              <div className="outline3 stepline" />
            </div>
            <div className="returnroute">
              <div
                className={
                  (this.state.tracking_path &&
                  this.state.tracking_path.has(BOX_STATUS_RETURN_IN_TRANSIT)
                    ? "highlighted"
                    : "") + " stepcircle step5 wf-section"
                }
              >
                <div className="statusta5">
                  Return in route
                  <br />
                  ‍‍
                  <span className="statusdatetxt text-span-5">
                    {this.state.tracking_path &&
                    this.state.tracking_path.has(BOX_STATUS_RETURN_IN_TRANSIT)
                      ? this.state.tracking_path.get(BOX_STATUS_RETURN_IN_TRANSIT)
                      : ""}
                  </span>
                </div>
                <h2 className="icomoon _00 white-tracking">
                  <br />
                </h2>
              </div>
              <div
                className={
                  (this.state.tracking_path &&
                  this.state.tracking_path.has(BOX_STATUS_RETURN_DELIVERED)
                    ? "highlighted"
                    : "") + " stepcircle step6 wf-section"
                }
              >
                <div className="statusta6">
                  Return <br />
                  delivered
                  <br />‍
                  <span className="statusdatetxt text-span-5">
                    {this.state.tracking_path &&
                    this.state.tracking_path.has(BOX_STATUS_RETURN_DELIVERED)
                      ? this.state.tracking_path.get(BOX_STATUS_RETURN_DELIVERED)
                      : ""}
                  </span>
                </div>
                <h2 className="icomoon _00 white-tracking">
                  <br />
                </h2>
              </div>
              <div
                className={
                  (this.state.tracking_path && this.state.tracking_path.has(BOX_STATUS_PROCESSED)
                    ? "highlighted"
                    : "") + " stepcircle step7 wf-section"
                }
              >
                <h2 className="icomoon _00 white-tracking">
                  <br />
                </h2>
                <div className="statusta7">
                  Device
                  <br />
                  processed
                  <br />
                  <span className="statusdatetxt text-span-5">
                    {this.state.tracking_path && this.state.tracking_path.has(BOX_STATUS_PROCESSED)
                      ? this.state.tracking_path.get(BOX_STATUS_PROCESSED)
                      : ""}
                  </span>
                </div>
              </div>
              <div className="stepcircle step8 wf-section">
                <div className="statusta8">
                  Payment made
                  <br />
                  ‍‍<span className="statusdatetxt text-span-5"></span>
                </div>
                <h2 className="icomoon _00 white-tracking">
                  <br />
                </h2>
              </div>
              <div className="detailscircle">
                <div className="deviceinfocirclebottom" />
                <h2 className="icomoon _00 profile-center">
                  <br />
                </h2>
              </div>
              <div className="usercircle">
                <h2 className="icomoon _00 profile-center">
                  <br />
                </h2>
                <div className="usercirclename">
                  {this.state.contact_info
                    ? `${this.state.contact_info.first_name} ${this.state.contact_info.last_name}`
                    : ""}
                </div>
                <div className="usercircleinfo">
                  {this.state.contact_info ? this.state.contact_info.email : ""}
                  <br />
                  {this.state.contact_info ? this.state.contact_info.phone : ""}
                  <br />‍<br />
                  <span className="usercircleaddress text-span-4">
                    {this.state.address
                      ? `${this.state.address.address1}, ${this.state.address.address2}`
                      : ""}
                    <br />
                    {this.state.address
                      ? `${this.state.address.city}, ${this.state.address.state} ${this.state.address.postal_code}`
                      : ""}
                  </span>
                </div>
              </div>
              <div className="div-block-15">
                <h2 className="icomoon _00 track-modal-phone">
                  <br />
                </h2>
                <div className="deviceinfotop" />
                <div className="devicecirclename">{this.state.device_name}</div>
                <div className="devicecircleinfo" dangerouslySetInnerHTML={{__html: this.state.device_info}}></div>
              </div>
              <div className="returnline5">
                <div className="bottomsteparrow" />
              </div>
              <div className="returnline1" />
              <div className="returnline2" />
              <div className="returnline4" />
              <div className="returnline4" />
              <div className="returnline3">
                <div className="rettracktag">
                  <span className="text-span-3">Return Tracking #</span>
                  <br />
                  <span className="rettracknumtxt text-span-2">
                    {this.props.details.ret_tracking_number}
                  </span>
                </div>
              </div>
              {this.props.details.device_list.length > 0 && 
              <a href="/audit" className="audit-white-button absolute w-inline-block">
                <div className="audit-report-button button">
                  <p className="paragraph-12 _00 right-10 blue">
                    Audit Report
                    <br />
                  </p>
                  <p className="icomoon arrow-icon">
                    <br />
                  </p>
                </div>
              </a>
              }
            </div>
          </div>
          
            {this.props.details.device_list.length > 0 && (
              <div class="device_list">
              <table>
                <thead>
                  <tr>
                    <th>Model</th>
                    <th>IMEI</th>
                    <th>Serial</th>
                    <th>FMI</th>
                    <th>Grade</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>{this.state.device_list}</tbody>
              </table>
              </div>
            )}
          {this.props?.details._out_tracking_status.length > 0 || this.props?.details._return_tracking_status.length > 0 ?
          <div className="tracking_details_map" id="map" visibility={true}>
            {/* <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyCTa5kFhOMR5nKhKwpKZFI5Ei-b0jffuL4" }}
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps, places)}
            >
              <MapContainer lat={59.955413} lng={30.337844} text={"Kreyser Avrora"} />
            </GoogleMapReact> */}
            {/* {(this.props.details._return_tracking_status.length) === 0 ?
                            <GoogleMap transit={this.props.details._out_tracking_status} /> :
                            <GoogleMap transit={this.props.details._return_tracking_status} />
                        } */}
            {/* <MapContainer outTracking={this.props?.details._out_tracking_status} returnData={this.props?.details._return_tracking_status} render={render}/> */}
            <WrapMap
              outTracking={this.props?.details._out_tracking_status}
              returnTracking={this.props?.details._return_tracking_status}
            />
          </div> : <></>
          }
          {this.props.details._out_tracking_status.length > 0 &&
          
          <div className="white-div center" onClick={this.handleOutbound}>
            <h5 className="form-heading">
              <strong className="bold-text-2">Outbound Tracking Details</strong>
              <br />
            </h5>
          </div>
          }
          {this.props.details._out_tracking_status.length > 0 &&
          <div className="white-div small">
            <p className={(this.state.openOutbound ? "open" : "") + " paragraph _00"}>
              {this.state.mappedOutbound}
              <br />
            </p>
          </div>
          }
          {this.props.details._return_tracking_status.length > 0 &&
          <div className="white-div center" onClick={this.handleReturn}>
            <h5 className="form-heading">
              <strong>Return Tracking Details</strong>
              <br />
            </h5>
          </div>
          }
          {this.props.details._return_tracking_status.length > 0 &&
          <div className="white-div small">
            <p className={(this.state.openReturn ? "open" : "") + " paragraph _00"}>
              {this.state.mappedReturn}
              <br />
            </p>
          </div>
          }
          <div className="white-div small last">
          </div>
        </div>
      </React.Fragment>
    );
  }
}
