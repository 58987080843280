import React, { Component } from "react";
import { Modal, Form, Container, Row, Col, Card, Button } from "react-bootstrap";
import * as organizationServices from "../../../services/organizationServices.js";
import * as userServices from "../../../services/userServices.js";
import * as programServices from "../../../services/programServices.js";
import * as DashboardActions from "../../../flux/DashboardActions";
import DashboardStore from "../../../flux/DashboardStore.js";
import Select from "react-select";
import { element, ModalBody } from "shards-react";

class ProgramView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgs: [],
      partners: [],
      programs: [],
      orgInfo: "",
      active_program: "",
      selected_program: 0,
      userSettings: {},
      programModal: false,
    };
  }

  componentDidMount = () => {
    console.log(this.props, this.state, "didMount");
    this.getOrgInfo();
    DashboardStore.on("CHANGEUSERSETTINGS", this.setUserSettings);
  };

  componentDidUpdate = (prevProps, prevState) => {
    console.log(this.props, prevProps, prevState, this.state, "update");
  };

  componentWillUnmount = () => {
    // console.log("WillUnMount", this.props, this.stata)
    DashboardStore.removeListener("CHANGEUSERSETTINGS", this.setUserSettings);
  };

  /*
    Hydrating form will not work because the id of org comes from user not the parents id
    of the program active. Therefore I only hydrated based on placeholder. Not logic just visual
    */

  getOrgInfo = () => {
    const organization_id = this.props.currentUser.organization_id;
    this.getProgramsfromParentOrg()
    organizationServices
      .getOrganization(organization_id)
      .then(this.getTreeOrgs)
      .catch(this.getOrgInfoError);
  };

  //   getOrgInfo = () => {

  // const organizationInfo = {
  //     id: res.id,
  //     name: res.name,
  //     parent_org: res?.parent_org,
  // }
  // this.setState({ orgInfo: organizationInfo })
  // DashboardStore.manipulateResOrganizationInfo(organizationInfo)
  // this.getOrgs()
  //   };

  getProgramsfromParentOrg = () =>{
    programServices
    .getProgramsByOrg(this.props.currentUser.organization_id)
    .then(this.onGetProgramsSuccess)
    .catch(this.onGetProgramsError);
  }

  getTreeOrgs = (res) => {
    DashboardStore.storeOrgInfo(res);
    const organizationInfo = {
      id: res.id,
      name: res.name,
      parent_org: res?.parent_org,
    };
    this.setState({ orgInfo: organizationInfo });
    organizationServices
      .getOrganizationTree(this.props.currentUser.organization_id, this.props.currentUser.authToken)
      .then(this.getTreeSuccess)
      .catch(() => console.log("error"));
  };

  getTreeSuccess = (res) => {
    let array = [];
    array.push({"value": this.state.orgInfo.id, "label": `${this.state.orgInfo.name}`})
    res.map((item, index) => {
      //First loop -- check if org_list is true
      // if true go to 2nd loop
      // if false return obj
      let obj = {};
      obj.value = item.id;
      obj.label = `${index + 1}. ${item.name}`;
      array.push(obj);

      if (item?.org_list !== undefined) {
        let sub = item.org_list;

        sub.map((element, ind) => {
          let obj1 = {};
          obj1.value = element.id;
          obj1.label = `${index + 1}.${ind + 1} ${element.name}`;
          array.push(obj1);
          if (element?.org_list !== undefined) {
            let sub2 = element.org_list;

            sub2.map((org) => {
              let obj2 = {};
              obj2.value = org.id;
              obj2.label = `--- ${org.name}`;
              array.push(obj2);
            });
          }
        });
      }
    });
    this.setState({
        orgs: array
    })
  };

  getOrgInfoError = () => {};

  getOrgs = () => {
    let orgId = this.props.currentUser.organization_id;
    organizationServices.getOrgsByParent(orgId).then(this.getOrgsSuccess).catch(this.getOrgsError);
  };

  getOrgsSuccess = (response) => {
    const orgInfo = DashboardStore.getOrganizationInfo();
    let orgs = [];

    let obj = {};
    obj["value"] = orgInfo.id;
    obj["label"] = orgInfo.name;
    orgs.push(obj);
    for (let i in response) {
      let obj = {};
      obj["value"] = response[i].id;
      obj["label"] = response[i].name;
      orgs.push(obj);
    }
    this.setState({
      orgs: orgs,
    });
  };

  getOrgsError = (e) => {
    console.log(e, "error");
  };

  handleOrgSelect = (e) => {
    if (e !== null) {
      let orgId = e.value;
      //DashboardActions.setOrganizationView(orgId)
      programServices
        .getProgramsByOrg(orgId)
        .then(this.onGetProgramsSuccess)
        .catch(this.onGetProgramsError);
    } else {
      this.setState({
        programs: [{"value": 0, "label": "Select Org ..."}],
      });
      this.getOrgInfo();
    }
  };

  onHandleClick = (orgId) => {
    DashboardActions.setOrganizationView(orgId);
    programServices
      .getProgramsByOrg(orgId)
      .then(this.onGetProgramsSuccess)
      .catch(this.onGetProgramsError);
  };

  onGetProgramsSuccess = (res) => {
    // set new program list
    this.setState({
        active_program : "Select...",
      programs: res.map((entry) => {
        return {
          value: entry.id,
          label: entry.program_name,
        };
      }),
    });
  };

  onGetProgramsError = (e) => {
    console.log("Error", e);
  };

  handleProgramSelect = (e) => {
    this.setState({
      selected_program: e.value,
    });
  };

  handleProgramClick = (e) => {
    // DashboardActions.setProgramView(id)
    console.log(this.props, "PROGRAMVIEW");
    const user_id = this.props.currentUser.id;
    const token = this.props.currentUser.authToken;
    const payload = {
      user_id: user_id,
      organization_id: this.props.currentUser.organization_id,
      active_program_id: this.state.selected_program,
    };
    userServices
      .settings(user_id, payload, token)
      .then(this.updateSettingsSuccess)
      .catch(this.updateSettingsError);
    this.handleProgramViewModal();
  };

  updateSettingsSuccess = () => {
    DashboardActions.apiCallGetUserSettings(
      this.props.currentUser.id,
      this.props.currentUser.authToken
    );
  };

  updateSettingsError = (err) => {
    console.log("updateSettingsError", err);
  };

  setUserSettings = () => {
    this.setState({
      userSettings: DashboardStore.getUserSettings(),
      active_program : DashboardStore.getUserSettings()?.active_program,
    });
  };

  handleProgramViewModal = (e) => {
    this.setState({
      programModal: !this.state.programModal,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          className="program_modal"
          show={this.state.programModal}
          onHide={this.handleProgramViewModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="transparent-inner-icon">
                <h2 className="icomoon _00 print-label">&#xe920;</h2>
                <h4>Program Selector</h4>
              </div>
            </Modal.Title>
          </Modal.Header>
          <ModalBody>
            <div className="program_selector">
              <label>Organization</label>
              <Select
                //value={this.state.address_id}
                isClearable={true}
                placeholder={`${this.state.orgInfo?.name}`}
                onChange={this.handleOrgSelect}
                options={this.state.orgs}
                isSearchable={true}
              />
              <label>Program</label>
              <Select
                placeholder={this.state.active_program}
                // isClearable={true}
                onChange={this.handleProgramSelect}
                options={this.state.programs}
                isSearchable={true}
              />
            </div>
            <button
              className="primary-btn full form-button w-button"
              onClick={this.handleProgramClick}
            >
              OK
            </button>
          </ModalBody>
        </Modal>
        <div className="program_info">
          <div>
            <span>Organization:</span> {this.state.orgInfo.name}
          </div>
          <div>
            <span>Program:</span> {this.state.userSettings.active_program}
          </div>
          <button
            class="primary-btn full form-button w-button"
            onClick={this.handleProgramViewModal}
          >
            Program Selector
          </button>
        </div>
      </React.Fragment>
    );
  }
}
export default ProgramView;
