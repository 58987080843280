import React from "react";
import { Formik, Field } from "formik";
import swal from "sweetalert";
import { Form, Label, FormGroup } from "reactstrap";
import * as userServices from "../services/userServices.js";
import "./ForgotPassword.css";
import { Navigate } from "react-router-dom";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {},
      formData: {
        email: "" 
      }
    };
  }

  componentDidMount = () => {

  }
  handleSubmit = e => {
    let email = e.email
    console.log(email, "values")
      userServices
        .forgotPassword(email)
        .then(this.onSuccess)
        .catch(this.onFailure);
  };
  onSuccess = () => {
    swal({
      type: "success",
      title: "Please Check Email"
    });
    <Navigate to="/login" />
  };
  onFailure = error => {
    swal({
      type: "warning",
      title: error.message
    });
  };

  render() {
    return (
        <React.Fragment>
            <div className="section light-purple full wf-section">
                <div className="container small w-container">
                    <a href="/" className="logo link login w-inline-block">
                        <img src="/images/Moblyz_logo_V2.svg" loading="lazy" alt="" className="image-full" />
                    </a>
                    <div className="login-div white">
                        <h1>Forgot Password</h1>
                    <div className="w-form">
                        <Formik
                            enableReinitialize={true}
                            initialValues={this.state.formData}
                            onSubmit={this.handleSubmit}
                        >
                            {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleSubmit,
                                isValid,
                                isSubmitting
                            } = props;
                            return (
                                    <Form onSubmit={handleSubmit} className="form registration">
                                        <div>
                                            <FormGroup>
                                                <Label><strong>Please verify your email</strong></Label>
                                                <Field
                                                type="email"
                                                name="email"
                                                values={values.email}
                                                placeholder="name@email.com"
                                                className="input-field w-input" 
                                                maxlength="256"
                                                />
                                                {errors.email && touched.email && (
                                                <span className="input-feedback">
                                                    {errors.email}
                                                </span>
                                                )}
                                            </FormGroup>
                                        </div>
                                    <button
                                        className="primary-button full form-button w-button"
                                        id="submit"
                                        type="submit"
                                        name="submit"
                                        disabled={!isValid || isSubmitting}
                                    >
                                        Submit
                                    </button>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
  }
}

export default ForgotPassword;