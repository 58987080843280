import axios from "axios";
import * as helper from "./serviceHelper";
import * as env from "./env";
import DashboardStore from "../flux/DashboardStore";




const endpoint = env.url

let getPrograms = (userId, token) => {
  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/program/exist?userId=${userId}`,
    withCredentials: false,
    crossdomain: true,
    headers: {Authorization: `Bearer ${token}`,"Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let getProgramsByOrg = (orgId, token) =>{
  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/get_programs?org_id=${orgId}`,
    withCredentials: false,
    crossdomain: true,
    headers: {Authorization: `Bearer ${token}`,"Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let getPriceBook = (token) => {
  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/pricebook`,
    withCredentials: false,
    crossdomain: true,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let createProgram = (payload, token) =>{
  const config = {
    data: payload,
    method: "POST",
    url: "https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/program/create",
    withCredentials: false,
    crossdomain: true,
    headers: {Authorization: `Bearer ${token}`,"Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
}

let getProgramSnapshot = (id) => {
  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/get_snapshot?program_id=${id}`,
    withCredentials: false,
    crossdomain: true,
    headers: {
      "Content-Type": "application/json", Accept: "application/json"
    },
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let updateProgram = (payload, token) =>{
  const config = {
    data: payload,
    method: "POST",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/update_program/${payload.program_id}`,
    withCredentials: false,
    crossdomain: true,
    headers: {Authorization: `Bearer ${token}`,"Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
}

let updateProgramAprroval = (payload, token) =>{
  const config = {
    data: payload,
    method: "POST",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/programs/${payload.id}/approval`,
    withCredentials: false,
    crossdomain: true,
    headers: {Authorization: `Bearer ${token}`,"Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
}

let programsPending = (organization_id, token) => {
    const config = {
      data: organization_id, 
      method: "GET",
      url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/programs/approve_list`,
      withCredentials: false,
      crossdomain: true,
      headers: {Authorization: `Bearer ${token}`,"Content-Type": "application/json", Accept: "application/json" }
    };
    return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
  };

  let activities = (program_id, token) => {
    const config = {
      method: "GET",
      url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/dashboard/${program_id}/activities`,
      withCredentials: false,
      crossdomain: true,
      headers: {Authorization: `Bearer ${token}`,"Content-Type": "application/json", Accept: "application/json" }
    };
    return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
  };

export { getPrograms,updateProgram, activities, updateProgramAprroval, getPriceBook, createProgram, getProgramsByOrg, getProgramSnapshot, programsPending}