import React, { useState } from 'react';
import AddressSearch, * as loqate from "react-loqate";
import { Form } from "react-bootstrap"
import './address.css'

export const Address = (props) => {

    const onHandleLoqate = (address) => {

        props.onHandleLoqate(address)

    }

    const AddressSearchInput = (props) => {
        return (
            <Form.Control
                autoComplete="chrome-off"
                className='loqate-dropdowns'
                placeholder="Address"
                {...props}
            />
        );
    };

    return (
        <AddressSearch
            inline={true}
            className='loqate-dropdowns input-field w-input'
            debounce={100}
            locale="en_GB"
            value={props.value}
            name={console.log("return")}
            apiKey="NE67-UA89-TZ79-WA31"
            countries={['CAN', 'USA']}
            onSelect={onHandleLoqate}
            components={{ Input: AddressSearchInput }}
        />
    );
}