import React, { Component } from "react";
import { AuthProvider } from "./AuthProvider";
import AppRoutesV2 from "./AppRoutesV2";
// import createHistory from "history/createBrowserHistory";
// import "bootstrap/dist/css/bootstrap.min.css";

// const history = createHistory();
// require("history").createBrowserHistory;

function App() {
  return (
    <AuthProvider>
      <AppRoutesV2 />
    </AuthProvider>
  );
}

export default App;
