import { EventEmitter } from "events";

import Dispatcher from "./dispatcher";
import Constants from "./constants";
// import getSidebarNavItems from "../data/sidebar-nav-items";
import getStandardSideBarNavItems from "../components/layout/sideItems/standardBar-nav-items";
import getAdminSideBarNavItems from "../components/layout/sideItems/adminBar-nav-items";
import getSuperSideBarNavItems from "../components/layout/sideItems/superBar-nav-items";

let _store = {
  menuVisible: false,
//   navItems: getSidebarNavItems(),
  standardNavItems: getStandardSideBarNavItems(),
  adminItems: getAdminSideBarNavItems(),
  superItems: getSuperSideBarNavItems()

};

class Store extends EventEmitter {
  constructor() {
    super();

    this.registerToActions = this.registerToActions.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);

    Dispatcher.register(this.registerToActions.bind(this));
  }

  registerToActions({ actionType, payload }) {
    switch (actionType) {
      case Constants.TOGGLE_SIDEBAR:
        this.toggleSidebar();
        break;
      default:
    }
  }

  toggleSidebar() {
    _store.menuVisible = !_store.menuVisible;
    this.emit(Constants.CHANGE);
  }

  getMenuState() {
    return _store.menuVisible;
  }

  getSidebarItems() {
    return _store.navItems;
  }

  getStandardSidebarItems() {
    return _store.standardNavItems
  }

  getAdminSidebarItems() {
    return _store.adminItems;
  }

  getSuperSidebarItems() {
    return _store.superItems;
  }

  addChangeListener(callback) {
    this.on(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }
}

export default new Store();
