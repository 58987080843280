import axios from "axios";
import * as helper from "./serviceHelper";
import * as env from "./env";
import DashboardStore from "../flux/DashboardStore";
 

let storeToken = DashboardStore.getToken()

const endpoint = env.url

let getAuditUpdates = (token, program_id, page_num, per_page, tracking_reference,tracking_number,start_date,end_date,grade,serial_number,device_type_id, download, location_name, approved_view) => {
  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/audit/program?program_id=${program_id}&page_num=${page_num}&per_page=${per_page}&tracking_reference=${tracking_reference}&tracking_number=${tracking_number}&start_date=${start_date}&end_date=${end_date}&grade=${grade}&serial_number=${serial_number}&device_type_id=${device_type_id}&download=${download}&location_name=${location_name}&approved_view=${approved_view}`,
    withCredentials: false,
    crossdomain: true,
    //headers: {"Content-Type": "application/json", Accept: "application/json" }
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let auditCOD = (id) => {
  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/audit/${id}/cod`,
    withCredentials: false,
    crossdomain: true,
    headers: {"Content-Type": "application/json", Accept: "application/json" }
    //headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let requestCOD = (id, token) =>{
  const config = {
    method: "POST",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/audit/${id}/cod`,
    withCredentials: false,
    crossdomain: true,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
}

let CODexist = (id, token) =>{
  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/audit/${id}/cod/status`,
    withCredentials: false,
    crossdomain: true,
    // headers: {"Content-Type": "application/json", Accept: "application/json" }
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let auditApprove = (token, payload) =>{
  const config = {
    data: payload,
    method: "POST",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/audit/approve`,
    withCredentials: false,
    crossdomain: true,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
}


export { getAuditUpdates, auditCOD, requestCOD, CODexist, auditApprove }