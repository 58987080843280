import React, { useContext, useState, useLayoutEffect, useEffect } from "react";
import * as userService from "../services/userServices";
import * as validationHelper from "../services/validation.helper.js";
import DashboardStore from "../flux/DashboardStore";
import * as DashboardActions from "../flux/DashboardActions";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import "./login.css";
import { authContext, useAuth } from "../AuthProvider";

function Login() {
  const { user, login, setUser } = useContext(authContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [inputType, setInputType] = useState("password");

  let navigate = useNavigate();
  const auth = useAuth();

  const onAuthSuccess = (res) => {
    let currentUserLocalStorage = localStorage.getItem("currentUser");
    let userLocalStorage = JSON.parse(currentUserLocalStorage);
    DashboardStore.storeCurrentUser(userLocalStorage);
    DashboardStore.createTokentUser(userLocalStorage.authToken);
    DashboardStore.storeCurrentUser(userLocalStorage);
    setUser(userLocalStorage);
    navigate("/home");
  };

  const onAuthError = () => {
    localStorage.clear();
  };

  if (user === undefined && localStorage.length !== 0) {
    let currentUserLocalStorage = localStorage.getItem("currentUser");
    let userLocalStorage = JSON.parse(currentUserLocalStorage);
    userService.authorizeUser(userLocalStorage.authToken).then(onAuthSuccess).catch(onAuthError);
  }

  const onChangeEmail = (e) => {
    let newValue = e.target.value;
    setEmail(newValue);
  };

  const onChangePassword = (e) => {
    let newValue = e.target.value;
    setPassword(newValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let item = {
      email: email,
      password: password,
    };
    userService.login(item).then(onLoginSuccess).catch(onLoginError);
  };

  const onLoginSuccess = (res) => {
    DashboardStore.createTokentUser(res.authToken);
    userService
      .getCurrent(res.authToken)
      .then(onGetCurrentUserSuccess)
      .catch(onGetCurrentUserError);
  };

  const onLoginError = (res) => {
    console.log(res, "RES");
  };

  const onGetCurrentUserSuccess = (res) => {
    // setBasicInfo(res)
    userService.userDetails(res.id).then(onGetDetailsSuccess).catch(onGetDetailsError);
  };

  const onGetCurrentUserError = (res) => {
    // console.log(res)
  };

  const onGetDetailsSuccess = (res) => {
    let token = DashboardStore.getToken();
    const role = res.role_id === 1 ? "Admin" : res.role_id === 2 ? "Standard User" : "Super";
    let currentUser = {
      id: res.id,
      email: res.email,
      name: `${res.first_name} ${res.last_name}`,
      role: role,
      organization_id: res.organization_id,
      authToken: token[0].store,
      isLoggedIn: true,
    };
    let currentUserJson = JSON.stringify(currentUser);
    localStorage.setItem("currentUser", currentUserJson);
    DashboardStore.storeCurrentUser(currentUser);
    login(currentUser);
    navigate("/home");
  };

  const onGetDetailsError = (res) => {
    console.log(res);
  };

  const togglePassword = () => {
    if(inputType === "password"){
      setInputType("text")
    }else{
      setInputType("password")
    }
  };

  return (
    <React.Fragment>
      <div className="section light-purple full wf-section">
        <div className="container small w-container">
          <a href="/" className="logo link login w-inline-block">
            <img src="/images/Moblyz_logo_V2.svg" loading="lazy" alt="" className="image-full" />
          </a>
          <div className="login-div white">
            <div className="w-form">
              <form
                id="wf-form-Login-Form"
                name="wf-form-Login-Form"
                data-name="Login Form"
                onSubmit={handleSubmit}
                className="form registration"
                aria-label="Login Form"
              >
                <div>
                  <label for="email">Email</label>
                  <input
                    type="email"
                    className="input-field w-input"
                    maxlength="256"
                    name="email"
                    data-name="email"
                    placeholder="name@company.com"
                    onChange={onChangeEmail}
                  />
                </div>
                <div>
                  <div className="div-block-2">
                    <label for="show_password">Password</label>
                    <label className="w-checkbox checkbox-field password">
                      <div
                        className={
                          (inputType === "password" ? "" : "w--redirected-checked ") +
                          "w-checkbox-input w-checkbox-input--inputType-custom checkbox flip password"
                        }
                      ></div>{" "}
                      <input
                        type="checkbox"
                        name="show_password"
                        id="show_password"
                        data-name="show_password"
                        onClick={togglePassword}
                      />
                      <span className="password label w-form-label" for="show_password">
                        Show Password
                      </span>
                    </label>
                  </div>
                  <input
                    type={inputType}
                    required
                    className="input-field w-input"
                    maxlength="256"
                    name="password"
                    data-name="password"
                    placeholder="Password"
                    autocomplete="off"
                    onChange={onChangePassword}
                  />
                </div>
                <label className="w-checkbox checkbox-field">
                  <div className="w-checkbox-input w-checkbox-input--inputType-custom checkbox"></div>
                  <input
                    type="checkbox"
                    name="remember_login"
                    id="remember_login"
                    data-name="remember_login"
                  />
                  <span className="w-form-label" for="remember_login">
                    Remember Login
                  </span>
                </label>
                <input
                  type="submit"
                  value="Login"
                  data-wait="Please wait..."
                  className="primary-button full form-button w-button"
                />
              </form>
              <div
                className="w-form-done"
                tabindex="-1"
                role="region"
                aria-label="Login Form success"
              >
                <div>Thank you! Your submission has been received.</div>
              </div>
              <div
                className="w-form-fail"
                tabindex="-1"
                role="region"
                aria-label="Login Form failure"
              >
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
            <div className="div-block-3">
              <a id="reset_password" href="/password">
                Reset your password
              </a>
              {/* <a href="/register">Sign up</a> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
