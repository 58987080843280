import React, { Component } from 'react'
import DashboardStore from '../../../flux/DashboardStore';
import * as programServices from "../../../services/programServices.js";
import "./activity.css";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';

  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );


class ActivityOverview extends Component {

    constructor(props) {
        super(props);

        this.state = {
            viewAs: 0,
            data: {
                datasets: [
                  {
                    label: 'Dataset 1',
                    cubicInterpolationMode: 'monotone',
                    data: [],
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                  }
                ],
              },
            month: [],
            count: [],
            value: [],
            request: [],
        }
    }

    componentDidMount = () => {
        console.log(this.props, this.state, "didMount")
        DashboardStore.on("CHANGEUSERSETTINGS", this.getActivityData)

    }

    componentDidUpdate = (prevProps, prevState) => {
        let userSettings = DashboardStore.getUserSettings()

        if(this.state.viewAs === 0 && userSettings !== undefined && userSettings.active_program_id !== 0){
            let viewAs = userSettings.active_program_id
            this.setState({viewAs: viewAs})
            if(viewAs !== 0){
                this.getActivityData()
            }
        }
    }

    componentWillUnmount = () => {
        console.log("WillUnMount", this.props, this.stata)
        DashboardStore.removeListener("CHANGEUSERSETTINGS", this.getActivityData)
    }

    getActivityData = () =>{
        let program_id = DashboardStore.getUserSettings().active_program_id
        programServices
            .activities(program_id)
            .then(this.getActivitySuccess)
            .catch(this.getActivityError)
    }

    getActivitySuccess = (res) =>{
        const labels = [];
        const deviceCount = []
        const totalValue = []
        const request = []

        for(let i of res){
            labels.push(i.month);
            deviceCount.push(i.device_count);
            totalValue.push(i.total_value);
            request.push(i.requests)
        }
        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'Requests',
                    data: request,
                    // borderColor: Utils.CHART_COLORS.red,
                    fill: false,
                    borderColor: 'rgb(255, 99, 132)',
                    cubicInterpolationMode: 'monotone',
                    tension: 0.4
                }, {
                    label: 'Device Count',
                    data: deviceCount,
                    borderColor: 'rgb(53, 162, 235)',
                    // borderColor: Utils.CHART_COLORS.blue,
                    fill: false,
                    tension: 0.4
                },
            ]
        };
        this.setState({
            month: labels,
            count: deviceCount,
            value: totalValue,
            request: request,
            data: data,
        })
    }

    getActivityError = () =>{
        
    }
    

    render() {
        const config = {
            type: 'line',
            data: this.state.data,
            options: {
                responsive: true,
                plugins: {
                    title: {
                        display: true,
                        text: 'Chart.js Line Chart - Cubic interpolation mode'
                    },
                },
                interaction: {
                    intersect: false,
                },
                scales: {
                    x: {
                        display: true,
                        title: {
                            display: true
                        }
                    },
                    y: {
                        display: true,
                        title: {
                            display: true,
                            text: 'Value'
                        },
                        suggestedMin: -10,
                        suggestedMax: 200
                    }
                }
            },
        };
        return (
            <React.Fragment>
                <div className='activity-container'>
                    <h2>Activity Overview</h2>
                    <Line  data={this.state.data} />
                </div>
            </React.Fragment>
        )
    }
}
export default ActivityOverview
