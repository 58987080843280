import React, { Component } from "react";
import { Card, Row, Col } from "react-bootstrap";
import DashboardStore from "../../../flux/DashboardStore";
import "./environmental.css";

class Environmental extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snapshot: [],
    };
  }

  componentDidMount = () => {
    this.storeSnapshot();
  };

  storeSnapshot = () => {
    let snapshot = DashboardStore.getSnapshot();
    console.log(snapshot, "ENV");
    this.setState({ snapshot: snapshot });
  };

  render() {
    return (
      <React.Fragment>
        <div className="env-container">
          <div className="">
            <Card className="header-wrap">
              <row>
                <h2 className="text-color">Environmental Impact Scorecard</h2>
              </row>
              <row>
                <h5>Metrics are displayed below for the most important environmental measures showing how much you are impacting these areas by reselling, reusing, and recycling your mobile devices while saving these materials from landfills.</h5>
              </row>
              <row>
                <h3>{this.state.snapshot?.device_count !== undefined ? this.state.snapshot.device_count : ""} total devices received</h3>
              </row>
            </Card>
          </div>
          <div className="">
            <Row>
              <Col>
                <Card className="env-wrap">
                  <span className="ico icon-MoblyzIcons_LightBulbCheck"></span>
                  <h3 className="text-color">{Math.round(((this.state.snapshot?.total_value !== undefined ? this.state.snapshot.total_value : "") * 18.577 * 100) / 100)}</h3>
                  <p>(kWh)</p>
                  <h6>Energy savings in kilowatt hour</h6>
                </Card>
              </Col>
              <Col>
                <Card className="env-wrap">
                  <span className="ico icon-co2"></span>
                  <h3 className="text-color">{Math.round(((this.state.snapshot?.total_value !== undefined ? this.state.snapshot.total_value : "") * 3.83 * 100) / 100)}</h3>
                  <p>(kg CO2 eq)</p>
                  <h6>Greenhouse gas emission reduction (expressed as global warming potential)</h6>
                </Card>
              </Col>
              <Col>
                <Card className="env-wrap">
                  <span className="ico icon-trash"></span>
                  <h3 className="text-color">{Math.round(((this.state.snapshot?.total_value !== undefined ? this.state.snapshot.total_value : "") * 0.349 * 100) / 100)}</h3>
                  <p>(kg)</p>
                  <h6>Non-hazardous solid waste reduction</h6>
                </Card>
              </Col>
              <Col>
                <Card className="env-wrap">
                  <span className="ico icon-water"></span>
                  <h3 className="text-color">{Math.round(((this.state.snapshot?.total_value !== undefined ? this.state.snapshot.total_value : "") * 12.687 * 100) / 100)}</h3>
                  <p>(liters H2O)</p>
                  <h6>Water consumption savings</h6>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="env-wrap">
                  <span className="ico icon-smog"></span>
                  <h3 className="text-color">{Math.round(((this.state.snapshot?.total_value !== undefined ? this.state.snapshot.total_value : "") * 0.322 * 100) / 100)}</h3>
                  <p>(kg SO2 eq)</p>
                  <h6>Smog formation potential savings</h6>
                </Card>
              </Col>
              <Col>
                <Card className="env-wrap">
                  <span className="ico icon-hazard"></span>
                  <h3 className="text-color">{Math.round(((this.state.snapshot?.total_value !== undefined ? this.state.snapshot.total_value : "") * 0.003 * 100) / 100)}</h3>
                  <p>(kg)</p>
                  <h6>Toxic substances avoide in the production</h6>
                </Card>
              </Col>
              <Col>
                <Card className="env-wrap">
                  <span className="ico icon-recycle"></span>
                  <h3 className="text-color">{Math.round(((this.state.snapshot?.total_value !== undefined ? this.state.snapshot.total_value : "") * 0.122 * 100) / 100)}</h3>
                  <p>(kg)</p>
                  <h6>Material conservation</h6>
                </Card>
              </Col>
              <Col>
                <Card className="env-wrap">
                  <span className="ico icon-MoblyzIcons_Paid"></span>
                  <h3 className="text-color">${Math.round(((this.state.snapshot?.total_value !== undefined ? this.state.snapshot.total_value : "") * 0.019 * 100) / 100)}</h3>
                  <p>(US $)</p>
                  <h6>Cost savings for non-hazardous solid waste disposal</h6>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <Card className="env-wrap">
                  <span className="ico icon-MoblyzIcons_PigBank"></span>
                  <h3 className="text-color">${Math.round(((this.state.snapshot?.total_value !== undefined ? this.state.snapshot.total_value : "") * 0.218 * 100) / 100)}</h3>
                  <p>(US $)</p>
                  <h6>Cost savings for energy use</h6>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Environmental;
