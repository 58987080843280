import React from "react";
import moment from 'moment'
import "@fortawesome/fontawesome-free/css/all.css"


const AuditCard = (props) => {

    const onHandleImg = (e) => {
        props.onHandleImg(props.data)
    }
    const onHandleCheck = (e) => {
        props.onHandleCheck(e.target.value, e.target.checked)
    }
    const onHandleCod = (e) => {
        let id = props.data.id
        props.onHandleCod(id)
    }

    /* 
    --------------------------------------------------------- 
    name:   format_tracking_number 
    desc:   formats tracking number into full fedex url
    parms:
            tracking_number: tracking number to format
    return: formatted url to fedex tracking
    ---------------------------------------------------------
     */
    const format_tracking_number = (tracking_number) => {
        return tracking_number ? (
            <a target="_blank" href={`https://www.fedex.com/fedextrack/?trknbr=${tracking_number}`}>{tracking_number}</a>
        ) : (
            <span></span>
        )
    }

    /* 
    --------------------------------------------------------- 
    name:   format_date
    desc:   formats date/timestamp into readable date
    parms:
            date_to_format: date/timestamp to be formatted
    return: formatted date
    todo:   instead of hard-coding the date format, use the locale code instead (eg. date.toLocaleString(local_code))
    ---------------------------------------------------------
    */
    const format_date = (date_to_format) => {
        return date_to_format ? moment(date_to_format).format("MM/DD/YYYY") : "";
    }



    return (
        <React.Fragment>
            <tr>
                {props.role === "Super" ? <td><input type='checkbox' checked={props.approved_list.length === props.unapproved_list.length ?
                    props.data.approved : (props.approved_list.includes(props.data.id) ? true : false)} onChange={onHandleCheck} value={props.data.id} /></td> : ""}
                {/* <td>{format_date(props.data.created_at)}</td>
                <td>{format_date(props.data.updated_at)}</td> */}
                {props.column.processed_date === true ? <td>{format_date(props.data.processed_date)}</td> : ""}
                {props.column.request_date === true ? <td>{format_date(props.data.request_date)}</td> : ""}
                {props.column.make === true ? <td>{props.data.make}</td> : ""}
                {props.column.model === true ? <td>{props.data.model}</td> : ""}
                {props.column.model_number === true ? <td>{props.data.model_number}</td> : ""}
                {props.column.memory_size === true ? <td>{props.data.memory_size}</td> : ""}
                {props.column.unique_id === true ? <td>{props.data.unique_id}</td> : ""}
                {props.column.imei === true ? <td>{props.data.imei}</td> : ""}
                {props.column.meid === true ? <td>{props.data.meid}</td> : ""}
                {props.column.serial_number === true ? <td>{props.data.serial_number}</td> : ""}
                {props.column.tracking_number === true ? <td>{format_tracking_number(props.data.tracking_number)}</td> : ""}
                {props.column.grade === true ? <td>{props.data.grade}</td> : ""}
                {props.column.condition_notes === true ? <td>{props.data.condition_notes}</td> : ""}
                {props.column.fmi === true ? <td>{props.data.fmi}</td> : ""}
                {props.column.value === true ? <td>{props.data.value}</td> : ""}
                {props.column.battery_ratio === true ? <td>{props.data.battery_ratio}</td> : ""}
                {props.column.tracking_reference === true ? <td>{props.data.tracking_reference}</td> : ""}
                {props.column.reference === true ? <td>{props.data.reference}</td> : ""}
                {props.column.lock_status === true ? <td>{props.data.lock_status}</td> : ""}
                {props.column.po === true ? <td>{props.data.po}</td> : ""}
                {props.column.battery === true ? <td>{props.data.battery}</td> : ""}
                {props.column.glass_description === true ? <td>{props.data.glass_description}</td> : ""}
                {/* {props.column.lcd_description === true ? <td>{props.data.lcd_description}</td> : ""} */}
                {props.column.housing_description === true ? <td>{props.data.housing_description}</td> : ""}
                {props.column.corner_description === true ? <td>{props.data.corner_description}</td> : ""}
                {props.column.first_name === true ? <td>{props.data.first_name}</td> : ""}
                {props.column.last_name === true ? <td>{props.data.last_name}</td> : ""}
                {props.column.city === true ? <td>{props.data.city}</td> : ""}
                {props.column.state === true ? <td>{props.data.state}</td> : ""}
                {props.column.phone === true ? <td>{props.data.phone}</td> : ""}
                {props.column.email === true ? <td>{props.data.email}</td> : ""}
                {/* <td>{format_date(props.data.date_paid)}</td>
                <td>{props.data.iccid}</td>
                <td>{props.data.audio_jack}</td>
                <td>{props.data.back_camera}</td>
                <td>{props.data.front_camera}</td>
                <td>{props.data.wifi}</td>
                <td>{props.data.keyboard}</td>
                <td>{props.data.touchpad}</td>
                <td>{props.data.ext_ports}</td>
                <td>{props.data.speakers}</td>
                <td>{props.data.microphone}</td>
                <td>{props.data.pressure_spots}</td>
                <td>{props.data.digitizer}</td>
                <td>{props.data.lcd}</td>
                <td>{props.data.water_damage}</td>
                <td>{props.data.accelerometer}</td>
                <td>{props.data.homebutton}</td>
                <td>{props.data.glass_grade}</td>
                <td>{props.data.lcd_grade}</td>
                <td>{props.data.inactive}</td>
                <td>{props.data.backhousing_frame_grade}</td>
                <td>{props.data.cosmetic_test}</td>
                <td>{props.data.device_status}</td>
                <td>{props.data.display_grade}</td>
                <td>{props.data.locked}</td>
                <td>{props.data.three_tier_grade}</td>
                <td>{props.data.device_type}</td> */}
                {/* {checkImgExist(props.data.id) ?
                    <td key={props.data.id}><div className="transparent-inner-icon _10 w-inline-block" value={props.data.id} onClick={onHandleImg}><h2 className="icomoon _00 reporting-icon"></h2></div></td>
                    : <td>No Image</td>

                } */}
                {props.data.images.length === 0 ? <td></td> :
                    <td style={{position : "sticky", right:"55px", background:"white"}} key={props.data.id}><div className="transparent-inner-icon _10 w-inline-block" value={props.data.id} onClick={onHandleImg}><h2 className="icomoon _00 reporting-icon"></h2></div></td>
                }
                <td style={{position : "sticky", right:"0px", background:"white"}} key={props.data.id}><div className="transparent-inner-icon _00" value={props.data.id} onClick={onHandleCod}><h2 className="icomoon _00 reporting-icon"></h2></div></td>
            </tr>

        </React.Fragment>
    );
};

export default AuditCard;

