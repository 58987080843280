import dispatcher from "./dispatcher";
import * as userServices from "../services/userServices";
import * as programServices from "../services/programServices";
import * as vendorServices from "../services/vendorServices";


//Adding our actions and dispatch our actions

//The name of our action method is createTodo

// export function createTodo(text){
//     dispatcher.dispatch({ //dispatches our event
//         type: "CREATE_TODO",
//         text,  //The data we pass in ex text or id
//     })
// }

export function getCurrentUser(token) {
  dispatcher.dispatch({type: "FETCH_TODOS"})
  userServices
    .getCurrent(token)
    .then((data) =>
      dispatcher.dispatch({
        type: "FETCH_CURRENTUSER_SUCCESS",
        data,
      })
    )
    .catch((err) =>
      dispatcher.dispatch({
        type: "FETCH_CURRENTUSER_FAILURE",
        err,
      })
    );
}

export function getTokenUser(item) {
      dispatcher.dispatch({type: "FETCH_TODOS"})
      userServices
      .login(item)
      .then((data) =>
        dispatcher.dispatch({
          type: "FETCH_TOKEN_SUCCESS",
          data,
        })
      )
      .catch((err) =>
        dispatcher.dispatch({
          type: "FETCH_TOKEN_FAILURE",
          err,
        })
      );
}

export function apiCallGetPrograms(userId, token) {
  programServices
  .getPrograms(userId, token)
  .then((data) =>
    dispatcher.dispatch({
      type: "FETCH_PROGRAMS_SUCCESS",
      data,
    })
  )
  .catch((err) =>
    dispatcher.dispatch({
      type: "FETCH_PROGRAMS_FAILURE",
      err,
    })
  );
}

export function apiCallGetProgramsPending(organization_id, token) {
  programServices
  .programsPending(organization_id, token)
  .then((data) =>
    dispatcher.dispatch({
      type: "FETCH_DP_PROGRAMS_SUCCESS",
      data,
    })
  )
  .catch((err) =>
    dispatcher.dispatch({
      type: "FETCH_DP_PROGRAMS_FAILURE",
      err,
    })
  );
}

export function apiCallPriceBook (token) {
  programServices
    .getPriceBook(token)
    .then((data) =>
      dispatcher.dispatch({
        type: "FETCH_PRICEBOOK_SUCCESS",
        data,
      })
    )
    .catch((err) =>
      dispatcher.dispatch({
        type: "FETCH_PRICEBOOK_FAILURE",
        err,
      })
    );
}

export function apiCallVendorDetails () {
  vendorServices
    .getPurchaser()
    .then((data) =>
      dispatcher.dispatch({
        type: "FETCH_VENDORDETAILS_SUCCESS",
        data,
      })
    )
    .catch((err) =>
      dispatcher.dispatch({
        type: "FETCH_VENDORDETAILS_FAILURE",
        err,
      })
    );
}

export function apiCallGetAddresses (userId) {
  userServices
    .getAddresses(userId)
    .then((data) =>
      dispatcher.dispatch({
        type: "FETCH_ADDRESSES_SUCCESS",
        data,
      })
    )
    .catch((err) =>
      dispatcher.dispatch({
        type: "FETCH_ADDRESSES_FAILURE",
        err,
      })
    );
}

export function setProgramView (id) {
      dispatcher.dispatch({
        type: "SET_PROGRAM_ID",
        id,
      })
}

export function setOrganizationView (id) {
  dispatcher.dispatch({
    type: "SET_ORGANIZATION_ID",
    id,
  })
}

export function apiCallGetSnapshot(program_id) {
  programServices
  .getProgramSnapshot(program_id)
  .then((data) =>
    dispatcher.dispatch({
      type: "FETCH_SNAPSHOT_SUCCESS",
      data,
    })
  )
  .catch((err) =>
    dispatcher.dispatch({
      type: "FETCH_SNAPSHOT_FAILURE",
      err,
    })
  );
}

export function apiCallUpdateUserSettings(user_id, payload, token) {
  userServices
  .settings(user_id, payload, token)
  .then((data) =>
    dispatcher.dispatch({
      type: "FETCH_USERSETTINGS_SUCCESS",
      data,
    })
  )
  .catch((err) =>
    dispatcher.dispatch({
      type: "FETCH_USERSETTINGS_FAILURE",
      err,
    })
  );
}

export function apiCallGetUserSettings(user_id, token) {
  userServices
  .getSettings(user_id, token)
  .then((data) =>
    dispatcher.dispatch({
      type: "FETCH_USERSETTINGS_SUCCESS",
      data,
    })
  )
  .catch((err) =>
    dispatcher.dispatch({
      type: "FETCH_USERSETTINGS_FAILURE",
      err,
    })
  );
}
