import React, { Component } from 'react'
import {Link} from "react-router-dom"

export default class NoRoute extends Component {
  render() {
    return (
      <div className="section light-purple full wf-section">
      <div className="position-absolute top-50 start-50 translate-middle">
        <div className="login-div white">
          <h2>Route does not exist please!</h2>
          <div>
            <Link to="/home">
              <input
                type="submit"
                value="Go Home"
                data-wait="Please wait..."
                className="primary-button full form-button w-button"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
    )
  }
}
