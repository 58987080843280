import React from "react";
import "@fortawesome/fontawesome-free/css/all.css"
import "./program.css";
import { Row, Container, Col } from "react-bootstrap";

const DeviceAddedCard = (props) => {
    const onHandleDelete = () => {
        props.onHandleDelete(props.device.id)
    }
    return (
        <React.Fragment>
            <Container>
                {/* <div className="row"> */}

                <Row md={4}>
                    <Col><span className="text-limit">{props.device.type}</span></Col>
                    <Col>{props.device.quantity}</Col>
                    <Col key={props.device.id} onClick={onHandleDelete}><i className="far fa-times-circle"></i></Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default DeviceAddedCard;