import * as React from "react";
import { Button, Modal, Container, Row, Col, Form, FloatingLabel, Collapse } from "react-bootstrap";
import { Formik, Field } from "formik";
import { Alert } from "@mui/material";
import DashboardStore from "../../../flux/DashboardStore";
import * as DashboardActions from "../../../flux/DashboardActions";
import ProgramsCard from "./ProgramCard";
import { InputGroup, DatePicker, InputGroupAddon, InputGroupText } from "shards-react";
import Select from "react-select";
import DeviceAddedCard from "./DeviceAddedCard";
import * as programServices from "../../../services/programServices";
import swal from "sweetalert";

class ProgramAlert extends React.Component {
  constructor(props) {
    super(props);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.state = {
      formData: {
        program_name: "",
        program_description: "",
        start_date: undefined,
        organization_id: 0,
        warehouse_id: 0,
        program_type_id: 0,
        created_by: 0,
        updated_by: 0,
      },
      quantity: 0,
      priceBook: DashboardStore.getPriceBook(),
      program_device_list: [],
      mapDevice: [],
      deviceSelected: "",
      program: [],
      programsPending: [],
      apiProgram: false,
      apiPendingProgram: false,
      alert: true,
      programModal: false,
    };
  }

  componentDidMount = (nextProps, prevState) => {
    // console.log("didMount", nextProps, prevState, this.props, this.state)
    if (DashboardStore.getProgramsDP() !== undefined) {
      this.getPrograms();
    }

    DashboardStore.on("CHANGE", this.getPrograms);
    DashboardStore.on("CHANGE", this.createPriceBookAction);
  };

  componentDidUpdate = (nextProps, prevState) => {
    // console.log("DidUpdate", nextProps, prevState, this.state, this.props)
    if (
      this.state.apiPendingProgram === false &&
      this.state.programsPending.length === 0 &&
      DashboardStore.getProgramsDP() === undefined
    ) {
      DashboardActions.apiCallGetProgramsPending(
        this.props.currentUser.organization_id,
        this.props.currentUser.authToken
      );
      this.setState({ apiPendingProgram: true });
      // DashboardActions.apiCallGetPrograms(this.props.currentUser.id, this.props.currentUser.authToken)
    }
    // if (this.state.program.length === 0 && this.props.program.length > 0) {
    //     this.getPrograms()
    // }
    if (this.state.priceBook.length === 0 && this.props.currentUser !== nextProps.currentUser) {
      DashboardActions.apiCallPriceBook(this.props.currentUser.authToken);
    }
  };

  componentWillUnmount = () => {
    // console.log("WillUnMount")
    DashboardStore.off("CHANGE", this.getPrograms);
    DashboardStore.off("CHANGE", this.createPriceBookAction);
  };

  createPriceBookAction = (e) => {
    let priceBookOptions = DashboardStore.getPriceBook();
  };

  /* Alert programs mapped into bullet.  */

  getPrograms = () => {
    let programs = DashboardStore.getProgramsDP();
    if (programs.length > 0) {
      this.setState({
        programsDP: programs.map(this.mappedPrograms),
      });
    }
  };

  getProgramsError = (res) => {
    console.log(res);
  };

  mappedPrograms = (data) => (
    <ProgramsCard key={data.id} programs={data} onHandleProgram={this.selectProgram} />
  );

  handleAlertClose = () => {
    this.setState({
      alert: false,
    });
  };

  /* Modal Methods and Logic */

  handleProgramModal = () => {
    this.setState({
      programModal: !this.state.programModal,
    });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => {
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      };
    });
  };

  handleStartDateChange(value) {
    this.setState((prevState) => {
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          start_date: value,
        },
      };
    });
  }

  handleChangeSelected = (selectedOption) => {
    if (selectedOption != null) {
      let device = {
        id: parseInt(selectedOption.value),
        type: selectedOption.label,
      };
      this.setState({
        deviceSelected: device,
      });
    }
  };

  handleQuantityChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      quantity: value,
    });
  };

  handleBtnAddDevice = (e) => {
    e.preventDefault();
    let device = {
      id: this.state.deviceSelected.id,
      type: this.state.deviceSelected.type,
      quantity: parseInt(this.state.quantity),
    };
    let devicesArray = [...this.state.program_device_list, device];
    this.getAddedDevices(devicesArray);
    this.setState({
      program_device_list: devicesArray,
      deviceSelected: "",
      quantity: 0,
    });
    document.getElementById("qtybox").value = 0;
    document.getElementById("selectDevice").selectedIndex = 0;
  };

  getAddedDevices = (devicesArray) => {
    let data = devicesArray;
    this.setState({
      mapDevice: data.map(this.mappedDeviceAdded),
    });
  };

  mappedDeviceAdded = (data) => (
    <DeviceAddedCard
      key={data.deviceId}
      mapped={this.state.mapDevice}
      device={data}
      onHandleDelete={this.deleteDevice}
    />
  );

  deleteDevice = (id) => {
    let currentDeviceCards = this.state.mapDevice;
    let currentDevices = this.state.program_device_list;

    let newDeviceCards = currentDeviceCards.filter((data) => data.props.device.id != id);
    let newDevices = currentDevices.filter((data) => data.id != id);

    this.setState({
      mapDevice: newDeviceCards,
      program_device_list: newDevices,
    });
  };

  onSubmitRequest = (e) => {
    let programType = parseInt(this.state.formData.program_type_id);
    let token = this.props.currentUser.authToken;
    let payload = {
      program_name: this.state.formData.program_name,
      program_description: this.state.formData.program_description,
      start_date: this.state.formData.start_date,
      organization_id: this.props.currentUser.organization_id,
      warehouse_id: 3,
      program_type_id: programType,
      program_device_list: this.state.program_device_list,
    };
    programServices
      .createProgram(payload, token)
      .then(this.onCreateProgramSuccess)
      .catch(this.onCreateProgramError);
    e.reset();
  };

  onCreateProgramSuccess = () => {
    swal({
      title: "Program Created",
      // text: err,
      icon: "success",
      buttons: true,
    }).then(this.resetValues);
  };

  resetValues = () => {
    this.setState({
      formData: {
        start_date: undefined,
        programModal: false,
      },
      program_device_list: [],
      mapDevice: [],
      programModal: false,
    });
    this.getPrograms();
  };

  onCreateProgramError = (e) => {
    swal({
      title: "Please try again!",
      // text: err,
      icon: "error",
      buttons: true,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Collapse in={this.state.alert}>
          <Alert
            className="col-8 mt-4"
            variant="outlined"
            severity="info"
            onClose={this.handleAlertClose}
          >
            Program currently pending!
            {this.state.programsDP}
            <hr />
            <div className="">
              <Button onClick={this.handleProgramModal} variant="outline-info">
                Create Program
              </Button>
            </div>
          </Alert>
        </Collapse>

        <Modal
          show={this.state.programModal}
          onHide={this.handleProgramModal}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              New Program * Notes * for own Organization for children go to to Settings
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <Formik
                enableReinitialize={true}
                initialValues={this.state.formData}
                onSubmit={this.onSubmitRequest}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleSubmit,
                    onSubmitRequest,
                    isValid,
                    isInvalid,
                  } = props;
                  return (
                    <div className="create-program">
                      <div className="col-md-12 p-0">
                        <form onSubmit={handleSubmit} className="theme-form mega-form">
                          <Row>
                            <div>
                              {/* <label for="first_name">First Name</label> */}
                              <Field
                                type="text"
                                name="program_name"
                                onChange={this.handleChange}
                                values={this.state.formData.program_name}
                                required
                                placeholder="Name of New Program"
                                className="input-field w-input"
                              />
                              {errors.program_name && touched.program_name && (
                                <span className="input-feedback">{errors.program_name}</span>
                              )}
                            </div>
                          </Row>
                          <hr className="mt-4 mb-4" />

                          <Row>
                            <Form.Group as={Col} controlId="form-control">
                              <Form.Label>Expected Start Date</Form.Label>
                              <InputGroup className="date-range">
                                <DatePicker
                                  size="md"
                                  selected={this.state.formData.start_date}
                                  onChange={this.handleStartDateChange}
                                  required
                                  placeholderText="Start Date"
                                  dropdownMode="select"
                                  className="text-center"
                                />
                                <InputGroupAddon type="append">
                                  <InputGroupText>
                                    <i className="material-icons">&#xE916;</i>
                                  </InputGroupText>
                                </InputGroupAddon>
                              </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} controlId="">
                              <Form.Label>Program Type</Form.Label>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="program_type_id"
                                  required
                                  id="flexRadioDefault1"
                                  value={1}
                                  onChange={this.handleChange}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                  One-Time
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="program_type_id"
                                  required
                                  id="flexRadioDefault2"
                                  value={2}
                                  onChange={this.handleChange}
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                  On-Going
                                </label>
                              </div>
                            </Form.Group>
                          </Row>
                          {/* <FloatingLabel controlId="floatingTextarea" label="Program Description" className="mb-3"> */}
                          <Row>
                            <div>
                              <Field
                                type="text"
                                name="program_description"
                                onChange={this.handleChange}
                                values={this.state.formData.program_description}
                                required
                                placeholder="Program Description"
                                className="input-field w-input"
                              />
                              {errors.program_description && touched.program_description && (
                                <span className="input-feedback">{errors.program_description}</span>
                              )}
                            </div>
                          </Row>

                          {/* </FloatingLabel> */}
                          <hr className="mt-4 mb-4" />
                          <Row>
                            <Form.Group className="mb-4 col-6">
                              <Form.Label>Device Type</Form.Label>
                              <Select
                                id="selectDevice"
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable="true"
                                isSearchable="true"
                                name="deviceSelected"
                                options={this.state.priceBook}
                                onChange={this.handleChangeSelected}
                              />
                            </Form.Group>
                            <Form.Group className="mb-4 col-4">
                              <Form.Label>Device Quantity</Form.Label>
                              <Field
                                id="qtybox"
                                type="number"
                                name="quantity"
                                values={values.quantity}
                                onChange={this.handleQuantityChange}
                                placeholder="Quantity"
                                className={
                                  errors.quantity && touched.quantity
                                    ? "form-control"
                                    : "form-control"
                                }
                              />
                              {errors.quantity && touched.quantity && (
                                <span className="input-feedback">{errors.quantity}</span>
                              )}
                            </Form.Group>
                            <Form.Group as={Col} className="mt-2">
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={this.handleBtnAddDevice}
                                type="button"
                              >
                                +
                              </button>
                            </Form.Group>
                          </Row>
                          <div>{this.state.mapDevice}</div>

                          <button type="submit" className="btn btn-sm btn-primary mt-4">
                            Create
                          </button>
                        </form>
                      </div>
                    </div>
                  );
                }}
              </Formik>
            </Container>
            {/* <Modal.Footer> */}
            {/* </Modal.Footer> */}
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ProgramAlert;
