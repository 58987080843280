import React from "react";
import * as userServices from "../../../services/userServices.js";
import swal from "sweetalert";
// import "../../../../../css/user-actions.css"
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
} from "shards-react";

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      firstName: "",
      lastName: ""
    };
    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  componentDidMount = () =>{
    this.apiAuthMe()
  }

  componentWillUnmount = () =>{
    console.log("hasUnmounted")
  }

  apiAuthMe = () =>{
    let currentUserLocalStorage = localStorage.getItem("currentUser")
    console.log(JSON.parse(currentUserLocalStorage), "localStorage")
    let user = JSON.parse(currentUserLocalStorage)
    userServices
      .authorizeUser(user.authToken)
      .then(this.authMeSuccess)
      .catch(console.log("error"))
  }

  authMeSuccess = (res) =>{
    this.setState({firstName: res.first_name, lastName: res.last_name})
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible,
    });
  }
  onClickLogout = () => {
    localStorage.clear();
    console.log("Click")
    this.onSuccessLogout()
    // UserService.logOut().then(this.onSuccessLogout).catch(this.onErrorLogout);
  };

  onSuccessLogout = () => {
    swal("Goodbye", "See you next time!", "success");
    localStorage.clear();
    setTimeout(() => {
      swal.close();
      // this.history.push("/", { type: "logout", logout: true });
      window.location = "/login";
    }, 2000);
  };
  onErrorLogout = (error) => {
    toast.error(error.message);
  };

  onClickEditProfile = () => {
    // this.history.push("/rep/settings")
  };

  render() {
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
        <p className="icomoon profile-icon"><br/></p>{" "}
          <span className="d-none d-md-inline-block">{this.state.firstName} {this.state.lastName}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          {/* <DropdownItem tag={Link} to="user-profile">
            <i className="material-icons">&#xE7FD;</i> Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="edit-user-profile">
            <i className="material-icons">&#xE8B8;</i> Edit Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="file-manager-list">
            <i className="material-icons">&#xE2C7;</i> Files
          </DropdownItem>
          <DropdownItem tag={Link} to="transaction-history">
            <i className="material-icons">&#xE896;</i> Transactions
          </DropdownItem>
          <DropdownItem divider /> */}
          <DropdownItem className="text-danger">
          <div className="text-nowrap log_off px-3">
          <Link to="/">
                        <a className="log_off">
                          
                          <span className="btn__label"
                          onClick={this.onClickLogout}>Log Out</span>
                        </a>
                      </Link>
        </div>

          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}
