/* eslint-disable import/no-anonymous-default-export */
export default function () {
    return [
        {
            title: "Home",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe913;</h5>',
            to: "/home",
        },
        {
            title: "Tracking",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe935;</h5>',
            to: "/tracking",
        },
        {
            title: "Audit",
            to: "/audit",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe909;</h5>',
            htmlAfter: ""
        },
        // {
        //     title: "Tools",
        //     htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe917;</h5>',
        //     to: "/tools",
        // },
        {
            title: "Support",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe930</h5>',
            to: "/support",
        }
    ];
}
