import axios from "axios";
import * as helper from "./serviceHelper";
import * as env from "./env";

const endpoint = env.url

let getTracking = (adress_id, program_id, page_num, per_page, sort_by, shipping_status_id, tracking_reference, tracking_number, start_date, end_date) => {

  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/tracking/program?adress_id=${adress_id}&program_id=${program_id}&page_num=${page_num}&per_page=${per_page}&sort_by=${sort_by}&shipping_status_id=${shipping_status_id}&tracking_reference=${tracking_reference}&tracking_number=${tracking_number}&start_date=${start_date}&end_date=${end_date}`,
    withCredentials: false,
    crossdomain: true,
    headers: {"Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let getTrackingDetails = (id) => {

  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/get_tracking_details?tracked_box_id=${id}`,
    withCredentials: false,
    crossdomain: true,
    headers: {"Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

// let createProgram = (payload) =>{
//   const config = {
//     data: payload,
//     method: "POST",
//     url: "https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/program/create",
//     withCredentials: false,
//     crossdomain: true,
//     headers: { "Content-Type": "application/json", Accept: "application/json" }
//   };
//   return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
// }

export { getTracking, getTrackingDetails }