import React from "react";
import moment from 'moment'

const DetailsTrackingCard = (props) => {
    return (
        <React.Fragment>
            <h6>{props.data.city.length > 0 ? props.data.city : "Origin"} | {props.data.shipping_status} | {moment(props.data.status_date).format("L")}</h6>
        </React.Fragment>
    )
}

export default DetailsTrackingCard;