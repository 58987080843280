import React, { useState } from "react";
import moment from 'moment'

const TableCard = (props) => {

    /* 
        --------------------------------------------------------- 
        name:   format_tracking_number 
        desc:   formats tracking number into full fedex url
        parms:
                tracking_number: tracking number to format
        return: formatted url to fedex tracking
        ---------------------------------------------------------
    */
    const format_tracking_number = (tracking_number) => {
        return tracking_number ? (
            <a target="_blank" href={`https://www.fedex.com/fedextrack/?trknbr=${tracking_number}`}>{tracking_number}</a>
        ) : (
            <span></span>
        )
    }

    /* 
        --------------------------------------------------------- 
        name:   format_date
        desc:   formats date/timestamp into readable date
        parms:
                date_to_format: date/timestamp to be formatted
        return: formatted date
        todo:   instead of hard-coding the date format, use the locale code instead (eg. date.toLocaleString(local_code))
        ---------------------------------------------------------
    */
    const format_date = (date_to_format) => {
        return date_to_format ? moment(date_to_format).format("MM/DD/YYYY") : "";
    }

    const onhandleDetails = () =>{
        props.handleDetails(props.tracking)
    }
  
    return (
            <React.Fragment>

                <tr>
                    <td>{props.tracking.box_status}</td>
                    <td>{format_date(props.tracking.status_date)}</td>
                    <td>{format_date(props.tracking.created_at)}</td>
                    <td>{props.tracking.program_name}</td>
                    <td>{format_tracking_number(props.tracking.ret_tracking_number)}</td>
                    <td>{format_tracking_number(props.tracking.out_tracking_number)}</td>
                    <td>{props.tracking.tracking_reference}</td>
                    <td>{props.tracking.model ? props.tracking.model : "n/a"}</td>
                    <td>{props.tracking.package_type}</td>
                    <td>{props.tracking.contact_list.length > 0 ? props.tracking.contact_list[0].first_name : ""}</td>
                    <td>{props.tracking.contact_list.length > 0 ? props.tracking.contact_list[0].last_name : ""}</td>
                    <td>{props.tracking.organization}</td>
                    <td>{props.tracking.contact_list.length > 0 ? props.tracking.contact_list[0].email : ""}</td>
                    <td>{props.tracking.contact_list.length > 0 ? props.tracking.contact_list[0].phone : ""}</td>
                    <td>{props.tracking.address1}</td>
                    <td>{props.tracking.city}</td>
                    <td>{props.tracking.state}</td>
                    <td>{props.tracking.postal_code}</td>
                    <td>{props.tracking.coverted_date}</td>
                    <td style={{position : "sticky", right:"0px", background:"white"}}><button value={props.tracking.id} onClick={onhandleDetails}>Details</button></td>
                </tr>
            </React.Fragment>
    )
}

export default TableCard;