import * as React from "react";
import { CardBody, CardFooter } from "shards-react"
import { Modal, Form, Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Formik, Field } from "formik";
import AddedDevice from "./AddedDevice";
import * as requestServices from "../../../services/requestServices";
import * as userServices from "../../../services/userServices";
import * as DashboardActions from "../../../flux/DashboardActions";
import swal from "sweetalert";
import DashboardStore from "../../../flux/DashboardStore";
import "./request.css";
import { Address } from "../../shared-components/common/Address";

class Request extends React.Component {
    constructor(props) {
        super(props);
        this.getAddresses = this.getAddresses.bind(this);
        this.state = {
            formData: {
                reference: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                postal_code: "",
                country: "",
                location_name: "",
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
            },
            verifyAddress: false,
            saveLocationAlert: false,
            currentId: 0,
            mappedDevices: [],
            device: "",
            qty: "",
            type: "",
            devices_array: [],
            contact_id_list: "",
            location_name: "",
            processed: false,
            program_id: 0,
            created_by: 0,
            address_id: 0,
            requestModal: false,
            options: [],
            addressesList: DashboardStore.getAddresses(),
            deviceOptions: [
                { id: "0", value: "0", name: "Select type" },
                { id: "DO1", value: "Phone", name: "Phone" },
                { id: "DO2", value: "Tablet", name: "Tablet" },
                { id: "DO3", value: "Laptop", name: "Laptop" },
                { id: "DO4", value: "Desktop", name: "Desktop" },
                { id: "DO5", value: "Accessory Boxes", name: "Accessory Boxes" },

            ],
            open_box: false
        }
    }
    componentDidMount = (nextProps, prevState) => {
        // console.log("didMount", nextProps, prevState, this.props)
        DashboardStore.on("CHANGE", this.getAddresses)


    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(prevProps, prevState, this.props, this.state, "DidUpdate")
        if (this.state.addressesList.length === 0 && prevProps.currentUser !== this.props.currentUser && DashboardStore.getAddresses().length === 0) {
            DashboardActions.apiCallGetAddresses(this.props.currentUser.id)
        }
        if(this.state.mappedDevices.length === 0 && this.state.devices_array.length > 0){
            this.getAddedDevices(this.state.devices_array)
        }
        // else if(this.state.addressesList.length === 0 ){
        //     DashboardActions.apiCallGetAddresses(prevProps.currentUser.id)
        // }
    }

    componentWillUnmount = (nextProps, prevState) => {
        // console.log(nextProps, prevState, this.state, "WillUnmount")
        DashboardStore.off("CHANGE", this.getAddresses)
    }

    getAddresses = () => {
        this.setState({
            addressesList: DashboardStore.getAddresses()
        })
    }

    getDeviceOptions = () => {
        this.setState({
            deviceOptions: [
                { id: "0", value: "0", name: "Select type" },
                { id: "DO1", value: "Phone", name: "Phone" },
                { id: "DO2", value: "Tablet", name: "Tablet" },
                { id: "DO3", value: "Laptop", name: "Laptop" },
                { id: "DO4", value: "Desktop", name: "Desktop" },
                { id: "DO5", value: "Accessory Boxes", name: "Accessory Boxes" },

            ],
            options: [],
            qty: ""
        })
    }

    getTabletType = () => {
        this.setState({
            options: [
                { id: "0", value: "0", name: "Size/Model" },
                { id: "T1", value: "Regular", name: "Regular" },
                { id: "T2", value: "Large", name: "Large" }
            ]

        })
    }

    getLaptopType = () => {
        this.setState({
            options: [
                { id: "0", name: "Size/Model" },
                { id: "L0", name: '11" Laptop' },
                { id: "L1", name: '13" Laptop' },
                { id: "L2", name: '15" Laptop' },
            ]

        })
    }

    getDesktopType = () => {
        this.setState({
            options: [
                { id: "0", name: "Size/Model" },
                { id: "D1", name: '21" iMac' },
                { id: "D2", name: '24" iMac' },
                { id: "D3", name: '27" iMac' },
                { id: "D4", name: 'Mac Mini' }
            ]

        })
    }

    getPhoneType = () => {
        this.setState({
            options: [
                { id: "0", name: "Size/Model" },
                { id: "P1", name: "Standard" },
                { id: "P2", name: "Extra Large" }
            ],
            //type: "Default"

        })
    }

    getDisplayType = () => {
        this.setState({
            options: [
                { id: "0", name: "Size/Model" },
                { id: "Dis1", name: '27"' },
                { id: "Dis2", name: 'Less than 27"' }
            ]

        })
    }

    getOtherType = () => {
        this.setState({
            options: [
                { id: "0", name: "Choose items..." },
                { id: "Other1", name: 'ULine 24x20x16' },
                { id: "Other2", name: 'ULine 24x20x12' },
                { id: "Other3", name: 'ULine 18x18x18' }

            ]

        })
    }

    deviceChosen = (e) => {
        let value = e.target.value;

        if (value === "Tablet") {
            this.getTabletType()
        } else if (value === "Laptop") {
            this.getLaptopType()
        } else if (value === "Desktop") {
            this.getDesktopType()
        } else if (value === "Phone") {
            this.getPhoneType()
        } else if (value === "Display") {
            this.getDisplayType()
        } else if (value === "Accessory Boxes") {
            this.getOtherType()
        } else if (value === "Select type") {
            this.getDeviceOptions()
        }

        this.setState({
            device: value
        })
    }

    handleType = (e) => {

        let value = e.target.value;
        this.setState({
            type: value
        })
    }

    handleQty = (e) => {
        let value = e.target.value;

        this.setState({
            qty: value,
            //btnEnable: false
        })
    }

    addDevice = (e) => {
        let id = this.state.currentId + 1

        let devices = {
            id: id,
            device: this.state.device,
            type: this.state.type,
            qty: parseInt(this.state.qty)
        }

        let newArray = [...this.state.devices_array, devices]
        this.getAddedDevices(newArray)
        this.setState({
            devices_array: newArray,
            device: "",
            options: [],
            type: "",
            qty: "",
            currentId: id,
            deviceOptions: [
                { id: "0", value: "0", name: "Select type" },
                { id: "DO1", value: "Phone", name: "Phone" },
                { id: "DO2", value: "Tablet", name: "Tablet" },
                { id: "DO3", value: "Laptop", name: "Laptop" },
                { id: "DO4", value: "Desktop", name: "Desktop" },
                { id: "DO5", value: "Accessory Boxes", name: "Accessory Boxes" },

            ]
        })
        document.getElementById('selectDevice').selectedIndex = 0;
        document.getElementById('qtybox').value = 0;
    }

    getAddedDevices = (devicesArray) => {
        let data = devicesArray;
        this.setState({
            mappedDevices: data.map(this.mapDevice)
        })
    }

    mapDevice = data => (
        <AddedDevice
            mapped={this.state.mappedDevices}
            key={data.id}
            devices={data}
            onHandleDelete={this.onHandleDelete}
        />
    );

    onHandleDelete = (e) => {
        let id = e.devices.id;
        let currentDeviceCards = this.state.mappedDevices
        let currentDevices = this.state.devices_array

        let newDeviceCards = currentDeviceCards.filter(data => data.props.devices.id != id)
        let newDevices = currentDevices.filter(data => data.id != id)

        this.setState({
            mappedDevices: newDeviceCards,
            devices_array: newDevices
        })
    }

    handleRequestModal = (e) => {
        this.setState({
            requestModal: !this.state.requestModal,
            verifyAddress: false
        })
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === "address1") {
            let ifAddressExist = this.state.addressesList.filter(data => data.address1 === value)
            if (ifAddressExist.length === 1) {
                this.setState({
                    saveLocationAlert: false
                })
            } else {
                this.setState({
                    saveLocationAlert: true
                })
            }
        }

        this.setState((prevState) => {
            return {
                ...prevState,
                formData: {
                    ...prevState.formData,
                    [name]: value,
                },
            };
        });
    };

    onSubmitRequest = (e) => {

        let payload = {
            program_id: DashboardStore.getUserSettings().active_program_id,
            reference: this.state.formData.reference,
            request_type_id: 2,
            address: {
                location_name: this.state.formData.location_name,
                address1: this.state.formData.address1,
                address2: this.state.formData.address2,
                city: this.state.formData.city,
                state: this.state.formData.state,
                postal_code: this.state.formData.postal_code,
                country: this.state.formData.country,
            },
            contact_list: [{
                first_name: this.state.formData.first_name,
                last_name: this.state.formData.last_name,
                email: this.state.formData.email,
                phone: this.state.formData.phone,
            }],
            device_list: this.state.devices_array,
            request_id: this.props.currentUser.id
        }
        let token = this.props.currentUser.authToken
        requestServices
            .createRequest(payload, token)
            .then(this.onCreateSuccess)
            .catch(this.onCreateError)
        e.reset()
    }

    onCreateSuccess = () => {
        swal({
            title: "Request Created",
            // text: err,
            icon: "success",
            buttons: true,
        }).then(this.resetModalForm())
    }

    resetModalForm = () =>{
        this.setState({
            formData: {},
            devices_array: [],
            verifyAddress: false,
            mappedDevices:[],
            requestModal: false,
        })
    }

    onCreateError = () => {
        swal({
            title: "Please try again!",
            // text: err,
            icon: "error",
            buttons: true,
        })
    }

    addressChosen = (e) => {
        let id = parseInt(e.target.value)
        let chosenAddress = this.state.addressesList.filter(data => data.id === id)
        let obj = chosenAddress[0]

        this.setState((prevState) => {
            return {
                ...prevState,
                verifyAddress: true,
                formData: {
                    first_name: this.state.formData.first_name,
                    last_name: this.state.formData.last_name,
                    phone: this.state.formData.phone,
                    reference: this.state.formData.reference,
                    email: this.state.formData.email,
                    address1: obj.address1,
                    address2: obj.address2,
                    city: obj.city,
                    state: obj.state,
                    postal_code: obj.postal_code,
                    country: obj.country,
                },
                saveLocationAlert: false
            };
        });
    }

    onSaveLocation = () => {
        let payload = {
            address1: this.state.formData.address1,
            address2: this.state.formData.address2,
            city: this.state.formData.city,
            state: this.state.formData.state,
            postal_code: this.state.formData.postal_code,
            country: this.state.formData.country,
            location_name: this.state.formData.location_name,
            created_by: this.props.currentUser.id,
            organization_id: this.props.currentUser.organization_id
        }
        userServices
            .createAddress(payload)
            .then(this.onAddressSaveSuccess)
            .catch(this.onAddressSaveError)
    }

    onAddressSaveSuccess = (e) => {
        this.setState({
            saveLocationAlert: false
        })
    }

    onAddressSaveError = (e) => {
        console.log(e, "Error")
    }

    onHandleLoqate = (e) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                formData: {
                    ...prevState.formData,
                    first_name: this.state.formData.first_name,
                    last_name: this.state.formData.last_name,
                    phone: this.state.formData.phone,
                    reference: this.state.formData.reference,
                    email: this.state.formData.email,
                    address1: e.Line1,
                    address2: e.Line2,
                    city: e.City,
                    state: e.ProvinceCode,
                    postal_code: e.PostalCode,
                    country: e.CountryIso2,
                },
                verifyAddress: true
            };
        });
    };

    toggleLocationBox = () => {
        this.setState({
            open_box: !this.state.open_box
        })
    }

    render() {
        const { deviceOptions } = this.state;

        let deviceOptionList = deviceOptions.length > 0 && deviceOptions.map((item, i) => {
            return (

                <option key={i} value={item.name}>{item.name}</option>
            )
        }, this);

        const { addressesList } = this.state;

        let addressOptionList = addressesList.length >= 0 && addressesList.map((item, i) => {
            return (

                <option key={i} value={item.id}>{item.location_name}</option>
            )
        }, this);

        const { options } = this.state;

        let optionsList = options.length > 0 && options.map((item, i) => {
            return (
                <option key={i} value={item.name}>{item.name}</option>
            )
        }, this);

        return (
            <React.Fragment>
                <Modal className="request-modal" show={this.state.requestModal} onHide={this.handleRequestModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="transparent-inner-icon">
                                <h2 className="icomoon _00 print-label">&#xe920;</h2>
                                <h4 >Request Packaging &amp; Shipping Label</h4>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">

                        {/* Using Form.Control it resets input but does not hydrate form when selecting saved
                            address. For that to work we need to use File tag */}
                        <Formik
                            enableReinitialize={true}
                            initialValues={this.state.formData}
                            onSubmit={this.onSubmitRequest}
                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleSubmit,
                                    onSubmitRequest,
                                    isValid,
                                    isSubmitting,
                                    isInvalid
                                } = props;
                                return (
                                    <Form onSubmit={handleSubmit}>
                                        <div className="request-packaging-shipping-label w-form">
                                            <div id="wf-form-Request-Packaging-Shipping-Label" name="wf-form-Request-Packaging-Shipping-Label" data-name="Request Packaging &amp; Shipping Label" method="get" className="form registration _00" aria-label="Request Packaging &amp; Shipping Label">
                                                <div className="form-heading-div flex form">
                                                    <div className="form-flex">
                                                        <h5 className="form-heading">What are you sending us?<br /></h5>
                                                    </div>
                                                </div>
                                                <p>Please list out the device type, its size and quantity.</p>
                                                <form className="w-layout-grid input-fields-grid answer-spacing _1">
                                                    <div className="div-block-7 up-24">
                                                        <label for="location-3">Device Type</label>
                                                        <select className="select w-select" placeholder="Select Device Type" id="selectDevice" onChange={this.deviceChosen} required>
                                                            {deviceOptionList}
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <label for="location-3">Size Type</label>
                                                        <select className="select w-select" placeholder="" id="selectType" onChange={this.handleType} required>
                                                            {optionsList}
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <label for="Manufacturer-Name-3">Quantity</label>
                                                        {(this.state.device === "Choose...") ?
                                                            <input className="input-field w-input" type="number" placeholder="" id="qtybox" onChange={this.handleQty} required></input> :
                                                            <input className="input-field w-input" type="number" placeholder="" id="qtybox" onChange={this.handleQty} required></input>
                                                        }
                                                    </div>
                                                    <button id="btn_add" className="secondary-button save-form _2 w-button" data-verify="Submit" value="Submit" type="button" onClick={this.addDevice}>Add</button>
                                                </form>

                                                <table className="mapped_devices">
                                                    <th>Device Type</th><th>Size</th><th className="quantity">Quantity</th><th></th>
                                                    {this.state.mappedDevices}
                                                </table>


                                                <label for="Manufacturer-Name-4">Select a saved location</label>
                                                <select className="select w-select" placeholder="Saved Locations" onChange={this.addressChosen}>
                                                    <option key="0" value="0">Saved Locations</option>
                                                    {addressOptionList}
                                                </select>
                                                <div className="w-layout-grid input-fields-grid">
                                                    <div>
                                                        <label for="first_name">First Name</label>
                                                        <Field
                                                            type="text"
                                                            name="first_name"
                                                            onChange={this.handleChange}
                                                            values={this.state.formData.first_name}
                                                            required
                                                            placeholder="Enter First Name"
                                                            className="input-field w-input"
                                                        />
                                                        {errors.first_name && touched.first_name && (
                                                            <span className="input-feedback">
                                                                {errors.first_name}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <label for="last_name">Last Name</label>
                                                        <Field
                                                            type="text"
                                                            name="last_name"
                                                            required
                                                            values={this.state.formData.last_name}
                                                            onChange={this.handleChange}
                                                            placeholder="Enter Last Name"
                                                            className="input-field w-input"
                                                        />
                                                        {errors.last_name && touched.last_name && (
                                                            <span className="input-feedback">
                                                                {errors.last_name}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <label for="email">Email</label>
                                                        <Field
                                                            type="email"
                                                            name="email"
                                                            values={this.state.formData.email}
                                                            onChange={this.handleChange}
                                                            required
                                                            placeholder="name@company.com"
                                                            className="input-field w-input"
                                                        />
                                                        {errors.email && touched.email && (
                                                            <span className="input-feedback">
                                                                {errors.email}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <label for="phone">Phone</label>
                                                        <Field
                                                            type="tel"
                                                            required
                                                            name="phone"
                                                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                                            values={this.state.formData.phone}
                                                            onChange={this.handleChange}
                                                            placeholder="### - ### - ###"
                                                            className="input-field w-input"
                                                        />
                                                        {errors.phone && touched.phone && (
                                                            <span className="input-feedback">
                                                                {errors.phone}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <label for="address1"><strong>Address</strong></label>
                                                        {this.state.verifyAddress === false ?
                                                            <Address
                                                                value={this.state.formData.address1}
                                                                onHandleLoqate={this.onHandleLoqate}
                                                                type="text"
                                                                name="address1"
                                                            // onChange={this.handleVerifyAddress}
                                                            />
                                                            :
                                                            <div>
                                                                <Field
                                                                    type="text"
                                                                    name="address1"
                                                                    autoComplete={['off', 'chrome-off']}
                                                                    values={values.address1}
                                                                    placeholder="ex. 123 Street"
                                                                    className="input-field w-input"
                                                                />
                                                                {errors.address1 && touched.address1 && (
                                                                    <span className="input-feedback">
                                                                        {errors.address1}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        <label for="address2">Suite Number</label>
                                                        <Field
                                                            type="text"
                                                            name="address2"
                                                            autoComplete={['off', 'chrome-off']}
                                                            values={this.state.formData.address2}
                                                            placeholder="Enter Apt/Unit"
                                                            className="input-field w-input"
                                                        />
                                                        {errors.address2 && touched.address2 && (
                                                            <span className="input-feedback">
                                                                {errors.address2}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <label for="city"><strong>City</strong><br /></label>
                                                        <Field
                                                            type="text"
                                                            name="city"
                                                            required
                                                            onChange={this.handleChange}
                                                            values={this.state.formData.city}
                                                            placeholder="City"
                                                            className="input-field w-input"
                                                        />
                                                        {errors.city && touched.city && (
                                                            <span className="input-feedback">
                                                                {errors.city}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <label for="state"><strong>State/Province</strong><br /></label>
                                                        <Field
                                                            type="text"
                                                            name="state"
                                                            required
                                                            pattern="[A-Za-z]{2}"
                                                            values={this.state.formData.state}
                                                            onChange={this.handleChange}
                                                            placeholder="Enter State"
                                                            className="input-field w-input"
                                                        />
                                                        {errors.state && touched.state && (
                                                            <span className="input-feedback">
                                                                {errors.state}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <Form.Group>
                                                        <label for="postal_code"><strong>Postal Code</strong><br /></label>
                                                        <Field
                                                            type="text"
                                                            onChange={this.handleChange}
                                                            name="postal_code"
                                                            values={this.state.formData.postal_code}
                                                            required
                                                            placeholder="Postal Code"
                                                            className={
                                                                errors.postal_code && touched.postal_code
                                                                    ? "input-field w-input"
                                                                    : "input-field w-input"
                                                            }
                                                        // className="input-field w-input"
                                                        />
                                                        {errors.postal_code && touched.postal_code && (
                                                            <span className="input-feedback">
                                                                {errors.postal_code}
                                                            </span>
                                                        )}
                                                    </Form.Group>
                                                    <div>
                                                        <label for="Select-Country-2" className="field-label">Country *</label>
                                                        <Field
                                                            type="text"
                                                            name="country"
                                                            pattern="[A-Za-z]{2}"
                                                            values={this.state.formData.country}
                                                            onChange={this.handleChange}
                                                            placeholder="Enter Country"
                                                            className="input-field w-input"
                                                        />
                                                        {errors.country && touched.country && (
                                                            <span className="input-feedback">
                                                                {errors.country}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <label for="Tracking-Reference">Tracking Reference # (Optional)</label>
                                                <Field
                                                    type="text"
                                                    name="reference" values={this.state.formData.reference}
                                                    onChange={this.handleChange}
                                                    placeholder="Enter Reference"
                                                    className="input-field w-input"
                                                />
                                                {errors.reference && touched.reference && (
                                                    <span className="input-feedback">
                                                        {errors.reference}
                                                    </span>
                                                )}
                                                {this.state.saveLocationAlert === true ?
                                                    <div className="row">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="location_name"
                                                            values={values.location_name}
                                                            placeholder="Give this location a name"
                                                            id="save_location_input"
                                                            onChange={this.handleChange}
                                                        />
                                                        <label placeholder=""><span>Save this location for a faster request next time?</span></label>
                                                        <Button onClick={this.onSaveLocation}>Save</Button>
                                                    </div> : ""
                                                }
                                                <div className={(this.state.open_box ? "open " : "") + "faq-element box"}>
                                                    <div className="faq-question box" onClick={this.toggleLocationBox}>
                                                        <div className="faq-text">Save to "Saved locations" (Optional)<br /></div>
                                                        <div className="plus-div">
                                                            <div className="plus-horizontal"></div>
                                                            <div className="plus-vertical"></div>
                                                        </div>
                                                    </div>
                                                    <div className="faq-answer box bg-l-purple">
                                                        <div className="form-flex up-24">
                                                            <div className="div-block-7">
                                                                <label for="Save-Location">Location Nickname</label>
                                                                <input
                                                                    className="input-field w-input"
                                                                    type="text"
                                                                    name="location_name"
                                                                    values={values.location_name}
                                                                    placeholder="Give this location a name"
                                                                    id="save_location_input"
                                                                    onChange={this.handleChange}
                                                                />
                                                            </div>
                                                            <button className="secondary-button save-form w-button" onClick={this.onSaveLocation}>Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button
                                                    type="submit"
                                                    id="submit"
                                                    name="submit"
                                                    disabled={!isValid || isSubmitting}
                                                    data-wait="Please wait..."
                                                    className="primary-btn full form-button w-button">Submit</button>
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Modal.Body>
                </Modal>
                <button className="quick-links-inner-div" onClick={this.handleRequestModal}>
                    <div className="div-block-5">
                        <div className="transparent-inner-icon">
                            <h4 className="icomoon _00">&#xe920;</h4>
                        </div>
                        <div className="div-block-6">
                            <p className="paragraph _00 bold down-6">Request Packaging</p>
                            <p className="paragraph _00 left _14">We'll provide everything you need to ship your devices.</p>
                        </div>
                    </div>
                    <div className="upper-div"></div>
                </button>
            </React.Fragment>
        )
    }
}

export default Request;