import React from "react";
import "./program.css"


const ProgramsCard = (props) => {


  return (
    <React.Fragment>
          <ui>
            <li className="prog-pending">{props.programs.program_name} - Pending</li>
          </ui>
    </React.Fragment>
  );
};

export default ProgramsCard;