import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Navbar } from "shards-react";

import NavbarSearch from "./NavbarSearch.js";
import NavbarNav from "../navbarNav/NavbarNav.js";
import NavbarToggle from "./NavbarToggle";

const MainNavbar = ({ layout, stickyTop }) => {
    const classes = classNames(
        "main-navbar",
        stickyTop && "sticky-top"
    );

    return (
        <div className={classes}>
            <Navbar type="light" className="align-items-stretch flex-md-nowrap">
                {/* <NavbarSearch /> */}
                <NavbarNav />
                <NavbarToggle />
            </Navbar>
        </div>
    );
};

MainNavbar.propTypes = {
    /**
     * The layout type where the MainNavbar is used.
     */
    layout: PropTypes.string,
    /**
     * Whether the main navbar is sticky to the top, or not.
     */
    stickyTop: PropTypes.bool
};

MainNavbar.defaultProps = {
    stickyTop: false
};

export default MainNavbar;
