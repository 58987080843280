import React from 'react'

export default function InventoryTableCard({item}) {
  return (
    <React.Fragment>
        <tr>
            <td>{item.make}</td>
            <td>{item.model}</td>
            <td>{item.memory_size}</td>
            <td>{item.carrier}</td>
            <td>{item.reference}</td>
        </tr>
    </React.Fragment>
  )
}
