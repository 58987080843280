import * as React from "react";
import {
  Table,
  Card,
  Row,
  Col,
  Modal,
  Form,
  Pagination,
  Container,
  ModalBody,
} from "react-bootstrap";
import Select from "react-select";
import * as organizationServices from "../../../services/organizationServices.js";
import * as programServices from "../../../services/programServices.js";
import DashboardStore from "../../../flux/DashboardStore";
import * as auditServices from "../../../services/auditServices.js";
import AuditCard from "./AuditCard.jsx";
import "./audit.css";
import { FaClipboard } from "react-icons/fa";
import AuditFilters from "./AuditFilters.jsx";
import AuditCarasoul from "./AuditCarasoul.jsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as userServices from "../../../services/userServices.js";
import AuditColumnsCard from "./AuditColumnsCard.jsx";
import PaginatedItems from "../../shared-components/common/PaginatedItems.jsx";

class Audit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: "",
      activeProgram: "",
      colCount: 11,
      tracking: [],
      mapTracking: [],
      mapHeaders: [],
      headers: [],
      orgOptions: [],
      prgOptions: [],
      programChosen: false,
      codModal: false,
      details: [],
      program: "",
      page_num: 1,
      viewAs: 0,
      filtersModal: false,
      pageOptions: [
        { value: 15, label: 15 },
        { value: 25, label: 25 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
        { value: 200, label: 200 },
      ],
      address_id: 0,
      location_name: "",
      program_id: 0,
      per_page: 15,
      sort_by: 0,
      tracking_reference: "",
      tracking_number: "",
      start_date: "",
      end_date: "",
      grade: "",
      serial_number: "",
      device_type_id: 0,
      download: false,
      filter: false,
      orgColumns: "",
      columnIndex: [],
      columnLabels: [],
      imgArray: [],
      imgModal: false,
      mappedImg: [],
      allChecked: false,
      auditRes: [],
      lock:"",
      approved_list: [],
      unapproved_list: [],
      approved_view: 0,
      carouselIndex: 0,
      auditImgId: 0,
      codDiv: {},
      codId: 0,
      codPdf: "",
      codPdfId: "",
      pageCount: 0,
      itemsTotal: 0,
      intialCount: 0,
      endCount: 0,
      columnsModal: false,
      imgLogic: false,
      mappedColumnToggle: [],
      mappedPagination: [],
      carouselImg: [],
      sortOptions: [
        { value: 2, label: "Start Date (by Newest)" },
        { value: 1, label: "Start Date (by Oldest)" },
        { value: 3, label: "Request Date (by Newest)" },
        { value: 4, label: "Request Date (by Oldest)" },
        { value: 5, label: "Model (A to Z)" },
        { value: 6, label: "Model (Z to A)" },
      ],
    };
  }

  /*
  If user does not have active_program_id they first need to create a program  
  */

  componentDidMount = () => {
    console.log(this.props, this.state, "didMount");
    // this.getOrgColumnView()
    DashboardStore.on("CHANGEUSERSETTINGS", this.programViewAs);
    if (
      DashboardStore.getUserSettings() !== undefined &&
      DashboardStore.getUserSettings().active_program_id !== 0
    ) {
      this.programViewAs();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    console.log(prevProps, prevState, this.state, "update");
    if (
      this.state.auditRes !== prevState.auditRes ||
      this.state.approved_list !== prevState.approved_list ||
      this.state.unapproved_list !== prevState.unapproved_list
    ) {
      this.mapAuditCardAgain();
    }
    if (this.state.auditRes !== prevState.auditRes) {
      this.mapAuditCardAgain();
    }
    if (
      this.state.viewAs === 0 &&
      DashboardStore.getUserSettings() !== undefined &&
      DashboardStore.getUserSettings().active_program_id !== 0
    ) {
      this.programViewAs();
    }
  };

  componentWillUnmount = () => {
    console.log("WillUnMount", this.props, this.state);
    DashboardStore.removeListener("CHANGEUSERSETTINGS", this.programViewAs);
  };

  programViewAs = () => {
    let userSettings = DashboardStore.getUserSettings();
    let id = userSettings.active_program_id;
    let orgId = DashboardStore.getOrgView();
    this.setState(
      {
        viewAs: id,
        activeProgram: userSettings.active_program,
      },
      () => {
        this.getOrgColumnView(orgId);
      }
    );
  };

  getOrgColumnView = () => {
    console.log("getOrgColumnView");
    let currentUser = this.props.currentUser;
    userServices
      .columnVisibility(currentUser.id, currentUser.authToken)
      .then(this.onOrgColumnSuccess)
      .catch(this.onOrgColumnError);
  };

  onOrgColumnSuccess = (res) => {
    let keys = Object.keys(res);
    let trueValues = Object.values(res);
    let visibleIndex = [];
    for (let i = 0; i < trueValues.length; i++) {
      if (trueValues[i] === true) {
        visibleIndex.push(i);
      }
    }
    let visibleValues = [];
    for (let j = 0; j < visibleIndex.length; j++) {
      let indexValues = visibleIndex[j];
      let resValue = keys[indexValues];
      visibleValues.push(resValue);
    }
    this.setState(
      {
        orgColumns: res,
        columnIndex: visibleIndex,
        columnLabels: visibleValues,
      },
      () => {
        this.getAuditHardcoded();
      }
    );
  };

  onOrgColumnError = (res) => {
    console.log(res, "Response");
  };

  getOrgs = () => {
    let orgId = this.props.organization_id;
    organizationServices.getOrgsByParent(orgId).then(this.getOrgsSuccess).catch(this.getOrgsError);
  };

  getOrgsSuccess = (response) => {
    let options = [];
    for (let i = 0; i < response.length; i++) {
      let obj = {};
      obj["value"] = response[i].id;
      obj["label"] = response[i].name;
      options.push(obj);
    }
    this.setState({
      orgOptions: response,
      options: options,
    });
  };

  getOrgsError = (e) => {
    console.log(e, "error");
  };

  handleChangeSelected = (e) => {
    if (e != null && this.state.programChosen === false) {
      this.getProgramsOfOrg(e.value);
    }
    if (e === null) {
      this.setState(
        {
          mappedAudit: [],
          options: [],
          orgOptions: [],
          prgOptions: [],
          programChosen: false,
          program: "",
        },
        this.getOrgs()
      );
    }
    if (e != null && this.state.programChosen === true) {
      this.setState({
        program: e.label,
      });
      this.getAuditHardcoded();
    }
  };

  getProgramsOfOrg = (orgId) => {
    let token = this.props.currentUser.authToken;
    programServices
      .getProgramsByOrg(orgId, token)
      .then(this.onGetProgramsSuccess)
      .catch(this.onGetProgramsError);
  };
  onGetProgramsSuccess = (response) => {
    let prgOptions = [];
    for (let i = 0; i < response.length; i++) {
      let obj = {};
      obj["value"] = response[i].id;
      obj["label"] = response[i].program_name;
      prgOptions.push(obj);
    }
    this.setState({
      options: prgOptions,
      prgOptions: prgOptions,
      programChosen: true,
    });
  };

  onGetProgramsError = (e) => {
    console.log(e, "Error");
  };

  handleSortByChange = (selectedOption) => {
    this.setState(
      {
        sort_by: selectedOption.value,
      },
      () => {
        this.getAuditHardcoded();
      }
    );
  };

  getAuditHardcoded = (page) => {
    let userSettings = DashboardStore.getUserSettings();
    // let id = userSettings.active_program_id
    let role = this.props.currentUser.role;
    let token = this.props.currentUser.authToken;
    let program_id = userSettings.active_program_id;
    let page_num = page === undefined ? this.state.page_num : page;
    let per_page = this.state.per_page;
    let tracking_reference = this.state.tracking_reference;
    let tracking_number = this.state.tracking_number;
    let start_date = this.state.start_date;
    let end_date = this.state.end_date;
    let grade = this.state.grade;
    let serial_number = this.state.serial_number;
    let device_type_id = this.state.device_type_id;
    let download = this.state.download;
    let location_name = this.state.location_name;
    let approved_view = role === "Super" ? this.state.approved_view : 1;
    auditServices
      .getAuditUpdates(
        token,
        program_id,
        page_num,
        per_page,
        tracking_reference,
        tracking_number,
        start_date,
        end_date,
        grade,
        serial_number,
        device_type_id,
        download,
        location_name,
        approved_view
      )
      .then(this.getAuditSuccess)
      .catch(this.getAuditError);
  };

  error = (e) => {
    console.log(e);
  };
  getAuditSuccess = (response) => {
    console.log(response);
    let data = response.items;
    let approved_list = [];
    let unapproved_list = [];
    let itemsTotal = response.itemsTotal;
    let intialCount = response.curPage === 1 ? 1 : (response.curPage - 1) * this.state.per_page + 1;
    let endCount =
      response.curPage === 1
        ? response.itemsReceived
        : (response.curPage - 1) * this.state.per_page + response.itemsReceived;

    for (let i in data) {
      let approved = data[i].approved;
      let id = data[i].id;
      approved === true ? approved_list.push(id) : unapproved_list.push(id);
    }

    this.setState({
      pageCount: response.pageTotal,
      approved_list: approved_list,
      unapproved_list: unapproved_list,
      intialCount: intialCount,
      itemsTotal: itemsTotal,
      endCount: endCount,
      // imgArray: imgArray,
      auditRes: data,
      // mappedPagination: this.mappingPaginationItem(response.pageTotal),
      // mappedAudit: data.map(this.mapAudit),
      filtersModal: false,
    });
  };

  callBack = (items) => {
    let auditItems = [];
    for (let i = 0; i < items.length; i++) {
      let p2 = i + 1;
      console.log(i, p2, "pointers");
      if (p2 !== items.length) {
        if (items[i].id === items[p2].id) {
          p2 = p2 + 1;
        } else {
          auditItems.push(items[i]);
          p2 = p2 + 1;
        }
      } else {
        auditItems.push(items[i]);
      }
    }
    this.setState({
      auditRes: auditItems,
      imgLogic: true,
    });
  };

  manipulateImg = (e) => {
    console.log(e, "manipulateImg");
  };

  getAuditError = (e) => {
    console.log(e, "AuditData");
  };

  mapAudit = (data) => (
    <AuditCard
      column={this.state.orgColumns}
      data={data}
      key={data.id}
      onHandleImg={this.onHandleImg}
      checked={this.state.allChecked}
      role={this.props.currentUser.role}
      onHandleCheck={this.onHandleCheck}
      approved_list={this.state.approved_list}
      unapproved_list={this.state.unapproved_list}
      onHandleCod={this.onHandleCod}
    />
  );

  onHandleCod = (id) => {
    this.setState({
      codId: id,
    });
    auditServices.auditCOD(id).then(this.onGetCODSuccess).catch(this.onGetCODError);
  };

  onGetCODSuccess = (res) => {
    this.setState({
      codDiv: res,
      codModal: true,
    });
  };

  onGetCODError = () => { };

  handleDownloadCOD = (e) => {
    let id = this.state.codId;
    let token = this.props.currentUser.authToken;
    auditServices
      .requestCOD(id, token)
      .then(this.onRequestCodPdfSuccess)
      .catch(this.onRequestCodPdfError);

    toast.info("Please Wait ...", {
      position: "top-right",
      autoClose: 8500,
      pauseOnHover: false,
      pauseOnFocusLoss: false,
      hideProgressBar: false,
      // onClose: this.closeToastify,
      onClose: this.checkPdfStatusCOD,

    });
  };

  onRequestCodPdfSuccess = (res) => {
    let url = res.file_name;
    let id = res.id

    console.log(url, res, "onRequestCodPdfSuccess");
    let b64 = res.file_name;
    this.setState({
      codPdf: url,
      codPdfId: id
    });
  };

  onRequestCodPdfError = (res) => {
    console.log(res, "onRequestCodPdfError")
  };

  checkPdfStatusCOD = () => {
    let id = this.state.codPdfId;
    let token = this.props.currentUser.authToken;
    auditServices.CODexist(id, token).catch(this.onStatusSuccess).then(this.onStatusError);
  };


  onStatusSuccess = (res) => {
    console.log(res, "onStatusSuccess: COD already exist");
    let url = this.state.codPdf;
    window.open(url);
  };

  onStatusError = (res) => {
    console.log(res, "onStatusError: COD ");
    let url = this.state.codPdf;
    window.open(url);
    // let id = this.state.codPdfId;
    // let token = this.props.currentUser.authToken;
    // auditServices
    //   .requestCOD(id, token)
    //   .then(this.onRequestCodPdfSuccess)
    //   .catch(this.onRequestCodPdfError);
  };


  //always gives error


  onCODexistSuccess = (res) => {
    console.log(res, "onCODexistSuccess: COD already exist");
    // let id = this.state.codId
    // let token = this.props.currentUser.authToken
    // auditServices
    //     .requestCOD(id, token)
    //     .catch(this.onRequestCodPdfSuccess)
    //     .then(this.onRequestCodPdfError)
  };

  onCODexistError = (res) => {
    console.log(res, "onCODexistError: COD already exist");
    let id = this.state.codId;
    let token = this.props.currentUser.authToken;
    auditServices
      .requestCOD(id, token)
      .catch(this.onRequestCodPdfSuccess)
      //Check if generatin PDF is done
      .then(this.onRequestCodPdfError);
  };

  closeToastify = () => {
    let url = this.state.codPdf;
    window.open(url);
    // window.location.href = url
  };

  handleModalHide = () => {
    this.setState({
      codModal: false,
    });
  };

  onHandleCheck = (id, checked) => {
    let auditId = parseInt(id);
    let approved = this.state.approved_list;
    let unapproved = this.state.unapproved_list;
    if (checked === true) {
      let newUnapproved = unapproved.filter((ids, i) => ids !== auditId);
      let newApproved = [...approved, auditId];
      this.updateCheckedState(newApproved, newUnapproved);
    } else {
      let newApproved = approved.filter((ids, i) => ids !== auditId);
      let newUnapproved = [...unapproved, auditId];
      this.updateCheckedState(newApproved, newUnapproved);
    }
  };

  updateCheckedState = (newApproved, newUnapproved) => {
    this.setState({
      approved_list: newApproved,
      unapproved_list: newUnapproved,
    });
  };

  onClickAll = (e) => {
    let response = this.state.auditRes;
    let data = response;
    let check = e.target.checked;

    let newApproved = [];
    let newUnapproved = [];
    if (check === true) {
      for (let i in data) {
        newApproved.push(data[i].id);
      }
    }
    if (check === false) {
      for (let i in data) {
        newUnapproved.push(data[i].id);
      }
    }
    this.setState({
      approved_list: newApproved,
      unapproved_list: newUnapproved,
    });
  };

  mapAuditCardAgain = () => {
    // let response = this.state.auditRes
    let data = this.state.auditRes;
    this.setState({
      mappedAudit: data.map(this.mapAudit),
    });
  };

  onHandleImg = (item) => {
    // create an image map
    const imgs = item.images.map((device_image) => {
      return {
        img: `${device_image?._image[0]?.device_image?.url}?tpl=large`,
        serial_number: item.serial_number,
        imei: item.imei,
        grade: item.grade,
      };
    });
    console.log(imgs, "imgs");
    // let id = parseInt(e)
    // let filterImgArray = data.filter((img, i) => img.id == `${id}`)
    this.setState({
      mappedImg: imgs.map((data, index) => this.mapImgages(data, index)),
      imgModal: true,
      carouselImg: imgs,
      carouselIndex: 0,
    });
  };

  handleImgHide = () => {
    this.setState({
      imgModal: false,
      carouselIndex: 0,
    });
  };

  mapImgages = (data, index) => (
    <AuditCarasoul data={data} active={this.state.carouselIndex} keyImg={index} />
  );

  onHandlePrev = () => {
    if (this.state.page_num > 1) {
      let prev = this.state.page_num - 1;
      // this.getAuditHardcoded(prev)
      this.setState(
        {
          page_num: prev,
        },
        () => {
          this.getAuditHardcoded(prev);
        }
      );
    }
  };

  onHandleNext = () => {
    if (this.state.page_num > 0) {
      let next = this.state.page_num + 1;
      // this.getAuditHardcoded(next)
      this.setState(
        {
          page_num: next,
          imgArray: [],
          mapImgages: [],
          mappedImg: [],
        },
        () => {
          this.getAuditHardcoded(next);
        }
      );
    }
  };

  handleFiltersModal = () => {
    this.setState({
      filtersModal: !this.state.filtersModal,
    });
  };

  onHandleFilters = (filters) => {
    this.setState(
      {
        page_num: 1,
        location_name: filters.location_name,
        tracking_reference: filters.tracking_reference,
        tracking_number: filters.tracking_number,
        start_date: filters.start_date,
        end_date: filters.end_date,
        grade: filters.grade,
        serial_number: filters.serial_number,
        device_type_id: filters.device_type_id,
        lock: filters.lock,
        filter: true,
      },
      () => {
        this.getAuditHardcoded();
      }
    );
  };

  handlePageChange = (selectedOption) => {
    this.setState(
      {
        per_page: selectedOption.value,
      },
      () => {
        this.getAuditHardcoded();
      }
    );
  };

  handleDownload = () => {
    let userSettings = DashboardStore.getUserSettings();

    let token = this.props.currentUser.authToken;
    let address_id = this.state.address_id;
    let program_id = userSettings.active_program_id;
    let page_num = this.state.page_num;
    let per_page = this.state.per_page;
    let tracking_reference = this.state.tracking_reference;
    let tracking_number = this.state.tracking_number;
    let start_date = this.state.start_date;
    let end_date = this.state.end_date;
    let grade = this.state.grade;
    let serial_number = this.state.serial_number;
    let device_type_id = this.state.device_type_id;
    let approved_view =  1;
    let download = true;
    let location_name = this.state.location_name
    auditServices
      .getAuditUpdates(
        token,
        program_id,
        page_num,
        per_page,
        tracking_reference,
        tracking_number,
        start_date,
        end_date,
        grade,
        serial_number,
        device_type_id,
        download,
        location_name,
        approved_view
      )
      .then(this.downloadAuditSuccess)
      .catch(this.downloadAuditError);
  };

  downloadAuditSuccess = (csv) => {
    // create a link from the csv
    let downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(new Blob(["\ufeff", csv]));
    downloadLink.download = "audit_data.csv";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // let userSettings = DashboardStore.getUserSettings();

    // let address_id = this.state.address_id;
    // let program_id = userSettings.active_program_id;
    // let page_num = this.state.page_num;
    // let per_page = this.state.per_page;
    // let tracking_reference = this.state.tracking_reference;
    // let tracking_number = this.state.tracking_number;
    // let start_date = this.state.start_date;
    // let end_date = this.state.end_date;
    // let grade = this.state.grade;
    // let serial_number = this.state.serial_number;
    // let device_type_id = this.state.device_type_id;
    // let download = true;
    // let approved_view =  1;
    // let token = this.props.currentUser.authToken;

    // window.location.href = `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/audit/program?token=${token}address_id=${address_id}&program_id=${program_id}&page_num=${page_num}&per_page=${per_page}&tracking_reference=${tracking_reference}&tracking_number=${tracking_number}&start_date=${start_date}&end_date=${end_date}&grade=${grade}&serial_number=${serial_number}&device_type_id=${device_type_id}&download=${download}&approved_view=${approved_view}`;
  };

  downloadAuditError = (e) => {
    console.log(e);
  };

  handleClearFilter = () => {
    this.setState(
      {
        page_num: 1,
        address_id: 0,
        program_id: 0,
        per_page: 15,
        sort_by: 0,
        tracking_reference: "",
        tracking_number: "",
        start_date: "",
        end_date: "",
        grade: "",
        serial_number: "",
        location_name: "",
        device_type_id: 0,
        filter: false,
      },
      () => {
        this.getAuditHardcoded();
      }
    );
  };

  onChangeSelectAudit = (e) => {
    let value = e.target.value;
    this.setState(
      {
        approved_view: value,
      },
      () => {
        this.getAuditHardcoded();
      }
    );
  };

  carouselPrevBtn = () => {
    let currentIndex = this.state.carouselIndex;
    let newIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
    if (currentIndex !== newIndex) {
      this.setState(
        {
          carouselIndex: newIndex,
        },
        () => this.onHandleNextImg()
      );
    }
  };

  carouselNextBtn = () => {
    let length = this.state.mappedImg.length;
    let currentIndex = this.state.carouselIndex;
    let newIndex = currentIndex < length - 1 ? currentIndex + 1 : currentIndex;
    if (currentIndex !== newIndex) {
      this.setState(
        {
          carouselIndex: newIndex,
        },
        () => this.onHandleNextImg()
      );
    }
  };

  onHandleNextImg = () => {
    let imgs = this.state.carouselImg;
    this.setState({
      mappedImg: imgs.map((data, index) => this.mapImgages(data, index)),
    });
  };

  handleBtnColumns = () => {
    this.setState({
      columnsModal: !this.state.columnsModal,
    });
  };

  handleColumnsModal = () => {
    this.setState({
      columnsModal: !this.state.columnsModal,
    });
  };

  onHandleToggle = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    switch (value) {
      case "true":
        this.setState((prevState) => {
          return {
            ...prevState,
            orgColumns: {
              ...prevState.orgColumns,
              [name]: false,
            },
          };
        });
        break;
      case "false":
        this.setState((prevState) => {
          return {
            ...prevState,
            orgColumns: {
              ...prevState.orgColumns,
              [name]: true,
            },
          };
        });
        break;
      default:
        break;
    }
  };

  onHandleUpdateColumns = () => {
    let token = this.props.currentUser.authToken;
    let columns = this.state.orgColumns;
    let payload = {
      updated_by: this.props.currentUser.id,
      user_id: this.props.currentUser.id,
      processed_date: columns.processed_date,
      request_date: columns.request_date,
      make: columns.make,
      model: columns.model,
      model_number: columns.model_number,
      memory_size: columns.memory_size,
      unique_id: columns.unique_id,
      imei: columns.imei,
      meid: columns.meid,
      serial_number: columns.serial_number,
      tracking_number: columns.tracking_number,
      grade: columns.grade,
      condition_notes: columns.condition_notes,
      fmi: columns.fmi,
      value: columns.value,
      battery_ratio: columns.battery_ratio,
      tracking_reference: columns.tracking_reference,
      reference: columns.reference,
      lock_status: columns.lock_status,
      po: columns.po,
      battery: columns.battery,
      glass_description: columns.glass_description,
      lcd_description: columns.lcd_description,
      housing_description: columns.housing_description,
      corner_description: columns.corner_description,

    }
    userServices
      .updateColumnVisibility(payload, token)
      .then(this.columnsModalSuccess)
      .catch(this.columnsModalError);
  };

  columnsModalSuccess = (res) =>{
    console.log(res, "columnsModalSuccess")
    this.setState({
      columnsModal: false
    })
    this.mapAuditCardAgain();
  }

  columnsModalError = (res) =>{
    console.log(res, "columnsModalError")
    this.setState({
      columnsModal: false
    })
    this.mapAuditCardAgain();
  }

  onBtnApprove = () => {
    let token = this.props.currentUser.authToken;
    let approved_list = [];
    let unapproved_list = [];

    let approved = this.state.approved_list;
    let unapproved = this.state.unapproved_list;

    for (let i in approved) {
      let obj = {};
      obj["id"] = approved[i];
      approved_list.push(obj);
    }
    for (let i in unapproved) {
      let obj = {};
      obj["id"] = unapproved[i];
      unapproved_list.push(obj);
    }
    let payload = {
      approved_list: approved_list,
      unapproved_list: unapproved_list,
    };
    auditServices
      .auditApprove(token, payload)
      .then(this.onAprroveSuccess)
      .catch(this.onAprroveError);
  };

  onAprroveSuccess = () => {
    toast.info("Processing Approval", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };

  onAprroveError = () => {
    toast.error("Error, please try again!", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };

  onHandlePageChange = (e) => {
    console.log(e.selected, "onHandlePageChange");
    let nextPage = e.selected + 1;
    // console.log(next, "next")
    this.setState(
      {
        page_num: nextPage,
        imgLogic: false,
      },
      () => {
        this.getAuditHardcoded();
      }
    );
  };

  handleDeleteFilter = (e) => {
    let name = e.target.value;
    let count = 0
    if(this.state.start_date.length > 0){count += 1 }
    if(this.state.end_date.length > 0){ count += 1}
    if(this.state.device_type_id.length > 0){ count += 1}
    if(this.state.grade.length > 0){ count += 1}
    if(this.state.location_name > 0){ count += 1}
    if(this.state.tracking_number.length > 0){ count += 1}
    if(this.state.tracking_reference.length > 0){ count += 1}
    if(this.state.lock.length > 0){ count += 1}
    console.log(count)
    if(count === 1) {
      this.setState({
        filter: false
      })
    }
    if (name === "shipping_status_id") {
      this.setState(
        {
          [name]: 0,
        },
        () => {
          this.getAuditHardcoded();
        }
      );
    } else if(name === "start_date"){
      this.setState(
        {
          start_date: "",
          end_date: ""
        },
        () => {
          this.getAuditHardcoded();
        }
      );
    }
      else {
      this.setState(
        {
          [name]: "",
        },
        () => {
          this.getAuditHardcoded();
        }
      );
    }
  };

  render() {
    const { carouselImg } = this.state;
    let indicators = carouselImg.map((item, i) => {
      return <li data-target="#carouselAuditIndicators" data-slide-to={i} className=""></li>;
    }, this);

    

    return (
      <React.Fragment>
        <Modal size="lg" show={this.state.imgModal} onHide={this.handleImgHide}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="transparent-inner-icon">
                <h2 className="icomoon _00 print-label">&#xE969;</h2>
                <h4>
                  IMEI/Serial:{" "}
                  {this.state.carouselImg.length > 0 ? this.state.carouselImg[0].serial_number : ""}{" "}
                  Grade {this.state.carouselImg.length > 0 ? this.state.carouselImg[0].grade : ""}{" "}
                </h4>
              </div>
            </Modal.Title>
          </Modal.Header>

          <ModalBody>
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
              <ol className="carousel-indicators">{indicators}</ol>
              <div className="carousel-inner">{this.state.mappedImg}</div>
              <button
                className="carousel-control-prev"
                type="button"
                value={1}
                onClick={this.carouselPrevBtn}
              >
                <span className="carousel-control-prev-icon" value={1} aria-hidden="true"></span>
              </button>
              <button className="carousel-control-next" type="button">
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                  value={1}
                  onClick={this.carouselNextBtn}
                ></span>
              </button>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          size="lg"
          show={this.state.codModal}
          onHide={this.handleModalHide}
          aria-labelledby="example-modal-sizes-title-lg"
          className="cod_container"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="transparent-inner-icon">
                <h2 className="icomoon _00 print-label">
                  <br />
                </h2>
                <h4>Certificate of Destruction</h4>
              </div>
            </Modal.Title>
          </Modal.Header>

          <Card style={{ width: "100%" }}>
            <Card.Body dangerouslySetInnerHTML={{ __html: this.state.codDiv }}></Card.Body>
          </Card>
          <Modal.Footer>
            <button class="div-block-10 green" onClick={this.handleDownloadCOD}>
              <h5 class="icomoon download"></h5>
              <p class="column-button-paragraph">Download PDF</p>
            </button>
          </Modal.Footer>
        </Modal>

        {/* Filters Modal */}
        <Modal
          size="lg"
          show={this.state.filtersModal}
          onHide={this.handleFiltersModal}
        // aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="transparent-inner-icon">
                <h2 className="icomoon _00 print-label">
                  <br />
                </h2>
                <h4>Report Filters</h4>
              </div>
            </Modal.Title>
          </Modal.Header>

          <AuditFilters
            start_date={this.state.start_date}
            end_date={this.state.end_date}
            location_name={this.state.location_name}
            grade={this.state.grade}
            device_type_id={this.state.device_type_id}
            lock={this.state.lock}
            tracking_number={this.state.tracking_number}
            tracking_reference={this.state.tracking_reference}
            serial_number={this.state.serial_number}
            className="audit-filters"
            userId={this.props.currentUser.id}
            onHandleFilters={this.onHandleFilters}
          />
        </Modal>
{/* Column Visibility Modal */}
        <Modal
          size="lg"
          show={this.state.columnsModal}
          onHide={this.handleColumnsModal}
        // aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="transparent-inner-icon">
                <h2 className="icomoon _00 print-label"></h2>
                <h4>Column Visibility</h4>
              </div>
            </Modal.Title>
          </Modal.Header>

          <AuditColumnsCard
            columns={this.state.orgColumns}
            onHandleToggle={this.onHandleToggle}
            onHandleUpdateColumns={this.onHandleUpdateColumns}
          />
        </Modal>
        <div id="audit_container" className="container-fluid moblyz_table">
          <h4>Audit Report: {this.state.activeProgram}</h4>
          <div>
            <div className="moblyz_table_filters">
              <div>
                <button className="div-block-10 green" onClick={this.handleFiltersModal}>
                  <h5 className="icomoon download"></h5>
                  <p className="column-button-paragraph">Filters</p>
                </button>
              </div>
            <div className="table_info">
              <div>
                <Select
                  placeholder="Sort By"
                  onChange={this.handleSortByChange}
                  options={this.state.sortOptions}
                />
              </div>
              <div>
                <button className="div-block-10 green" onClick={this.handleDownload}>
                  <h5 className="icomoon download">&#xEA23;</h5>
                  <p className="column-button-paragraph">Download</p>
                </button>
              </div>
              
              <div>
                  <p>Rows per page</p>
              </div>
              <div>
                    <Select
                      //value={this.state.address_id}
                      placeholder="15"
                      onChange={this.handlePageChange}
                      options={this.state.pageOptions}
                    // isSearchable={true}
                    />
                  </div>
              <div>
                <button className="div-block-10 green" onClick={this.handleBtnColumns}>
                  <h5 className="icomoon download">&#xEA2A;</h5>
                  <p className="column-button-paragraph">Columns</p>
                </button>
              </div>
              {(this.state.itemsTotal > 0) ?
              (<div>
                <p>
                  Showing records {this.state.intialCount} to {this.state.endCount} of{" "}
                  {this.state.itemsTotal}
                </p>
              </div>) : ""
              }
              <div>
                <PaginatedItems
                  pageCount={this.state.pageCount}
                  handlePageChange={this.onHandlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
          {this.state.filter === true ? (
            <div className="applied_filters">
              {this.state.filter === false ? (
                <p>No filter applied</p>
              ) : (
                <button className="btn btn-pill btn-light" onClick={this.handleClearFilter}>
                  Clear
                </button>
              )}
              {this.state.start_date.length > 0 ? (
                <div>
                  Requested between: {this.state.start_date} - {this.state.end_date}{" "}
                  <button
                    className="icomoon _00 close-icon"
                    value="start_date"
                    onClick={this.handleDeleteFilter}
                  >
                    &#xE954;
                  </button>
                </div>
              ) : (
                ""
              )}
              {this.state.location_name.length > 0 ? (
                <div>
                  Shipped from: {this.state.location_name}{" "}
                  <button
                    className="icomoon _00 close-icon"
                    value="location_name"
                    onClick={this.handleDeleteFilter}
                  >
                    &#xE954;
                  </button>
                </div>
              ) : (
                ""
              )}
              {this.state.device_type_id.length > 0 ? (
                <div>
                  Device Type: {this.state.device_type_id === 1 ? "Laptop" : this.state.device_type_id === 2 ? "Phone" : this.state.device_type_id === 3 ? "Desktop" : "Tablet"}{" "}
                  <button
                    className="icomoon _00 close-icon"
                    value="device_type_id"
                    onClick={this.handleDeleteFilter}
                  >
                    &#xE954;
                  </button>
                </div>
              ) : (
                ""
              )}
              {this.state.tracking_reference.length > 0 ? (
                <div>
                  Tracking Reference: {this.state.tracking_reference}{" "}
                  <button
                    className="icomoon _00 close-icon"
                    value="tracking_reference"
                    onClick={this.handleDeleteFilter}
                  >
                    &#xE954;
                  </button>
                </div>
              ) : (
                ""
                )}
                {this.state.serial_number.length > 0 ? (
                  <div>
                    Serial #: {this.state.serial_number}{" "}
                    <button
                      className="icomoon _00 close-icon"
                      value="serial_number"
                      onClick={this.handleDeleteFilter}
                    >
                      &#xE954;
                    </button>
                  </div>
                ) : (
                  ""
                  )}
                  {this.state.tracking_number.length > 0 ? (
                    <div>
                      Serial #: {this.state.tracking_number}{" "}
                      <button
                        className="icomoon _00 close-icon"
                        value="tracking_number"
                        onClick={this.handleDeleteFilter}
                      >
                        &#xE954;
                      </button>
                    </div>
                  ) : (
                ""
                )}
                {this.state.grade.length >  0 ? (
                  <div>
                    Grade: {this.state.grade}{" "}
                    <button
                      className="icomoon _00 close-icon"
                      value="grade"
                      onClick={this.handleDeleteFilter}
                    >
                      &#xE954;
                    </button>
                  </div>
                ) : (
                ""
                )}
                {this.state.lock?.length > 0 ? (
                  <div>
                    Lock: {this.state.lock}{" "}
                    <button
                      className="icomoon _00 close-icon"
                      value="lock"
                      onClick={this.handleDeleteFilter}
                    >
                      &#xE954;
                    </button>
                  </div>
                ) :
                ""
              }
            </div>
          ) : (
            ""
          )}

          <Card className="audit-card filter_table">
            <div id="audit">
              <Table responsive>
                <thead>
                  <tr>
                    {this.props.currentUser.role === "Super" ? <th>Files</th> : ""}
                    {/* <th>Created</th>
                                        <th>Updated</th> */}
                    {this.state.orgColumns.processed_date === true ? <th>Date Processed</th> : ""}
                    {this.state.orgColumns.request_date === true ? <th>Date Requested</th> : ""}
                    {this.state.orgColumns.make === true ? <th>Make</th> : ""}
                    {this.state.orgColumns.model === true ? <th>Model</th> : ""}
                    {this.state.orgColumns.model_number === true ? <th>Model Number</th> : ""}
                    {this.state.orgColumns.memory_size === true ? <th>Memory Size</th> : ""}
                    {this.state.orgColumns.unique_id === true ? <th>Unique Id</th> : ""}
                    {this.state.orgColumns.imei === true ? <th>IMEI</th> : ""}
                    {this.state.orgColumns.meid === true ? <th>MEID</th> : ""}
                    {this.state.orgColumns.serial_number === true ? <th>Serial #</th> : ""}
                    {this.state.orgColumns.tracking_number === true ? <th>Tracking Number</th> : ""}
                    {this.state.orgColumns.grade === true ? <th>Grade</th> : ""}
                    {this.state.orgColumns.condition_notes === true ? <th>Condition</th> : ""}
                    {this.state.orgColumns.fmi === true ? <th>FMI</th> : ""}
                    {this.state.orgColumns.value === true ? <th>Value</th> : ""}
                    {this.state.orgColumns.battery_ratio === true ? <th>Battery Ratio</th> : ""}
                    {this.state.orgColumns.tracking_reference === true ? (
                      <th>Tracking Reference</th>
                    ) : (
                      ""
                    )}
                    {this.state.orgColumns.reference === true ? <th>Reference</th> : ""}
                    {this.state.orgColumns.lock_status === true ? <th>Lock Status</th> : ""}
                    {this.state.orgColumns.po === true ? <th>PO</th> : ""}
                    {this.state.orgColumns.battery === true ? <th>Baterry</th> : ""}
                    {this.state.orgColumns.glass_description === true ? (
                      <th>Glass Description</th>
                    ) : (
                      ""
                    )}
                    {/* {this.state.orgColumns.lcd_description === true ? <th>LCD Description</th> : ""} */}
                    {this.state.orgColumns.housing_description === true ? (
                      <th>Housing Description</th>
                    ) : (
                      ""
                    )}
                    {this.state.orgColumns.corner_description === true ? (
                      <th>Corner Description</th>
                    ) : (
                      ""
                    )}
                    {this.state.orgColumns.first_name === true ? <th>First Name</th> : ""}
                    {this.state.orgColumns.last_name === true ? <th>Last Name</th> : ""}
                    {this.state.orgColumns.city === true ? <th>City</th> : ""}
                    {this.state.orgColumns.state === true ? <th>State</th> : ""}
                    {this.state.orgColumns.phone === true ? <th>Phone</th> : ""}
                    {this.state.orgColumns.email === true ? <th>Email</th> : ""}
                    <th style={{position : "sticky", right:"55px", background:"white"}}>Images</th>
                    <th style={{position : "sticky", right:"0px", background:"white"}}>COD</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {audit} */}
                  {this.state.mappedAudit}
                </tbody>
              </Table>
            </div>
            {this.props.currentUser.role === "Super" ? (
              <Row>
                <Col xs={6} md={3}>
                  <div>
                    <input className="mt-4 mb-4" type="checkbox" onClick={this.onClickAll} /> Check
                    All
                  </div>
                </Col>
                <Col xs={6} md={3}>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={this.onChangeSelectAudit}
                  >
                    <option value="0" selected onChange={this.onChangeSelectAudit}>
                      Show All
                    </option>
                    <option value="1" onChange={this.onChangeSelectAudit}>
                      Show Only Aprroved
                    </option>
                    <option value="2" onChange={this.onChangeSelectAudit}>
                      Show Only Unapproved
                    </option>
                  </Form.Select>
                </Col>

                <Col xs={6} md={3}>
                  <button className="primary-button" onClick={this.onBtnApprove}>
                    Approve
                  </button>
                </Col>
                <Col xs={6} md={3}></Col>
              </Row>
            ) : (
              ""
            )}
          </Card>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default Audit;
