import axios from "axios";
import * as helper from "./serviceHelper";
import * as env from "./env";


const endpoint = env.url


let createRequest = (payload, token) =>{
  const config = {
    data: payload,
    method: "POST",
    url: "https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/requests",
    withCredentials: false,
    crossdomain: true,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
}

let request = (payload, token) =>{
const config = {
  data: payload,
  method: "POST",
  url: "https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/requests",
  withCredentials: false,
  crossdomain: true,
  headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
};
return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
}

export { createRequest, request }