import React, { useMemo, useState, useContext, useLayoutEffect, useEffect } from "react";
import { useParams, Routes, Route } from "react-router-dom";
import "./services/serviceHelper";
import DashboardStore from "./flux/DashboardStore";
import Overview from "./components/shared-components/Overview";
import ForgotPassword from "./components/ForgotPassword.jsx";
import { ToastContainer } from "react-toastify";
import "./index.css";
import Login from "./components/Login.jsx";
import { AuthProvider, authContext } from "./AuthProvider";
import { RequireAuth, useAuth } from "./RequireAuth";
import CurrentValue from "./components/features/tools/CurrentValue";
import Environmental from "./components/features/environmental/Environmental";
import AdminDashboardV2 from "./components/dashboard/admin/AdminDashboardV2";
import StandardDashboard from "./components/dashboard/standard/StandardDashboard";
import SuperDashboard from "./components/dashboard/super/SuperDashboard";
import Tracking from "./components/features/tracking/Tracking";
import NotAuthorized from "./components/pages/NotAuthorized.jsx";
import Audit from "./components/features/audit/Audit";
import NoRoute from "./components/pages/NoRoute";
import * as userService from "./services/userServices";
import RedirectLogin from "./components/pages/RedirectLogin";
import Program from "./components/features/program/Program";
import { Inventory } from "./components/features/inventory/Inventory";
import { Vendors } from "./components/features/vendors/Vendors";
import VendorDetails from "./components/features/vendors/VendorDetails";
import { Support } from "./components/features/support/Support";

const Error404 = React.lazy(() => import("./components/pages/Error404.jsx"));
const Register = React.lazy(() => import("./components/Register.jsx"));
// const ForgotPassword = React.lazy(() => import("./components/ForgotPassword.jsx"));
const ResetPassword = React.lazy(() => import("./components/ResetPassword.jsx"));

// let navigate = useNavigate();


function AppRoutesV2() {
  const { user, setUser } = useContext(authContext);

  const onAuthSuccess = (res) =>{
    let currentUserLocalStorage = localStorage.getItem("currentUser");
    let userLocalStorage = JSON.parse(currentUserLocalStorage);
    DashboardStore.createTokentUser(userLocalStorage.authToken);
    DashboardStore.storeCurrentUser(userLocalStorage);
    setUser(userLocalStorage);
  }

  const onAuthError = () =>{
    localStorage.clear();
    window.location("/login")
  }

  useEffect(() =>{
    if (user === undefined && localStorage.length !== 0) {
      let currentUserLocalStorage = localStorage.getItem("currentUser");
      let userLocalStorage = JSON.parse(currentUserLocalStorage);
      userService
      .authorizeUser(userLocalStorage.authToken)
      .then(onAuthSuccess)
      .catch(onAuthError);
    }
  }, [])




  if (user !== undefined && user.role === "Admin") {
    return (
      <div>
        <Routes>
          {/* <Route
            path="login"
            element={
              <React.Suspense fallback={<>...</>}>
                <Login />
              </React.Suspense>
            }
          /> */}
        </Routes>
          <AdminDashboardV2 currentUser={user}>
            <Routes>
              <Route
                path="/"
                index
                element={
                  <React.Suspense fallback={<>...</>}>
                    <Login />
                  </React.Suspense>
                }
              />
              <Route path="/home" element={<RequireAuth><Overview currentUser={user} /></RequireAuth>} />
              <Route path="/tracking" element={<RequireAuth><Tracking currentUser={user} /></RequireAuth>} />
              <Route path="/audit" element={<RequireAuth><Audit currentUser={user} /></RequireAuth>} />
              <Route path="/tools" element={<RequireAuth><CurrentValue currentUser={user} /></RequireAuth>} />
              <Route path="/program" element={<RequireAuth><Program currentUser={user} /></RequireAuth>} />
              <Route path="/inventory" element={<RequireAuth><Inventory currentUser={user} /></RequireAuth>} />
              <Route path="/vendors" element={<RequireAuth><Vendors currentUser={user} /></RequireAuth>} />
              <Route path="/vendors/:id" element={<RequireAuth><VendorDetails currentUser={user} /></RequireAuth>} />
              <Route path="/settings" element={<RequireAuth><NotAuthorized /></RequireAuth>} />
              <Route path="/support" element={<RequireAuth><Support currentUser={user} /></RequireAuth>} />
              <Route path="/environmental" element={<RequireAuth><Environmental currentUser={user} /></RequireAuth>} />
              <Route path="/current-value" element={<RequireAuth><CurrentValue currentUser={user} /></RequireAuth>} />
              <Route path="/not-auth" element={<NotAuthorized />} />
              <Route path="*" element={<NoRoute />} />
            </Routes>
          </AdminDashboardV2>
      </div>
    );
  }
  if (user !== undefined && user.role === "Standard User") {
    return (
      <div>
        <Routes>
          {/* <Route
            path="login"
            element={
              <React.Suspense fallback={<>...</>}>
                <Login />
              </React.Suspense>
            }
          /> */}
        </Routes>
          <StandardDashboard currentUser={user}>
            <Routes>
              <Route
                path="/"
                index
                element={
                  <React.Suspense fallback={<>...</>}>
                    <Login />
                  </React.Suspense>
                }
              />
              <Route path="/home" element={<RequireAuth><Overview currentUser={user} /></RequireAuth>} />
              <Route path="/tracking" element={<RequireAuth><Tracking currentUser={user} /></RequireAuth>} />
              <Route path="/audit" element={<RequireAuth><Audit currentUser={user} /></RequireAuth>} />
              {/* <Route path="/tools" element={<RequireAuth><CurrentValue currentUser={user} /></RequireAuth>} /> */}
              {/* <Route path="/settings" element={<RequireAuth><NotAuthorized /></RequireAuth>} /> */}
              <Route path="/support" element={<RequireAuth><NotAuthorized /></RequireAuth>} />
              <Route path="/environmental" element={<RequireAuth><Environmental currentUser={user} /></RequireAuth>} />
              <Route path="/current-value" element={<RequireAuth><CurrentValue currentUser={user} /></RequireAuth>} />
              <Route path="/not-auth" element={<NotAuthorized />} />
              <Route path="*" element={<NoRoute />} />
            </Routes>
          </StandardDashboard>
      </div>
    );
  }
  if (user !== undefined && user.role === "Super") {
    return (
      <div>
        <Routes>
          {/* <Route
            path="login"
            element={
              <React.Suspense fallback={<>...</>}>
                <Login />
              </React.Suspense>
            }
          /> */}
        </Routes>
          <SuperDashboard currentUser={user}>
            <Routes>
              <Route
                path="/"
                index
                element={
                  <React.Suspense fallback={<>...</>}>
                    <Login />
                  </React.Suspense>
                }
              />
              <Route path="/home" element={<RequireAuth><Overview currentUser={user} /></RequireAuth>} />
              <Route path="/tracking" element={<RequireAuth><Tracking currentUser={user} /></RequireAuth>} />
              <Route path="/audit" element={<RequireAuth><Audit currentUser={user} /></RequireAuth>} />
              <Route path="/tools" element={<RequireAuth><CurrentValue currentUser={user} /></RequireAuth>} />
              <Route path="/settings" element={<RequireAuth><NotAuthorized /></RequireAuth>} />
              <Route path="/support" element={<RequireAuth><NotAuthorized /></RequireAuth>} />
              <Route path="/environmental" element={<RequireAuth><Environmental currentUser={user} /></RequireAuth>} />
              <Route path="/current-value" element={<RequireAuth><CurrentValue currentUser={user} /></RequireAuth>} />
              <Route path="/not-auth" element={<NotAuthorized />} />
              <Route path="*" element={<NoRoute />} />
            </Routes>
          </SuperDashboard>
      </div>
    );
  }
  // }, [])
  /*
Simple Content has  pages that are not required on the home page can be split out 
into separate bundles, thereby decreasing load time on the initial page and improving performance.
Also has path=* for NotFound
*/
  if (user === undefined) {
    return (
      <div>
        {/* <BrowserRouter> */}
        {/* <AuthProvider> */}
        <Routes>
          <Route
            path="/"
            index
            element={
              <React.Suspense fallback={<>...</>}>
                <Login />
              </React.Suspense>
            }
          />
          <Route
            path="login"
            element={
              <React.Suspense fallback={<>...</>}>
                <Login />
              </React.Suspense>
            }
          />
          <Route
            path="password"
            element={
              <React.Suspense fallback={<>...</>}>
                <ForgotPassword />
              </React.Suspense>
            }
          />
          {/* <Route
            path="register"
            element={
              <React.Suspense fallback={<>...</>}>
                <Register />
              </React.Suspense>
            }
          /> */}
          <Route
            path="/reset/:token"
            element={
              <React.Suspense fallback={<>...</>}>
                <ResetPassword />
              </React.Suspense>
            }
          />
          <Route path="*" element={<RedirectLogin />} />
        </Routes>
        {/* </AuthProvider> */}
        {/* </BrowserRouter> */}
      </div>
    );
  }

  {
    /* <Route path="*" element={<NotAuthorized />} /> */
  }

  // if(user.role === "Admin"){
  //   return(
  //     <div>
  //     <BrowserRouter>
  //     <Routes>
  //     <Route path="login"element={<React.Suspense fallback={<>...</>}><Login /></React.Suspense> }/>
  //     </Routes>

  //     <AdminDashboardV2 currentUser={user}>

  //       <Routes>
  //       <Route path="/" index element={<React.Suspense fallback={<>...</>}><Home /></React.Suspense> }/>
  //       <Route path="home" element={<Home currentUser={user}/>}/>
  //         <Route path="tracking" element={<Tracking currentUser={user}/>}/>
  //         <Route path="audit" lement={<Audit currentUser={user}/>}/>
  //         <Route path="tools" lement={<CurrentValue currentUser={user}/>}/>
  //         <Route path="settings" element={<NotAuthorized />}/>
  //         <Route path="support" element={<NotAuthorized />}/>
  //         <Route path="environmental" element={<Environmental currentUser={user}/>}/>
  //         <Route path="current-value" element={<CurrentValue currentUser={user}/>}/>
  //       </Routes>
  //       </AdminDashboardV2>

  //     </BrowserRouter>
  //     </div>
  //   )
  // }
}

export default AppRoutesV2;
