import React from "react";
import { Link } from "react-router-dom";

function RedirectLogin() {
  return (
    <React.Fragment>
      <div className="section light-purple full wf-section">
        <div className="position-absolute top-50 start-50 translate-middle">
          <div className="login-div white">
            <h2>Please go to Login if already member!</h2>
            <div>
              <Link to="/login">
                <input
                  type="submit"
                  value="Login"
                  data-wait="Please wait..."
                  className="primary-button full form-button w-button"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RedirectLogin;
