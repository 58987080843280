// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
    return [
        {
            title: "Home",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe913;</h5>',
            to: "/home",
        },
        {
            title: "Tracking",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe935;</h5>',
            to: "/tracking",
        },
        {
            title: "Audit",
            to: "/audit",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe909;</h5>',
            htmlAfter: ""
        },
        {
            title: "Tools",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe917;</h5>',
            to: "/tools",
        },
        {
            title: "Settings",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe92d;</h5>',
            to: "/settings",
        },
        {
            title: "DP App",
            // htmlBefore: '<i class="material-icons">vertical_split</i>',
            to: "/program-approval",
        },
        {
            title: "Tools",
            // htmlBefore: '<i class="material-icons">vertical_split</i>',
            to: "/current-value",
        },
        {
            title: "Support",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe930</h5>',
            to: "/support",
        }
    ];
}
