import * as React from "react";
import { Table, Card, Tabs, Tab, Modal, Col, Container, Row } from "react-bootstrap";
import ProgramTab from "./ProgramTab";
import * as trackingServices from "../../../services/trackingServices.js";
import TableCard from "./TableCard";
import * as organizationServices from "../../../services/organizationServices.js";
import * as programServices from "../../../services/programServices.js";
import DashboardStore from "../../../flux/DashboardStore";
import Details from "./Details";
import Select from "react-select";
import "./tracking.css";
import Filters from "./Filters";
import PaginatedItems from "../../shared-components/common/PaginatedItems";

// import GoogleMap from "./GoogleMap";

class Tracking extends React.Component {
  constructor(props) {
    super(props);
    this.programViewAs = this.programViewAs.bind(this);
    this.state = {
      userSettings: DashboardStore.getUserSettings(),
      currentUser: DashboardStore.getUserInfo(),
      activeProgram: "",
      colCount: 11,
      tracking: [],
      mapTracking: [],
      mapHeaders: [],
      headers: [],
      orgOptions: [],
      prgOptions: [],
      programChosen: false,
      detailsModal: false,
      details: [],
      program: "",
      page_num: 1,
      itemsTotal: 0,
      intialCount: 0,
      endCount: 0,
      viewAs: 0,
      sortOptions: [
        { value: 0, label: "Status Date (by Newest)" },
        { value: 1, label: "Status Date (by Oldest)" },
        { value: 2, label: "Request Date (by Newest)" },
        { value: 3, label: "Request Date (by Oldest)" },
      ],
      pageOptions: [
        { value: 15, label: 15 },
        { value: 25, label: 25 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
        { value: 200, label: 200 },
      ],
      filtersModal: false,
      start_date: "",
      end_date: "",
      shipping_status_id: 0,
      shipping_status: "",
      address_id: 0,
      tracking_reference: "",
      tracking_number: "",
      filter: false,
      per_page: 15,
      sort_by: "",
      orgColumns: {},
      trackingDetailsChosen: {},
      mappedPagination: [],
      pageCount: 0,
    };
  }
  /*
  If user does not have active_program_id they first need to create a program  
  */
  componentDidMount = () => {
    // console.log(this.props, this.state, "didMount")
    DashboardStore.on("CHANGEUSERSETTINGS", this.programViewAs);
    this.getOrgsColumns();

  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    // console.log(prevProps, prevState, snapshot, "update")

    if (this.state.viewAs === 0 && DashboardStore.getUserSettings() !== undefined && DashboardStore.getUserSettings().active_program_id !== 0) {
      this.programViewAs();
    }
  };

  componentWillUnmount = () => {
    DashboardStore.removeListener("CHANGEUSERSETTINGS", this.programViewAs);
  };

  setUserSettings = () => { };

  programViewAs = () => {
    let userSettings = DashboardStore.getUserSettings();
    let id = userSettings.active_program_id;
    this.setState(
      {
        viewAs: id,
        activeProgram: userSettings.active_program,
      },
      () => {
        this.getTrackingHardcoded(id);
      }
    );
  };

  getOrgsColumns = () => {
    let orgId = DashboardStore.getOrgView();
    organizationServices
      .columnVisibility(orgId)
      .then(this.onOrgColumnSuccess)
      .catch(this.onOrgColumnError);
  };

  onOrgColumnSuccess = (res) => {
    this.setState({
      orgColumns: res,
    });
  };

  onOrgColumnError = (res) => {
    console.log(res, "onOrgColumnError");
  };

  //Currently program_id is hardcoded with 1 fix xano data and then use programId
  getTrackingHardcoded = (programId) => {
    let adress_id = this.state.address_id;
    // let program_id = 1
    let program_id = DashboardStore.getUserSettings().active_program_id;
    let page_num = this.state.page_num;
    let per_page = this.state.per_page;
    let sort_by = this.state.sort_by;
    let start_date = this.state.start_date;
    let end_date = this.state.end_date;
    let shipping_status_id = this.state.shipping_status_id;
    let tracking_reference = this.state.tracking_reference;
    let tracking_number = this.state.tracking_number;

    trackingServices
      .getTracking(
        adress_id,
        program_id,
        page_num,
        per_page,
        sort_by,
        shipping_status_id,
        tracking_reference,
        tracking_number,
        start_date,
        end_date
      )
      .then(this.onGetTrackingSuccess)
      .catch(this.onGetTrackingError);
  };

  onGetTrackingSuccess = (e) => {
    console.log(e, "tracking success");
    let response = e.items;
    let itemsTotal = e.itemsTotal;
    let intialCount = e.curPage === 1 ? 1 : (((e.curPage - 1) * this.state.per_page) + 1);
    let endCount = e.curPage === 1 ? e.itemsReceived : (((e.curPage - 1) * (this.state.per_page)) + e.itemsReceived);
    // this.mappingPaginationItem(e.pageTotal)
    this.setState({
      pageCount: e.pageTotal,
      // colCount: colCount,
      tracking: response,
      filtersModal: false,
      itemsTotal: itemsTotal,
      intialCount: intialCount,
      endCount: endCount,
      // mappedPagination: this.mappingPaginationItem(e.pageTotal),
      mapTracking: response.map(this.mappedTracking),
    });
  };
  mappingPaginationItem = (pageTotal) => {
    // let active = this.state.page_num
    // let items = [];
    // for (let number = 1; number <= pageTotal; number++) {
    //     items.push(
    //         <Pagination.Item key={number} active={number === active ? true : false}>
    //             {number}
    //         </Pagination.Item>,
    //     );
    // }
    // return items;
  };

  onGetTrackingError = (e) => {
    console.log(e, "Error");
  };

  mappedTracking = (data) => (
    <TableCard key={data.id} tracking={data} handleDetails={this.handleDetails} />
  );

  //Hard-Code currently tracking_id is set to 10
  handleDetails = (tracking) => {
    // let id = tracking.id
    // hardcoding tracking id to 6
    this.setState({
      trackingDetailsChosen: tracking,
    });
    trackingServices
      .getTrackingDetails(tracking.id)
      .then(this.onGetDetailsSuccess)
      .catch(this.onGetDetailsError);
  };

  onGetDetailsSuccess = (response) => {
    this.setState({
      detailsModal: true,
      details: response,
    });
  };

  onGetDetailsError = (e) => {
    console.log(e, "Error");
  };

  handleChangeSelected = (e) => {
    // console.log(e, "selected")

    if (e != null && this.state.programChosen === false) {
      this.getProgramsOfOrg(e.value);
    }
    if (e === null) {
      // console.log(e)
      this.setState({
        mapTracking: [],
        prgOptions: [],
        programChosen: false,
        tracking: [],
        program: "",
      });
      this.getOrgs();
    }
    if (e != null && this.state.programChosen === true) {
      this.setState({
        program: e.label,
      });
      this.getTrackingHardcoded(e.value);
    }
  };

  getProgramsOfOrg = (orgId) => {
    let token = this.state.currentUser[0].store.authToken;
    programServices
      .getProgramsByOrg(1, token)
      .then(this.onGetProgramsSuccess)
      .catch(this.onGetProgramsError);
  };
  onGetProgramsSuccess = (response) => {
    // console.log(response, "Success")
    let prgOptions = [];
    for (let i = 0; i < response.length; i++) {
      let obj = {};
      obj["value"] = response[i].id;
      obj["label"] = response[i].program_name;
      prgOptions.push(obj);
    }
    this.setState({
      prgOptions: prgOptions,
      programChosen: true,
    });
  };

  onGetProgramsError = (e) => {
    console.log(e, "Error");
  };

  handleModalHide = () => {
    this.setState({
      detailsModal: false,
    });
  };

  handleFiltersModal = () => {
    this.setState({
      filtersModal: !this.state.filtersModal,
    });
  };

  onHandlePrev = () => {
    if (this.state.page_num > 1) {
      let prev = this.state.page_num - 1;
      // console.log(prev, "Prev")
      this.setState(
        {
          page_num: prev,
        },
        () => {
          this.getTrackingHardcoded();
        }
      );
    }
  };

  onHandleNext = () => {
    if (this.state.page_num > 0) {
      let next = this.state.page_num + 1;
      // console.log(next, "next")
      this.setState(
        {
          page_num: next,
        },
        () => {
          this.getTrackingHardcoded();
        }
      );
    }
  };

  handleSubmit = (filters) => {
    this.setState(
      {
        page_num: 1,
        start_date: filters.start_date,
        end_date: filters.end_date,
        shipping_status_id: filters.shipping_status_id,
        shipping_status: filters.shipping_status,
        address_id: filters.address_id,
        tracking_reference: filters.tracking_reference,
        tracking_number: filters.tracking_number,
        filter: true,
        filtersModal: false,
      },
      () => {
        this.getTrackingHardcoded();
      }
    );
  };

  handleSortByChange = (selectedOption) => {
    this.setState(
      {
        sort_by: selectedOption.value,
      },
      () => {
        this.getTrackingHardcoded();
      }
    );
  };

  handlePageChange = (selectedOption) => {
    this.setState(
      {
        per_page: selectedOption.value,
      },
      () => {
        this.getTrackingHardcoded();
      }
    );
  };

  handleClearFilter = () => {
    this.setState(
      {
        page_num: 1,
        start_date: "",
        end_date: "",
        shipping_status_id: 0,
        shipping_status: "",
        address_id: 0,
        tracking_reference: "",
        tracking_number: "",
        per_page: 15,
        filter: false,
      },
      () => {
        this.getTrackingHardcoded();
      }
    );
  };

  handleDeleteFilter = (e) => {
    let name = e.target.value;
    let count = 0
    if(this.state.start_date.length > 0){count += 1 }
    if(this.state.end_date.length > 0){ count += 1}
    if(this.state.shipping_status_id.length > 0){ count += 1}
    if(this.state.shipping_status.length > 0){ count += 1}
    if(this.state.address_id > 0){ count += 1}
    if(this.state.tracking_reference.length > 0){ count += 1}
    if(this.state.tracking_number.length > 0){ count += 1}
    console.log(count)
    if(count === 2) {
      this.setState({
        filter: false
      })
    }
    if (name === "shipping_status_id" || name === "address_id") {
      this.setState(
        {
          [name]: 0,
        },
        () => {
          this.getTrackingHardcoded();
        }
      );
    } else {
      this.setState(
        {
          [name]: "",
        },
        () => {
          this.getTrackingHardcoded();
        }
      );
    }
  };

  onHandlePageChange = (e) => {
    console.log(e.selected, "onHandlePageChange");
    let nextPage = e.selected + 1;
    // console.log(next, "next")
    this.setState(
      {
        page_num: nextPage,
      },
      () => {
        this.getTrackingHardcoded();
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        {/* Details Modal */}
        <Modal
          size="lg"
          show={this.state.detailsModal}
          onHide={this.handleModalHide}
          aria-labelledby="example-modal-sizes-title-lg"
          className="tracking_details"
        >
          <Modal.Header closeButton></Modal.Header>
          <Card style={{ width: "100%" }}>
            <Details
              details={this.state.details}
              programId={this.state.viewAs}
              info={this.state.trackingDetailsChosen}
            />
          </Card>
        </Modal>
        <h1>{this.state.program}</h1>

        {/* Filters Modal */}
        <Modal size="lg" show={this.state.filtersModal} onHide={this.handleFiltersModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="transparent-inner-icon">
                <h2 className="icomoon _00 print-label">
                  <br />
                </h2>
                <h4>Tracking Filters</h4>
              </div>
            </Modal.Title>
          </Modal.Header>

          <Filters
            currentUser={this.state.currentUser}
            handleSubmit={this.handleSubmit}
            start_date={this.state.start_date}
            end_date={this.state.end_date}
            shipping_status_id={this.state.shipping_status_id}
            shipping_status={this.state.shipping_status}
            address_id={this.state.address_id}
            tracking_reference={this.state.tracking_reference}
            tracking_number={this.state.tracking_number}
          />
        </Modal>
        <div id="tracking" className={this.props.mini !== "1" && "container-fluid moblyz_table"}>
          {this.props.mini !== "1" && (
            <div>
              <h4>Tracking Report: {this.state.activeProgram}</h4>
              <div className="moblyz_table_filters">
                <div>
                <button className="div-block-10 green" onClick={this.handleFiltersModal}>
                  <h5 className="icomoon download"></h5>
                  <p className="column-button-paragraph">Filters</p>
                </button>
                </div>
                <div className="table_info">
                  <div>
                    <Select
                      placeholder="Sort By"
                      onChange={this.handleSortByChange}
                      options={this.state.sortOptions}
                    />
                  </div>
                  <div>
                    <p>Rows per page</p>
                  </div>
                  <div>
                    <Select
                      placeholder="15"
                      onChange={this.handlePageChange}
                      options={this.state.pageOptions}
                    />
                  </div>
                  {(this.state.itemsTotal > 0) ?
                  (<div>
                    <p>
                      Showing records {this.state.intialCount} to {this.state.endCount} of {this.state.itemsTotal}
                    </p>
                  </div>) : ""
                  }
                  <div>
                    {/* {this.state.pageCount === 1 || this.state.pageCount === 0 ? "" : */}
                    <PaginatedItems
                      pageCount={this.state.pageCount}
                      handlePageChange={this.onHandlePageChange}
                    />
                    {/* } */}
                  </div>
                </div>
              </div>
              {this.state.filter === true ? (
                <div className="applied_filters">
                  <button className="btn btn-pill btn-light" onClick={this.handleClearFilter}>
                    Clear
                  </button>
                  {this.state.start_date.length > 0 ? (
                    <div>
                      Requested between: {this.state.start_date} - {this.state.end_date}{" "}
                      <button
                        className="icomoon _00 close-icon"
                        value="start_date"
                        onClick={this.handleDeleteFilter}
                      >
                        &#xE954;
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.address_id > 0 ? (
                    <div>
                      <strong>Shipped from:</strong> {this.state.address_id}{" "}
                      <button
                        className="icomoon _00 close-icon"
                        value="address_id"
                        onClick={this.handleDeleteFilter}
                      >
                        &#xE954;
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.shipping_status_id > 0 ? (
                    <div>
                      <strong>Shipping Status:</strong> {this.state.shipping_status}{" "}
                      <button
                        className="icomoon _00 close-icon"
                        value="shipping_status_id"
                        onClick={this.handleDeleteFilter}
                      >
                        &#xE954;
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.tracking_reference.length > 0 ? (
                    <div>
                      <strong>Tracking Reference:</strong> {this.state.tracking_reference}{" "}
                      <button
                        className="icomoon _00 close-icon"
                        value="tracking_reference"
                        onClick={this.handleDeleteFilter}
                      >
                        &#xE954;
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.tracking_number.length > 0 ? (
                    <div>
                      <strong>Tracking Number:</strong> {this.state.tracking_number}{" "}
                      <button
                        className="icomoon _00 close-icon"
                        value="tracking_number"
                        onClick={this.handleDeleteFilter}
                      >
                        &#xE954;
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          <Row className="filter_table">
            <Col>
              <Card>
                <div>
                  <Table responsive>
                    <thead>
                      <tr>
                        {/* {trackingHeader} */}
                        <th>Status</th>
                        <th>Status Date</th>
                        <th>Request Date</th>
                        <th>Program</th>
                        <th>Return Tracking #</th>
                        <th>Outbound Tracking #</th>
                        <th>Tracking Reference</th>
                        <th>Model</th>
                        <th>Package</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Company</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Address</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Zip</th>
                        <th style={{position : "sticky", right:"0px", background:"white"}}>Details</th>
                        {this.state.mapHeaders}
                      </tr>
                    </thead>
                    <tbody>{this.state.mapTracking}</tbody>
                  </Table>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default Tracking;
