import React, { Component } from 'react'
import { Container, Card, CardBody, CardFooter, ModalFooter } from "shards-react";
import { Modal } from "react-bootstrap"
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap"
import { Formik, Field } from "formik"
import * as yup from 'yup';
import "./pickup.css"
import AddedDevice from './AddedDevice';
import * as requestServices from "../../../services/requestServices.js"
import * as userServices from "../../../services/userServices.js"
import DashboardStore from '../../../flux/DashboardStore';
import * as DashboardActions from "../../../flux/DashboardActions.js"
import swal from "sweetalert";
import { Address } from '../../shared-components/common/Address';


const schema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),

});

class PrintLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                address1: "",
                address2: "",
                city: "",
                state: "",
                postal_code: "",
                country: "",
                nickname: "",
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                reference: "Print Label Test",
            },
            labelLink: "",
            location_name: "",
            printModal: false,
            device: "",
            qty: "",
            type: "",
            devices_array: [],
            currentId: 0,
            mappedDevices: [],
            saveLocationAlert: true,
            options: [],
            addressesList: DashboardStore.getAddresses(),
            deviceOptions: [
                { id: "0", value: "0", name: "Select type" },
                { id: "DO1", value: "Phone", name: "Phone" },
                { id: "DO2", value: "Tablet", name: "Tablet" },
                { id: "DO3", value: "Laptop", name: "Laptop" },
                { id: "DO4", value: "Desktop", name: "Desktop" },
                { id: "DO5", value: "Accessory Boxes", name: "Accessory Boxes" },

            ],
            open_box: false
        }
    }

    componentDidMount = () => {
        // console.log(this.props.location, this.state, "didMount")
        DashboardStore.on("CHANGE", this.getAddresses)
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        // console.log(prevProps, prevState, snapshot, this.props, "update")
        if (this.state.addressesList.length === 0 && prevProps.currentUser !== this.props.currentUser && DashboardStore.getAddresses().length === 0) {
            DashboardActions.apiCallGetAddresses(this.props.currentUser.id)
        }
    }

    componentWillUnmount = () => {
        // console.log("WillUnMount", this.props, this.state)
        DashboardStore.off("CHANGE", this.getAddresses)

    }

    getAddresses = () => {
        this.setState({
            addressesList: DashboardStore.getAddresses()
        })
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === "address1") {
            let ifAddressExist = this.state.addressesList.filter(data => data.address1 === value)
            if (ifAddressExist.length === 1) {
                this.setState({
                    saveLocationAlert: false
                })
            } else {
                this.setState({
                    saveLocationAlert: true
                })
            }
        }

        this.setState((prevState) => {
            return {
                ...prevState,
                formData: {
                    ...prevState.formData,
                    [name]: value,
                },
            };
        });
    };

    addressChosen = (e) => {
        let id = parseInt(e.target.value)
        let chosenAddress = this.state.addressesList.filter(data => data.id === id)
        let obj = chosenAddress[0]

        this.setState((prevState) => {
            return {
                ...prevState,
                verifyAddress: true,
                formData: {
                    first_name: this.state.formData.first_name,
                    last_name: this.state.formData.last_name,
                    phone: this.state.formData.phone,
                    email: this.state.formData.email,
                    address1: obj.address1,
                    address2: obj.address2,
                    city: obj.city,
                    state: obj.state,
                    postal_code: obj.postal_code,
                    country: obj.country,
                    nickname: this.state.formData.nickname,
                },
                saveLocationAlert: false
            };
        });
    }

    getDeviceOptions = () => {
        this.setState({
            deviceOptions: [
                { id: "0", value: "0", name: "Select type" },
                { id: "DO1", value: "Phone", name: "Phone" },
                { id: "DO2", value: "Tablet", name: "Tablet" },
                { id: "DO3", value: "Laptop", name: "Laptop" },
                { id: "DO4", value: "Desktop", name: "Desktop" },
                { id: "DO5", value: "Accessory Boxes", name: "Accessory Boxes" },

            ],
            options: [],
            qty: ""
        })
    }

    getTabletType = () => {
        this.setState({
            options: [
                { id: "0", value: "0", name: "Size/Model" },
                { id: "T1", value: "Regular", name: "Regular" },
                { id: "T2", value: "Large", name: "Large" }
            ]

        })
    }

    getLaptopType = () => {
        this.setState({
            options: [
                { id: "0", name: "Size/Model" },
                { id: "L0", name: '11" Laptop' },
                { id: "L1", name: '13" Laptop' },
                { id: "L2", name: '15" Laptop' },
            ]

        })
    }

    getDesktopType = () => {
        this.setState({
            options: [
                { id: "0", name: "Size/Model" },
                { id: "D1", name: '21" iMac' },
                { id: "D2", name: '24" iMac' },
                { id: "D3", name: '27" iMac' },
                { id: "D4", name: 'Mac Mini' }
            ]

        })
    }

    getPhoneType = () => {
        this.setState({
            options: [
                { id: "0", name: "Size/Model" },
                { id: "P1", name: "Standard" },
                { id: "P2", name: "Extra Large" }
            ],
            //type: "Default"

        })
    }

    getDisplayType = () => {
        this.setState({
            options: [
                { id: "0", name: "Size/Model" },
                { id: "Dis1", name: '27"' },
                { id: "Dis2", name: 'Less than 27"' }
            ]

        })
    }

    getOtherType = () => {
        this.setState({
            options: [
                { id: "0", name: "Choose items..." },
                { id: "Other1", name: 'ULine 24x20x16' },
                { id: "Other2", name: 'ULine 24x20x12' },
                { id: "Other3", name: 'ULine 18x18x18' }

            ]

        })
    }

    deviceChosen = (e) => {
        let value = e.target.value;

        if (value === "Tablet") {
            this.getTabletType()
        } else if (value === "Laptop") {
            this.getLaptopType()
        } else if (value === "Desktop") {
            this.getDesktopType()
        } else if (value === "Phone") {
            this.getPhoneType()
        } else if (value === "Display") {
            this.getDisplayType()
        } else if (value === "Accessory Boxes") {
            this.getOtherType()
        } else if (value === "Select type") {
            this.getDeviceOptions()
        }

        this.setState({
            device: value
        })
    }

    handleType = (e) => {

        let value = e.target.value;
        this.setState({
            type: value
        })
    }

    handleQty = (e) => {
        let value = e.target.value;

        this.setState({
            qty: value,
            //btnEnable: false
        })
    }

    addDevice = (e) => {
        let id = this.state.currentId + 1

        let devices = {
            id: id,
            device: this.state.device,
            type: this.state.type,
            qty: parseInt(this.state.qty)
        }

        let newArray = [...this.state.devices_array, devices]
        this.getAddedDevices(newArray)
        this.setState({
            devices_array: newArray,
            device: "",
            options: [],
            type: "",
            qty: "",
            currentId: id,
            deviceOptions: [
                { id: "0", value: "0", name: "Select type" },
                { id: "DO1", value: "Phone", name: "Phone" },
                { id: "DO2", value: "Tablet", name: "Tablet" },
                { id: "DO3", value: "Laptop", name: "Laptop" },
                { id: "DO4", value: "Desktop", name: "Desktop" },
                { id: "DO5", value: "Accessory Boxes", name: "Accessory Boxes" },

            ]
        })
        document.getElementById('selectDevice').selectedIndex = 0;
        document.getElementById('qtybox').value = 0;
    }

    getAddedDevices = (devicesArray) => {
        let data = devicesArray;
        this.setState({
            mappedDevices: data.map(this.mapDevice)
        })
    }

    mapDevice = data => (
        <AddedDevice
            mapped={this.state.mappedDevices}
            key={data.id}
            devices={data}
            onHandleDelete={this.onHandleDelete}
        />
    );

    onHandleDelete = (e) => {
        let id = e.devices.id;
        let currentDeviceCards = this.state.mappedDevices
        let currentDevices = this.state.devices_array

        let newDeviceCards = currentDeviceCards.filter(data => data.props.devices.id != id)
        let newDevices = currentDevices.filter(data => data.id != id)

        this.setState({
            mappedDevices: newDeviceCards,
            devices_array: newDevices
        })
    }

    handlePickupModal = () => {
        this.setState({
            printModal: !this.state.printModal,
            // formData: {},
            verifyAddress: false,
            // devices_array: [],
            // mappedDevices: [],
        })
    }


    onSubmitRequest = (e) => {
        let programId = DashboardStore.getUserSettings().active_program_id
        let payload = {
            program_id: programId,
            reference: this.state.formData.reference,
            request_type_id: 1,
            address: {
                location_name: this.state.location_name,
                address1: this.state.formData.address1,
                address2: this.state.formData.address2,
                city: this.state.formData.city,
                state: this.state.formData.state,
                postal_code: this.state.formData.postal_code,
                country: this.state.formData.country 
            },
            contact_list: [
                {
                    first_name: this.state.formData.first_name,
                    last_name: this.state.formData.last_name,
                    email: this.state.formData.email,
                    phone: this.state.formData.phone,
                }
            ],
            device_list: this.state.devices_array,
            request_id: 0
        }
        requestServices
            .request(payload)
            .then(this.onRequestSuccess)
            .catch(this.onRequestError)
        e.preventDefault()
    }

    onRequestSuccess = (e) => {
        let b64 = e.label;

        let link = 'data:application/pdf;base64,' + b64
        // window.open(link);
        this.setState({
            labelLink: link,
            formData: {},
            mappedDevices: [],
            devicesArray: [],
            verifyAddress: false,
        })
    }

    onRequestError = (e) => {
        swal({
            title: "Please try again!",
            // text: err,
            icon: "error",
            buttons: true,
        })
    }

    onHandleLoqate = (e) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                formData: {
                    ...prevState.formData,
                    address1: e.Line1,
                    address2: e.Line2,
                    city: e.City,
                    state: e.ProvinceCode,
                    postal_code: e.PostalCode,
                    country: e.CountryIso2,
                    first_name: this.state.formData.first_name,
                    last_name: this.state.formData.last_name,
                    phone: this.state.formData.phone,
                    reference: this.state.formData.reference,
                    email: this.state.formData.email,
                    nickname: this.state.formData.nickname,

                },
                verifyAddress: true
            };
        });
    };

    onSaveLocation = () => {
        let payload = {
            address1: this.state.formData.address1,
            address2: this.state.formData.address2,
            city: this.state.formData.city,
            state: this.state.formData.state,
            postal_code: this.state.formData.postal_code,
            country: this.state.formData.country,
            location_name: this.state.formData.location_name,
            created_by: this.props.currentUser.id,
            organization_id: this.props.currentUser.organization_id
        }
        userServices
            .createAddress(payload)
            .then(this.onAddressSaveSuccess)
            .catch(this.onAddressSaveError)
    }

    onAddressSaveSuccess = (e) => {
        this.setState({
            saveLocationAlert: false
        })
    }

    onAddressSaveError = (e) => {
        console.log(e, "Error")
    }

    toggleLocationBox = () => {
        this.setState({
            open_box: !this.state.open_box
        })
    }
    render() {
        const { deviceOptions } = this.state;

        let deviceOptionList = deviceOptions.length > 0 && deviceOptions.map((item, i) => {
            return (

                <option key={i} value={item.name}>{item.name}</option>
            )
        }, this);

        const { addressesList } = this.state;

        let addressOptionList = addressesList.length >= 0 && addressesList.map((item, i) => {
            return (

                <option key={i} value={item.id}>{item.location_name}</option>
            )
        }, this);

        const { options } = this.state;

        let optionsList = options.length > 0 && options.map((item, i) => {
            return (
                <option key={i} value={item.name}>{item.name}</option>
            )
        }, this);
        return (
            <React.Fragment>
                <button className="quick-links-inner-div" onClick={this.handlePickupModal}>
                    <div className="div-block-5">
                        <div className="transparent-inner-icon">
                            <h4 className="icomoon _00">&#xe91c;</h4>
                        </div>
                        <div className="div-block-6">
                            <p className="paragraph _00 bold down-6">Download Shipping Label</p>
                            <p className="paragraph _00 left _14">Print an instant pre-paid label to ship devices with your own packaging.</p>
                        </div>
                    </div>
                    <div className="upper-div"></div>
                </button>
                <Modal
                    size="lg"
                    show={this.state.printModal}
                    onHide={this.handlePickupModal}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="transparent-inner-icon">
                                <h2 className="icomoon _00 print-label">&#xe91c;</h2>
                                <h4>Download Label Request</h4>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-heading-div flex form">
                            <div className="form-flex">
                                <h5 className="form-heading">What are you sending us?<br /></h5>
                            </div>
                        </div>
                        <p>Please list out the device type, its size and quantity.</p>
                        <form className="w-layout-grid input-fields-grid answer-spacing _1">
                            <div className="div-block-7 up-24">
                                <label for="location-3">Device Type</label>
                                <select className="select w-select" placeholder="Select Device Type" id="selectDevice" onChange={this.deviceChosen} required>
                                    {deviceOptionList}
                                </select>
                            </div>
                            <div>
                                <label for="location-3">Size Type</label>
                                <select className="select w-select" placeholder="" id="selectType" onChange={this.handleType} required>
                                    {optionsList}
                                </select>
                            </div>
                            <div>
                                <label for="Manufacturer-Name-3">Quantity</label>
                                {(this.state.device === "Choose...") ?
                                    <input className="input-field w-input" type="number" placeholder="" id="qtybox" onChange={this.handleQty} required></input> :
                                    <input className="input-field w-input" type="number" placeholder="" id="qtybox" onChange={this.handleQty} required></input>
                                }
                            </div>
                            <button id="btn_add" className="secondary-button save-form _2 w-button" data-verify="Submit" value="Submit" type="button" onClick={this.addDevice}>Add</button>
                        </form>
                        <table className="mapped_devices">
                            <th>Device Type</th><th>Size</th><th className="quantity">Quantity</th><th></th>
                            {this.state.mappedDevices}
                        </table>
                        <Formik
                            enableReinitialize={true}
                            validationSchema={schema}
                            initialValues={this.state.formData}
                            onSubmit={this.onSubmitRequest}
                        >
                            {props => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleSubmit,
                                    onSubmitRequest,
                                    isValid,
                                    isSubmitting,
                                    isInvalid
                                } = props;
                                return (
                                    <Form onSubmit={handleSubmit}>
                                        <div id="wf-form-Print-Shipping-Label----Form" name="wf-form-Print-Shipping-Label----Form" data-name="Print Shipping Label -- Form" className="form registration _00" aria-label="Print Shipping Label -- Form">
                                            <div className="form-heading-div flex form">
                                                <div className="form-flex">
                                                    <h5 className="form-heading">Ship-from address below (Required)<br /></h5>
                                                </div>
                                            </div>
                                            <select
                                                placeholder="Saved Locations"
                                                className="select w-select"
                                                onChange={this.addressChosen}
                                            >
                                                <option key="0" value="0">Saved Locations</option>
                                                {addressOptionList}
                                            </select>
                                            <div className="w-layout-grid input-fields-grid">
                                                <div>
                                                    <label for="first_name">First Name</label>
                                                    <Field
                                                        type="text"
                                                        className="input-field w-input"
                                                        maxLength={256}
                                                        name="first_name"
                                                        onChange={this.handleChange}
                                                        values={this.state.formData.first_name}
                                                        placeholder="First Name"
                                                        required
                                                    />
                                                </div>
                                                <div>
                                                    <label for="last_name">Last Name</label>
                                                    <Field
                                                        type="text"
                                                        className="input-field w-input"
                                                        maxLength={256}
                                                        name="last_name"
                                                        onChange={this.handleChange}
                                                        values={this.state.formData.last_name}
                                                        placeholder="Last Name"
                                                        required
                                                    />
                                                </div>
                                                <div>
                                                    <label for="email">Email</label>
                                                    <Field
                                                        type="email"
                                                        className="input-field w-input"
                                                        maxLength={256}
                                                        name="email"
                                                        onChange={this.handleChange}
                                                        values={this.state.formData.email}
                                                        placeholder="name@company.com"
                                                        required
                                                    />
                                                </div>
                                                <div>
                                                    <label for="phone">Phone</label>
                                                    <Field
                                                        type="tel"
                                                        className="input-field w-input"
                                                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                                        name="phone"
                                                        onChange={this.handleChange}
                                                        values={this.state.formData.phone}
                                                        placeholder="### - ### - ####"
                                                        required
                                                    />
                                                </div>
                                                <div>
                                                    <label for="Last-Name-3"><strong>Address</strong></label>
                                                    {this.state.verifyAddress === false ?
                                                        <Address
                                                            className="loqate-dropdowns"
                                                            value={this.state.formData.address1}
                                                            onHandleLoqate={this.onHandleLoqate}
                                                        />
                                                        :
                                                        <Field
                                                            type="text"
                                                            name="address1"
                                                            onChange={this.handleChange}
                                                            values={this.state.formData.address1}
                                                            placeholder="Address"
                                                            required
                                                            className="input-field w-input"
                                                        />
                                                    }
                                                </div>
                                                <div>
                                                    <label for="address2">Suite Number</label>
                                                    <Field
                                                        type="text"
                                                        name="address2"
                                                        onChange={this.handleChange}
                                                        values={this.state.formData.address2}
                                                        placeholder="Suite/Unit"
                                                        className="input-field w-input"
                                                    />
                                                </div>
                                                <div>
                                                    <label for="city"><strong>City</strong><br /></label>
                                                    <Field
                                                        name="city"
                                                        onChange={this.handleChange}
                                                        values={this.state.formData.city}
                                                        placeholder="City"
                                                        required
                                                        className="input-field w-input"
                                                    />
                                                </div>
                                                <div>
                                                    <label for="state"><strong>State/Province</strong><br /></label>
                                                    <Field
                                                        name="state"
                                                        onChange={this.handleChange}
                                                        pattern="[A-Za-z]{2}"
                                                        values={this.state.formData.state}
                                                        placeholder="State/Province"
                                                        required
                                                        className="input-field w-input"
                                                    />
                                                </div>
                                                <div>
                                                    <label for="postal_code"><strong>Postal Code</strong><br /></label>
                                                    <Field
                                                        name="postal_code"
                                                        onChange={this.handleChange}
                                                        values={this.state.formData.postal_code}
                                                        placeholder="State/Province"
                                                        required
                                                        className="input-field w-input"
                                                    />
                                                </div>
                                                <div>
                                                    <label for="country" className="field-label">Country *</label>
                                                    <Field
                                                        name="country"
                                                        onChange={this.handleChange}
                                                        values={this.state.formData.country}
                                                        placeholder="Country"
                                                        pattern="[A-Za-z]{2}"
                                                        required
                                                        className="input-field w-input"
                                                    />
                                                </div>
                                            </div>
                                            <div className={(this.state.open_box ? "open " : "") + "faq-element box"}>
                                                <div className="faq-question box" onClick={this.toggleLocationBox}>
                                                    <div className="faq-text">Save to "Saved locations" (Optional)<br /></div>
                                                    <div className="plus-div">
                                                        <div className="plus-horizontal"></div>
                                                        <div className="plus-vertical"></div>
                                                    </div>
                                                </div>
                                                <div className="faq-answer box bg-l-purple">
                                                    <div className="form-flex up-24">
                                                        <div className="div-block-7">
                                                            <label for="Save-Location">Location Nickname</label>
                                                            <Field
                                                                className="input-field w-input"
                                                                type="text"
                                                                name="location_name"
                                                                values={this.state.formData.location_name}
                                                                placeholder="Give this location a name"
                                                                id="save_location_input"
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                        <button className="secondary-button save-form w-button" onClick={this.onSaveLocation}>Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                                type="submit"
                                                id="submit"
                                                name="submit"
                                                disabled={!isValid || isSubmitting}
                                                value="Get Label"
                                                data-wait="Please wait..."
                                                className="primary-button full form-button w-button"
                                            >
                                                Donwload Label
                                            </button>
                                        </div>
                                    </Form>
                                )
                            }
                            }
                        </Formik>
                    </Modal.Body>
                    <ModalFooter>
                        {this.state.labelLink.length > 0 ?
                            <h3>Download <a download href={this.state.labelLink}>Label</a>.</h3>
                            :
                            <div></div>
                        }
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}

export default PrintLabel
