import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { InputGroup, DatePicker, InputGroupAddon, InputGroupText } from "shards-react";
import * as addressServices from "../../../services/addressServices.js";
import Select from "react-select";
import { Formik, Field } from "formik";
import "./tracking.css";
import moment from "moment";

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        tracking_reference: "",
        tracking_number: "",
      },
      options: [],
      statusOptions: [
        { value: 1, label: "Request Received" },
        { value: 2, label: "Packaging in Transit" },
        { value: 3, label: "Packagining Delivered" },
        { value: 4, label: "Return in Transit" },
        { value: 5, label: "Return Delivered" },
        { value: 6, label: "Return Request Received" },
        { value: 8, label: "Processed" },
      ],
      address_id: 0,
      shipping_status_id: 0,
      shipping_status: "",
      start_date: "",
      end_date: "",
      checkProps: false,
    };
  }

  componentDidMount = () => {
    // console.log(this.props, this.state, "didMount")
    this.getSavedLocation();
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    // console.log(prevProps, prevState, snapshot, "update")
    if (this.state.checkProps === false) {
      if (
        this.props.tracking_number !== "" ||
        (this.props.tracking_reference !== "" && this.state)
      ) {
        this.setTrackingPropsStrings();
      }
      if (
        this.props.address_id > 0 ||
        this.props.shipping_status_id > 0 ||
        this.props.start_date !== "" ||
        this.props.end_date !== ""
      ) {
        this.setTrackingProps();
      }
    }
  };

  setTrackingPropsStrings = () => {
    this.setState({
      checkProps: true,
      formData: {
        tracking_reference: this.props.tracking_reference,
        tracking_number: this.props.tracking_number,
      },
    });
  };

  setTrackingProps = () => {
    this.setState({
      address_id: this.props.address_id,
      shipping_status_id: this.props.shipping_status_id,
      shipping_status: this.props.shipping_status,
      // start_date: this.props.start_date,
      // end_date: this.props.end_date,
      checkProps: true,
    });
  };

  getSavedLocation = () => {
    let id = this.props.currentUser[0].store.id;
    addressServices.saveLocations(id).then(this.onLocationSuccess).catch(this.onLocationError);
  };

  onLocationSuccess = (response) => {
    let data = response.addresses;
    let mappedAddress = data.map((item, index) => {
      return <option value={item.id}>{item.location_name}</option>;
    });
    this.setState({
      options: mappedAddress,
    });
  };

  onLocationError = (e) => {
    console.log(e);
  };

  handleStartDateChange = (value) => {
      console.log(value, "Start")
    this.setState({
      start_date: value,
    });
  };

  handleEndDateChange = (value) => {
    this.setState({
      end_date: value,
    });
  };

  handleLocationChange = (selectedOption) => {
    this.setState({ address_id: selectedOption.target.value });
  };

  handleStatusChange = (selectedOption) => {
    this.setState(
      { shipping_status_id: selectedOption.target.value,
        shipping_status: selectedOption.target.options[selectedOption.target.selectedIndex].text
     });
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => {
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      };
    });
  };

  handleSubmit = () => {
    const filters = {
      start_date: this.state.start_date !== "" ? moment(this.state.start_date).format("l") : "",
      end_date: this.state.end_date !== "" ? moment(this.state.end_date).format("l") : "",
      shipping_status_id: this.state.shipping_status_id,
      shipping_status: this.state.shipping_status,
      address_id: this.state.address_id,
      tracking_reference: this.state.formData.tracking_reference,
      tracking_number: this.state.formData.tracking_number,
    };
    this.props.handleSubmit(filters);
  };

  render() {
    return (
      <React.Fragment>
        <div className="modal-body">
          <div className="w-layout-grid input-fields-grid">
            <div className="div-block-22">
              <label for="Start-Date" className="">
                Requested within Start Date
              </label>
              <DatePicker
                selected={this.state.start_date}
                onChange={this.handleStartDateChange}
                placeholderText={
                  this.props.start_date.length !== 0 ? `${this.props.start_date}` : "mm/dd/yyyy"
                }
                dropdownMode="select"
                className="input-field inline-label w-input"
              />
            </div>
            <div className="div-block-23">
              <label for="End-Date" className="">
                End Date
              </label>
              <DatePicker
                selected={this.state.end_date}
                onChange={this.handleEndDateChange}
                placeholderText={this.props.end_date !== "" ? this.props.end_date : "mm/dd/yyyy"}
                dropdownMode="select"
                className="input-field inline-label w-input"
              />
            </div>
          </div>
          <div className="w-layout-grid input-fields-grid">
            <div className="div-block-25 ">
              <div className="">Shipped from</div>
              {/* <Select
                //value={this.state.address_id}
                onChange={this.handleLocationChange}
                options={this.state.options}
                className="select w-select"
                isSearchable={true}
              /> */}
              <select
                id="Status-3"
                name="Status-3"
                data-name="Status 3"
                className="select w-select"
                defaultValue={this.props.address_id}
                onChange={this.handleLocationChange}
              >
                <option value="0">Saved Locations Options</option>
                  {this.state.options}
              </select>
            </div>
            <div className="div-block-24">
              <div className="">Status</div>
              {/* <Select
                onChange={this.handleStatusChange}
                options={
                  this.props.shipping_status_id !== 0
                    ? this.props.statusOptions
                    : this.state.statusOptions
                }
                className="select w-select"
              /> */}
              <select
                id="Status-3"
                name="Status-3"
                data-name="Status 3"
                className="select w-select"
                defaultValue={this.props.shipping_status_id}
                onChange={this.handleStatusChange}
              >
                <option value="0">Status</option>
                <option value="1">Request Received</option>
                <option value="2">Packaging in Transit</option>
                <option value="3">Packaging Delivered</option>
                <option value="4">Return in Transit</option>
                <option value="5">Return Received</option>
                <option value="6">Return Request Received</option>
                <option value="8">Processed</option>
              </select>
            </div>
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={this.state.formData}
            onSubmit={this.handleSubmit}
          >
            {(props) => {
              const { values, touched, errors, handleSubmit, isValid, isSubmitting } = props;
              return (
                <div className="reset-password-box">
                  <Form className="" onSubmit={handleSubmit}>
                    <div className="w-layout-grid input-fields-grid">
                      <div className="div-block-26 ">
                        <div className="">Tracking Reference</div>
                        <Form.Group className="" controlId="">
                          <Field
                            type="text"
                            name="tracking_reference"
                            values={values.tracking_reference}
                            onChange={this.handleChange}
                            placeholder="Tracking Reference"
                            className="input-field w-input"
                          />
                          {errors.tracking_reference && touched.tracking_reference && (
                            <span className="input-feedback">{errors.tracking_reference}</span>
                          )}
                        </Form.Group>
                      </div>
                      <div className="div-block-27">
                        <div className="">Tracking Number</div>
                        <Form.Group className="" controlId="">
                          <Field
                            type="text"
                            name="tracking_number"
                            values={values.tracking_number}
                            onChange={this.handleChange}
                            placeholder="Tracking Number"
                            className="input-field w-input"
                          />
                          {errors.tracking_number && touched.tracking_number && (
                            <span className="input-feedback">{errors.tracking_number}</span>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                    <button
                      className="primary-button full form-button w-button"
                      id="submit"
                      type="submit"
                      name="submit"
                      disabled={!isValid || isSubmitting}
                    >
                      Search
                    </button>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

export default Filters;
