import React, { useState, useEffect } from "react";
import { Card, Table, Modal } from "react-bootstrap";
import Create from "./Create";
import DashboardStore from "../../../flux/DashboardStore";
import * as inventoryServices from "../../../services/inventoryServices.js";
import InventoryTableCard from "./InventoryTableCard";

export function Inventory(props) {
  const [inventoryModal, setInventoryModal] = useState(false);
  const [openInventory, setOpenInventory] = useState(null);
  const [programedInventory, setProgramedInventory] = useState(null);

  const { name } = DashboardStore.getOrganizationInfo();

  const onInvetorySuccess = (res) => {
    console.log(res);
    let open = [];
    let prg = [];
    res.map((item) => {
      if (item.program_id === 0) {
        open.push(<InventoryTableCard key={item.id} item={item} />);
      }
      if (item.program_id !== 0) {
        prg.push(<InventoryTableCard key={item.id} item={item} />);
      }
    });
    setOpenInventory(open);
    setProgramedInventory(prg);
  };

  useEffect(() => {
    inventoryServices
      .getInventory(props && props.currentUser.organization_id)
      .then(onInvetorySuccess)
      .catch((res) => {
        console.log(res);
      });
  }, []);

  return (
    <React.Fragment>
      <div id="audit_container" className="container-fluid moblyz_table">
        <h4>Inventory: {name} </h4>
        <button className="btn btn-primary" onClick={() => setInventoryModal(!inventoryModal)}>
          New Inventory
        </button>
        <Modal
          size="lg"
          show={inventoryModal}
          onHide={() => setInventoryModal(!inventoryModal)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="transparent-inner-icon">
                <h2 className="icomoon _00 print-label">&#xe953;</h2>
                <h4>Create a new Moblyz Inventory</h4>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Create setInventoryModal={setInventoryModal} />
          </Modal.Body>
        </Modal>
        <h4>Open Inventory</h4>
        <Card className="inventory-card filter_table">
          <div id="open-inventory">
            <Table responsive>
              <thead>
                <tr>
                  <th>Manufacturer</th>
                  <th>Model</th>
                  <th>Storage Size</th>
                  <th>Carrier</th>
                  <th>Reference</th>
                </tr>
              </thead>
              <tbody>{openInventory && openInventory}</tbody>
            </Table>
          </div>
        </Card>
        <h4>Programed Inventory</h4>
        <Card className="inventory-card filter_table">
          <div id="programed-inventory">
            <Table responsive>
              <thead>
                <tr>
                  <th>Manufacturer</th>
                  <th>Model</th>
                  <th>Storage Size</th>
                  <th>Carrier</th>
                  <th>Reference</th>
                </tr>
              </thead>
              <tbody>{programedInventory && programedInventory}</tbody>
            </Table>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
}
