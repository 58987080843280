import React, { Component } from 'react'
import { Card, Row, Col, Pagination } from "react-bootstrap";
import Select from "react-select";

export default class ProgramTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [
            ],
            page_num: 1,
        }
    }

    componentDidMount = () => {
        // console.log(this.props, this.state, "didMount")
        this.handleOrgOptions(this.props.orgOptions)
    }

    componentDidUpdate = (prevProps, prevState) => {
        // console.log(prevProps, prevState, this.props, "update")
        if (prevProps.orgOptions != this.props.orgOptions && this.props.orgOptions.length > 0) {
            this.handleOrgOptions(this.props.orgOptions)
        } if (prevProps.prgOptions != this.props.prgOptions && this.props.prgOptions.length > 0) {
            this.handleProgramOptions(this.props.prgOptions)
        }
    }

    handleOrgOptions = (props) => {
        let options = []
        for (let i = 0; i < props.length; i++) {
            let obj = {}
            obj["label"] = props[i].name
            obj["value"] = props[i].id
            options.push(obj)
        }
        this.setState({
            options: options
        })
    }

    handleProgramOptions = (prog) => {
        this.setState({
            options: prog
        })
    }

    onHandlePrev = () =>{
        if(this.state.page_num > 1){
            let prev = this.state.page_num - 1
            this.setState({
                page_num: prev
            },() =>{this.getTrackingHardcoded()} )
        } 
    }

    onHandleNext = () =>{
        if(this.state.page_num > 0){
            let next = this.state.page_num + 1
            this.setState({
                page_num: next
            }, () => {this.getTrackingHardcoded()})
        }
    }

    getTrackingHardcoded = (programId) => {
        // let adress_id = 0
        // let program_id = 1
        // let page_num = this.state.page_num
        // let per_page = 5
        // let sort_by = 0
        // trackingServices
        //     .getTracking(adress_id, program_id, page_num, per_page, sort_by)
        //     .then(this.onGetTrackingSuccess)
        //     .catch(this.onGetTrackingError)
    }


    render() {
        return (
            <React.Fragment>
                <Card>
                    <Card.Body>
                        {/* <Row>
                            <Col>1 of 3</Col>
                            <Col>1 of 3</Col>
                            <Col>1 of 3</Col>
                            <Col>1 of 3</Col>
                            <Col>
                            of 3
                            </Col>
                        </Row> */}


                        {/* <Row>
                            <Col>
                                <Select
                                    id="selectProgram"
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable="true"
                                    isSearchable="true"
                                    name="deviceSelected"
                                    options={this.state.options}
                                    onChange={this.props.handleChangeSelected}
                                />
                            </Col>
                            <Col md="auto">Variable width testing</Col>
                            <Col sm lg="6">
                                3 of 3
                            </Col>
                        </Row> */}
                    </Card.Body>
                </Card>
            </React.Fragment>
        )
    }
}
