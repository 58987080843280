import { EventEmitter } from "events";
import dispatcher from "./dispatcher";

// two constants for action types used to identify
// the action and respond accordingly.
// Register to register new listener
// const clientDashboardStore = new ClientDashboardStore;
// Dispatcher.register(ClientDashboardStore.handleActions.bind(ClientDashboardStore));
// or
// dispatcher.dispatch()
// this is for all actions to dispatch


class DashboardStore extends EventEmitter {
  constructor() {
    super();
    this.storeData = {
      currentUser: [],
      userToken: [],
      programs: [],
      programsDP: [],
      priceBook: [],
      addresses: [],
      snapshot: [],
      userSettings: [],
      organizationInfo: [],
      programView: [{ viewAs: 0 }],
      organizationView: [{ viewAs: 0 }],
      vendorDetails: [],
    }
    dispatcher.register(this.handleActions.bind(this));

    //Dont bind when using arrowFunction
    //this.createTokentUser = this.createTokentUser.bind(this);
    //this.createPrograms = this.createPrograms(this)
  }

  createTokentUser = (store) => {
    this.storeData.userToken.push({
      store,
    });
    this.emit("CHANGE");
  }

  storeCurrentUser(store) {
    this.storeData.currentUser.push({
      store,
    });
    this.emit("CHANGE");
  }

  storeOrgInfo(store) {
    this.storeData.organizationInfo.push(store)
  }

  createPrograms = (data) => {
    let store = data.program_of_organization
    for (let i = 0; i < store.length; i++) {
      let obj = store[i]
      this.storeData.programs.push(obj)
    }
    this.emit("CHANGE");
  }

  manipulateResProgramsDP = (data) => {
    let array = []
    for (let i = 0; i < data.length; i++) {
      let obj = data[i]
      array.push(obj)
    }
    this.storeData.programsDP.push(array)
    this.emit("CHANGE");
  }

  pushPriceBookStore = (store) => {

    for (let i = 0; i < store.length; i++) {
      let obj = {
        value: store[i].id,
        label: store[i].DeviceName,
        UnitPrice: store[i].UnitPrice
      }
      this.storeData.priceBook.push(obj)
    }
    this.emit("CHANGE");

  }

  pushAddressesStore = (res) => {
    for (let i = 0; i < res.length; i++) {
      let obj = {
        id: res[i].id,
        address1: res[i].address1,
        address2: res[i].address2,
        city: res[i].city,
        country: res[i].country,
        location_name: res[i].location_name,
        nickname: res[i].nickname,
        postal_code: res[i].postal_code,
        state: res[i].state,
      }
      this.storeData.addresses.push(obj)
    }
    this.emit("CHANGE");
  }

  pushProgramViewStore = (id) => {
    this.storeData.programView.map(element => id > 0 ? element.viewAs = parseInt(id) : id)
    this.emit("CHANGE");
  }

  pushOrganizationViewStore = (id) => {
    this.storeData.organizationView.map(element => id > 0 ? element.viewAs = parseInt(id) : id)
    this.emit("CHANGE");
  }

  manipulateResSnapshot = (res) => {
    this.storeData.snapshot.push(res)
    this.emit("CHANGESNAPSHOT");
  }

  // manipulateResOrganizationInfo = (res) => {
  //   this.storeData.organizationInfo.push(res)
  // }

  manipulateSettingsUser = (res) => {
    console.log(res, "userSEttings")
    this.storeData.userSettings.push(res)
    this.emit("CHANGEUSERSETTINGS");
  }

  manipulateVendorResponse = (res) => {
    console.log(res, "VendorResponse")
    this.storeData.vendorDetails.push(res)
    this.emit("CHANGEVENDORDETAILS")
  }

  getUserInfo() {
    return this.storeData.currentUser;
  }

  getToken() {
    return this.storeData.userToken;
  }

  getPrograms() {
    return this.storeData.programs;
  }

  getProgramsDP() {
    let lastIndex = this.storeData.programsDP.length - 1
    return this.storeData.programsDP[lastIndex];
  }

  getPriceBook() {
    return this.storeData.priceBook;
  }

  getAddresses = () => {
    return this.storeData.addresses;
  }

  getProgramView = () => {
    return this.storeData.programView[0].viewAs;
  }

  getOrgView = () => {
    return this.storeData.organizationView[0].viewAs;
  }

  getSnapshot = () => {
    let lastIndex = this.storeData.snapshot.length - 1
    return this.storeData.snapshot[lastIndex];
  }

  getOrganizationInfo = () => {
    return this.storeData.organizationInfo[0];
  }

  getUserSettings = () => {
    let lastIndex = this.storeData.userSettings.length - 1
    return this.storeData.userSettings[lastIndex];
  }

  getVendorDetails = () => {
    let lastIndex = this.storeData.vendorDetails.length - 1
    return this.storeData.vendorDetails[lastIndex];
  }


  handleActions(action) {
    switch (action.type) {
      case "FETCH_CURRENTUSER_SUCCESS":
        // console.log(action, "CurrentUser")
        this.storeCurrentUser(action.data.item);
        this.emit("CHANGE");
        break;
      case "FETCH_CURRENTUSER_FAILURE":
        this.storeCurrentUser(action.err);
        break;
      case "FETCH_TOKEN_SUCCESS":
        // console.log(action, "ActionSuccess")
        this.createTokentUser(action.data.authToken);
        this.emit("CHANGE");
        break;
      case "FETCH_TOKEN_FAILURE":
        // console.log(action.err, "Error")
        this.createTokentUser(action.err);
        break;
      case "FETCH_PROGRAMS_SUCCESS":
        this.createPrograms(action.data);
        this.emit("CHANGE");
        break;
      case "FETCH_PROGRAMS_FAILURE":
        //this.createPrograms(action.err);
        break;
      case "FETCH_PRICEBOOK_SUCCESS":
        this.pushPriceBookStore(action.data);
        this.emit("CHANGE");
        break;
      case "FETCH_PRICEBOOK_FAILURE":
        // console.log(action.err, "Error")
        this.pushPriceBookStore(action.err);
        break;
      case "FETCH_ADDRESSES_SUCCESS":
        // console.log(action, "ActionSuccess")
        this.pushAddressesStore(action.data.addresses);
        this.emit("CHANGE");
        break;
      case "FETCH_ADDRESSES_FAILURE":
        // console.log(action.err, "Error")
        this.pushAddressesStore(action.err);
        break;
      default:
      case "SET_PROGRAM_ID":
        this.pushProgramViewStore(action.id);
        break;
      case "SET_ORGANIZATION_ID":
        this.pushOrganizationViewStore(action.id);
        break;
      case "FETCH_DP_PROGRAMS_SUCCESS":
        this.manipulateResProgramsDP(action.data);
        this.emit("CHANGE");
        break;
      case "FETCH_DP_PROGRAMS_FAILURE":
        console.log(action.err, "Error")
        window.location("/login")
        //this.manipulateResProgramsDP(action.err);
        break;
      case "FETCH_SNAPSHOT_SUCCESS":
        this.manipulateResSnapshot(action.data);
        // this.emit("CHANGESNAPSHOT");
        break;
      case "FETCH_SNAPSHOT_FAILURE":
        console.log(action.err, "Error")
        //this.manipulateResProgramsDP(action.err);
        break;
      case "FETCH_USERSETTINGS_SUCCESS":
        this.manipulateSettingsUser(action.data);
        // this.emit("CHANGESNAPSHOT");
        break;
      case "FETCH_USERSETTINGS_FAILURE":
        console.log(action.err, "Error")
        //this.manipulateResProgramsDP(action.err);
        break;
      case "FETCH_VENDORDETAILS_SUCCESS":
        this.manipulateVendorResponse(action.data);
        // this.emit("CHANGESNAPSHOT");
        break;
      case "FETCH_VENDORDETAILS_FAILURE":
        console.log(action.err, "Error")
        //this.manipulateResProgramsDP(action.err);
        break;
    }
  }

}

export default new DashboardStore();
