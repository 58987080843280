import React, { useState, useContext, useEffect } from "react";
import * as inventoryServices from "../../../services/inventoryServices.js";
import { authContext } from "../../../AuthProvider";
import DashboardStore from "../../../flux/DashboardStore.js";

export function Create({ setInventoryModal }) {
  const { user } = useContext(authContext);
  const [file, setFile] = useState();

  useEffect(() => {
    const userSettings = DashboardStore.getUserSettings();
    console.log(userSettings, "userSettings");
    const organizationInfo = DashboardStore.getOrganizationInfo();
    console.log(organizationInfo, "organizationInfo");
  }, [user]);

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const csvFilteToArray = (csvFile) => {
    const lines = csvFile.split("\n");

    const result = [];
    const headers = lines[0].replace(/\s/g, "").split(",");
    for (let i = 1; i < lines.length - 1; i++) {
      const obj = {};
      const currentline = lines[i].replace(" ", "").split(",");
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j].replace(" ", "")] = currentline[j].replace("\r", ""); //removes \r from the end of the line
      }
      result.push(obj);
    }
    let payload = {
      organization_id: user.organization_id,
      program_id: 0,
      audit_id: 0,
      device_list: result,
    };
    inventoryServices
      .addInventory(payload)
      .then((res) => console.log(res))
      .catch((res) => console.log(res));
    // return result;
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = (e) => {
    e.preventDefault();

    // Headers for each column
    let headers = ["Make, Model, Storage, Carrier, Reference"];

    downloadFile({
      data: headers,
      fileName: "template.csv",
      fileType: "text/csv",
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        csvFilteToArray(csvOutput);
      };

      fileReader.readAsText(file);
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi soluta
        qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda eligendi cumque?
      </p>
      <form>
        <row>
          <p>
            1. Download the Moblyz Device ... <button onClick={exportToCsv}>Download</button>
          </p>
        </row>
        <row>
          <p>
            2. Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi
            soluta qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda
            eligendi cumque?
          </p>
        </row>
        <row>
          <p>
            3. Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi
            soluta qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda
            eligendi cumque?
            <input type={"file"} id={"csvFileInput"} accept={".csv"} onChange={handleOnChange} />
          </p>
        </row>

        <button
          onClick={(e) => {
            handleOnSubmit(e);
          }}
        >
          Save
        </button>
      </form>
    </div>
  );
}

export default Create;
