import { useLoadScript } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import Map from "./Map";

export default function WrapMap({outTracking, returnTracking}) {
  const [out_Tracking, setOut] = useState();
  const [ret_tracking_number, setRet] = useState();

  useEffect(()=>{
    setOut(outTracking)
    setRet(returnTracking)
  }, [])
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCTa5kFhOMR5nKhKwpKZFI5Ei-b0jffuL4",
    libraries: ["places"],
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map outTracking={outTracking} retTracking={returnTracking}/>;
}