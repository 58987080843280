import axios from "axios";
import * as helper from "./serviceHelper";
import * as env from "./env";

const endpoint = env.url;

let register = (payload) =>{
    const config = {
      data: payload,
      method: "POST",
      url: "https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/auth/signup",
      withCredentials: false,
      crossdomain: true,
      headers: { "Content-Type": "application/json", Accept: "application/json" }
    };
    return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
}

let getOrgsByParent = (id) => {

  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/get_orgs_by_parent?parent_id=${id}`,
    withCredentials: false,
    crossdomain: true,
    headers: {"Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let getOrganization = (organization_id) => {

  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/organization/${organization_id}`,
    withCredentials: false,
    crossdomain: true,
    headers: {"Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let getOrganizationTree = (organization_id, token) => {

  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/organization/sub_orgs/${organization_id}`,
    withCredentials: false,
    crossdomain: true,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let organizationUpdate = (payload, id, token) =>{
  const config = {
    data: payload,
    method: "POST",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/organization/${id}`,
    withCredentials: false,
    crossdomain: true,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
}

let create = (payload, token) =>{
  const config = {
    data: payload,
    method: "POST",
    url: "https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/organization",
    withCredentials: false,
    crossdomain: true,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
}

let columnVisibility = (orgId) => {

  const config = {
    method: "GET",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/column_visibility/${orgId}`,
    withCredentials: false,
    crossdomain: true,
    headers: {"Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
};

let updateColumn = (payload, token, organization_id) =>{
  const config = {
    data: payload,
    method: "POST",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/column_visibility/${organization_id}`,
    withCredentials: false,
    crossdomain: true,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
}

let updatePageViews = (payload, organization_id, token) =>{
  const config = {
    data: payload,
    method: "POST",
    url: `https://xehi-05cq-aqc1.n7.xano.io/api:KOddT8uD/settings/${organization_id}`,
    withCredentials: false,
    crossdomain: true,
    headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json", Accept: "application/json" }
  };
  return axios(config).then(helper.onGlobalSuccess).catch(helper.onGlobalError);
}

export { create, updatePageViews, getOrganizationTree, updateColumn, columnVisibility,  register, getOrgsByParent, getOrganization, organizationUpdate };