import React from "react";
import { useAuth } from "./AuthProvider";
import { Navigate } from "react-router-dom";

export const RequireAuth = ({ children }) => {
  const auth = useAuth();

  return auth.user.isLoggedIn ? (
    children
  ) : (
    <Navigate to="/not-auth"/>
  );
};