import React, { Component } from 'react'
import ReactPaginate from 'react-paginate';

class PaginatedItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageCount: 0,

        }
    }

    componentDidMount = () =>{

    }

    componentDidUpdate = (prevProps, prevState) => {
        console.log(prevProps, prevState, this.state, this.props, "update")
        if(this.state.pageCount !== this.props.pageCount){
            this.setPageCount()
        }
    }

    setPageCount = () =>{
        this.setState({
            pageCount: this.props.pageCount
        })
    }

    handlePageChange = (e) =>{
        console.log(e.selected, "handlePageChagnge")

    }


        render() {
            return (
                <React.Fragment>
                    {(this.state.pageCount > 1) ? (
                    <ReactPaginate
                        previousLabel="Prev"
                        nextLabel="Next"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={this.state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={1}
                        onPageChange={this.props.handlePageChange}
                        containerClassName="pagination"
                        activeClassName="active"
                        // forcePage={pageOffset}
                    />
                    ) : ""
                    }
                </React.Fragment>
            )
        }
}

export default PaginatedItems