import React, { Component } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import DashboardStore from "../../../flux/DashboardStore.js";
import * as DashboardActions from "../../../flux/DashboardActions.js";
import * as pricebookServices from "../../../services/pricebookServices.js";
import { Chart as ChartJS, CategoryScale, BarElement } from "chart.js";
import { Bar } from "react-chartjs-2";
import "./currentValue.css";

ChartJS.register(
  CategoryScale,

  BarElement
);

class CurrentValue extends Component {
  state = {
    priceBook: [],
    deviceSelected: {},
    data: {
      labels,
      datasets: [
        {
          label: "Condition Grades",
          data: [0, 0, 0, 0, 0],
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    },
  };

  componentDidMount = (nextProps, prevState) => {
    console.log("didMount", DashboardStore.getPriceBook().length, prevState, this.props);
    DashboardStore.on("CHANGE", this.createPriceBookAction);
    // undefined is not working instead will use length
    // if(DashboardStore.getPriceBook() === undefined){
    if (DashboardStore.getPriceBook().length === 0) {
      DashboardActions.apiCallPriceBook(this.props.currentUser.authToken);
    } else {
      this.createPriceBookAction();
    }
  };

  componentDidUpdate = (nextProps, prevState) => {
    if (
      this.state.priceBook.length === 0 &&
      this.props.currentUser !== nextProps.currentUser &&
      DashboardStore.getPriceBook().length != 0
    ) {
      DashboardActions.apiCallPriceBook(this.props.currentUser.authToken);
    }
  };

  componentWillUnmount = () => {
    DashboardStore.off("CHANGE", this.createPriceBookAction);
  };

  createPriceBookAction = (e) => {
    let priceBookOptions = DashboardStore.getPriceBook();
    this.setState({
      priceBook: priceBookOptions,
    });
  };

  onCurrentValuesSucces = (res) => {
    console.log(res, "onCurrentValuesSucces");
  };

  onCurrentValuesError = (res) => {
    console.log(res, "onCurrentValuesError");
  };

  handleChangeSelected = (selectedOption) => {
    if (selectedOption != null) {
      let gradeA = selectedOption.UnitPrice;
      let gradeB = gradeA * 0.9;
      let gradeC = gradeB * 0.75;
      let gradeD = gradeC * 0.5;
      let gradeF = gradeD * 0.05;
      let device = {
        id: parseInt(selectedOption.value),
        type: selectedOption.label,
        unitPrice: selectedOption.UnitPrice,
      };

      this.setState((prevState) => {
        return {
          ...prevState,
          data: {
            labels,
            datasets: [
              {
                label: "Condition Grades",
                data: [gradeA, gradeB, gradeC, gradeD, gradeF],
                backgroundColor: "rgba(53, 162, 235, 0.5)",
              },
            ],
          },
          deviceSelected: device,
        };
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* <h1>CurrentValue</h1> */}
        {/* <Card> */}
        <Container>
          {/* <Row>
              <Row>
                <Col>
                  <Select
                    className="col-6"
                    classNamePrefix="select"
                    onChange={this.handleChangeSelected}
                    // defaultValue={colourOptions[0]}
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    options={this.state.priceBook}
                  />
                </Col>
                <Col>
                  <p>{this.state.deviceSelected.type}</p>
                </Col>
              </Row>
              <Col>
                <Bar options={options} data={this.state.data} />
              </Col>
            </Row> */}
          <div
            id="w-node-_8142211a-2317-2ad6-5c67-30d26c162b6a-ec42405d"
            className="trackingtable-main-div _1"
          >
            <Row>
              <h4 className="heading-4 device-value-heading">
                Fair Market Value
                <br />
              </h4>
            </Row>

            <div className="device-bg">
              <div className="device-value-grid">
                <Row>
                  <Col className="grade-col">
                    <div className="column-1">
                      <div className="div-block-17 right">
                        <p className="paragraph _00 white-p">
                          Condition Grade
                          <br />
                        </p>
                      </div>
                      <div className="device-value-search device-detailed w-form">
                        {/* <form

                          > */}
                        {/* <div> */}
                        <Select
                          className=""
                          classNamePrefix="select"
                          onChange={this.handleChangeSelected}
                          // defaultValue={colourOptions[0]}
                          id="Search-mobile-device"
                          placeholder="Search for mobile device"
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={this.state.priceBook}
                        />
                        {/* <input
                                type="text"
                                className="input-field search w-input"
                                maxLength={256}
                                name="Search-mobile-device"
                                data-name="Search mobile device"
                                placeholder="Search for mobile device"
                                id="Search-mobile-device"
                              /> */}
                        {/* </div> */}
                        {/* </form> */}
                      </div>
                      <div className="device-image">
                        <div className="div-block-19">
                          <img
                            src="https://assets.website-files.com/61b79d6f6812cf659821787e/61d0984de284457d41be2cd1_iPad7.jpg"
                            loading="lazy"
                            alt=""
                            className="image-2"
                          />
                          <p className="paragraph _00 device-info">
                            {this.state.deviceSelected?.type}
                            <br />
                            {/* iPad 7th Gen 10.2" (32GB) */}
                            <br />
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col className="grade-col">
                    <div className="mini-column">
                      <div className="div-block-17">
                        <div className="div-block-18">
                          <div className="circle-grade">
                            <h4 className="heading-4 device-value gradeletter">
                              A<br />
                            </h4>
                          </div>
                          <div className="">
                            <h5 className="icomoon _00 inline device-value">
                              <br />
                            </h5>
                            <a id="AgradeInfoBtn" href="#" className="button-2 w-button" />
                          </div>
                        </div>
                      </div>
                      <div className="price-circle a">
                        <h4 className="heading-4 device-value">
                          ${Math.floor(this.state.data.datasets[0].data[0])}
                          <br />
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col className="grade-col">
                    <div className="mini-column">
                      <div className="div-block-17">
                        <div className="div-block-18">
                          <div className="circle-grade">
                            <h4 className="heading-4 device-value gradeletter">
                              B<br />
                            </h4>
                          </div>
                          <div className="">
                            <h5 className="icomoon _00 inline device-value">
                              <br />
                            </h5>
                            <a id="BgradeInfoBtn" href="#" className="button-2 w-button" />
                          </div>
                        </div>
                      </div>
                      <div className="price-circle b">
                        <h4 className="heading-4 device-value">
                          ${Math.floor(this.state.data.datasets[0].data[1])}
                          <br />
                        </h4>
                      </div>
                    </div>
                  </Col>
                  <Col className="grade-col">
                    <div className="mini-column">
                      <div className="div-block-17">
                        <div className="div-block-18">
                          <div className="circle-grade">
                            <h4 className="heading-4 device-value gradeletter">
                              C<br />
                            </h4>
                          </div>
                          <div className="">
                            <h5 className="icomoon _00 inline device-value">
                              <br />
                            </h5>
                            <a
                              id="CgradeInfoBtn"
                              data-w-id="52d6d1ee-7135-6ca1-2c1b-5b6846211ca0"
                              href="#"
                              className="button-2 w-button"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="price-circle c">
                        <h4 className="heading-4 device-value">
                          ${Math.floor(this.state.data.datasets[0].data[2])}
                          <br />
                        </h4>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
        {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. */}
        <div className="grading-criteria">
          <Row>
            <h4 className="heading-4 device-value-heading">
              Grade Criteria
              <br />
            </h4>
          </Row>
          <Row>
            <Col sm={2}>
              <h4 className="heading-4 device-value-heading">
                A
                <br />
              </h4>
            </Col>
            <Col>
              <p className="">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book.
                It has survived not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                with desktop publishing software like Aldus PageMaker including versions of Lorem
                Ipsum.
                <br />
              </p>
            </Col>
          </Row>
          <Row>
          <Col sm={2}>
              <h4 className="heading-4 device-value-heading">
                B
                <br />
              </h4>
            </Col>
            <Col>
              <p className="">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book.
                It has survived not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                with desktop publishing software like Aldus PageMaker including versions of Lorem
                Ipsum.
                <br />
              </p>
            </Col>
          </Row>
          <Row>
          <Col sm={2}>
              <h4 className="heading-4 device-value-heading">
                C
                <br />
              </h4>
            </Col>
            <Col>
              <p className="">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book.
                It has survived not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                with desktop publishing software like Aldus PageMaker including versions of Lorem
                Ipsum.
                <br />
              </p>
            </Col>
          </Row>
        </div>
        {/* </Card> */}
      </React.Fragment>
    );
  }
}

export default CurrentValue;
const labels = ["A", "B", "C", "D", "F"];

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
    },
  },
};
