// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
    return [
        {
            title: "Home",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe913;</h5>',
            to: "/home",
        },
        {
            title: "Tracking",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe935;</h5>',
            to: "/tracking",
        },
        {
            title: "Audit",
            to: "/audit",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe909;</h5>',
            htmlAfter: ""
        },
        {
            title: "Program",
            to: "/program",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe909;</h5>',
            htmlAfter: ""
        },
        {
            title: "Inventory",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe917;</h5>',
            to: "/inventory",
        },
        {
            title: "Vendors",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe917;</h5>',
            to: "/vendors",
        },
        {
            title: "Tools",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe917;</h5>',
            to: "/tools",
        },
        {
            title: "Settings",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe92d;</h5>',
            to: "/settings",
        },
        {
            title: "Support",
            htmlBefore: '<h5 class="icomoon _00 dashboard">&#xe930;</h5>',
            to: "/support",
        }
    ];
}
