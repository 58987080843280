//STAGE
//==================================================\\

// export const url = "";


//Production

export const url = "https://xehi-05cq-aqc1.n7.xano.io/api";

//==================================================\\

//DEV



//Local
//==================================================\\

//export const url = "https://localhost:3000/api";


