import React, { Component, useEffect, useState } from 'react'
import * as vendorServices from "../../../services/vendorServices.js";
import * as DashboardActions from "../../../flux/DashboardActions.js";
import VendorRow from './VendorRow.jsx';
import DashboardStore from '../../../flux/DashboardStore.js';



export function Vendors() {

    const [vendors, setVendors] = useState(null)


    const onVendorSuccess = (res) => {
        DashboardStore.manipulateVendorResponse(res)
        console.log('This is res', res)
        let content = [];
        console.log('This is content variable', content)
        /* res.map((vendor) => {
            content.push(<VendorRow key={vendor.id} name={vendor.name} description={vendor.description}/>); 
        }) */
        setVendors(res)
    }

    useEffect(() => {
        vendorServices
            .getPurchaser()
            .then(onVendorSuccess)
            .catch((res) => {
                console.log(res);
            });
    }, []);

    /* useEffect(() => {
        DashboardActions.apiCallVendorDetails()
        const data = DashboardStore.getVendorDetails()
        console.log("vendor details from dashboard store", data)
    }, []); */


    /*    if(vendors.error){
           content = <p>
               There was an error loading the vendor list.
           </p>
       }
   
       if(vendors.data){
           
       } */



    /* const onPurchaserSuccess = (res) => {
        console.log(res);
        let purchaser = [];
        res.map((item) => {
            purchaser.push(<VendorRow key={item.id} name={item.name} description={item.description} />);
        });
        setPurchasers(purchaser);
        };

    useEffect(() => {
        vendorServices
            .getPurchaser()
            .then(onPurchaserSuccess)
            .catch((res) => {
                console.log(res);
            });
        }, []); */

    return (
        <React.Fragment>
            <div id="audit_container" className="container-fluid moblyz_table">
                <h4>My Vendors</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt ligula et diam interdum volutpat nec nec lectus. Fusce sed suscipit tortor. Sed ut quam orci. Praesent quis aliquet nisl. Aliquam ac nisl nibh. Quisque hendrerit dapibus ex a tincidunt. Aenean vehicula feugiat nunc aliquam blandit. Suspendisse ipsum velit, mattis eget ullamcorper sed, laoreet a leo. Mauris vitae vulputate purus, eget aliquet mi. Quisque euismod ex odio, ut molestie ligula tincidunt in.</p>

                <h4>Processors</h4>
                <p>This is a list of vendors who will audit your devices. These are not currently pulling any data from backend!</p>


                <h4>Purchasers</h4>
                <p>This is a list of vendors who will buy your devices. Data is from backend!</p>
                {vendors?.map((item) => (
                    <div key={item.id}>
                        <VendorRow item={item} />
                    </div>
                ))}
                {/* <div> {vendors} </div> */}
            </div>
        </React.Fragment>
    )
}