import React from 'react'
import { Col, Row, Form } from "react-bootstrap";


const AuditColumnsCard = (props) => {
    const onHandleToggle = (e) => {
        props.onHandleToggle()
    }
    const onHandleUpdateColumns = (e) => {
        props.onHandleUpdateColumns()
    }
    return (
        <React.Fragment>
            <div className="modal-body">
                <div className="column_visibility">
                    {props.columns.processed_date !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="processed_date"
                                value={props.columns.processed_date}
                                onChange={props.onHandleToggle}
                                checked={props.columns.processed_date}
                                label="Processed Date"
                            />
                        </div> : ""
                    }
                    {props.columns.submitted_date !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="submitted_date"
                                value={props.columns.submitted_date}
                                onChange={props.onHandleToggle}
                                checked={props.columns.submitted_date}
                                label="Submittted Date"
                            />
                        </div> : ""
                    }
                    {props.columns.make !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="make"
                                value={props.columns.make}
                                onChange={props.onHandleToggle}
                                checked={props.columns.make}
                                label="Make"
                            />
                        </div> : ""
                    }
                    {props.columns.model !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="model"
                                value={props.columns.model}
                                onChange={props.onHandleToggle}
                                checked={props.columns.model}
                                label="Model"
                            />
                        </div> : ""
                    }
                    {props.columns.model_number !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="model_number"
                                value={props.columns.model_number}
                                onChange={props.onHandleToggle}
                                checked={props.columns.model_number}
                                label="Model Number"
                            />
                        </div> : ""
                    }
                    {props.columns.memory_size !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="memory_size"
                                value={props.columns.memory_size}
                                onChange={props.onHandleToggle}
                                checked={props.columns.memory_size}
                                label="Memory Size"
                            />
                        </div> : ""
                    }
                    {props.columns.imei !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="imei"
                                value={props.columns.imei}
                                onChange={props.onHandleToggle}
                                checked={props.columns.imei}
                                label="IMEI"
                            />
                        </div> : ""
                    }
                    {props.columns.meid !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="meid"
                                value={props.columns.meid}
                                onChange={props.onHandleToggle}
                                checked={props.columns.meid}
                                label="MEID"
                            />
                        </div> : ""
                    }
                    {props.columns.serial_number !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="serial_number"
                                value={props.columns.serial_number}
                                onChange={props.onHandleToggle}
                                checked={props.columns.serial_number}
                                label="Serial #"
                            />
                        </div> : ""
                    }
                    {props.columns.tracking_number !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="tracking_number"
                                value={props.columns.tracking_number}
                                onChange={props.onHandleToggle}
                                checked={props.columns.tracking_number}
                                label="Tracking #"
                            />
                        </div> : ""
                    }
                    {props.columns.grade !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="grade"
                                value={props.columns.grade}
                                onChange={props.onHandleToggle}
                                checked={props.columns.grade}
                                label="Grade"
                            />
                        </div> : ""
                    }
                    {props.columns.condition_notes !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="condition_notes"
                                value={props.columns.condition_notes}
                                onChange={props.onHandleToggle}
                                checked={props.columns.condition_notes}
                                label="Condition Notes"
                            />
                        </div> : ""
                    }
                    {props.columns.fmi !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="fmi"
                                value={props.columns.fmi}
                                onChange={props.onHandleToggle}
                                checked={props.columns.fmi}
                                label="FMI"
                            />
                        </div> : ""
                    }
                    {props.columns.value !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="value"
                                value={props.columns.value}
                                onChange={props.onHandleToggle}
                                checked={props.columns.value}
                                label="Value"
                            />
                        </div> : ""
                    }
                    {props.columns.battery_ratio !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="battery_ratio"
                                value={props.columns.battery_ratio}
                                onChange={props.onHandleToggle}
                                checked={props.columns.battery_ratio}
                                label="Battery Ratio"
                            />
                        </div> : ""
                    }
                    {props.columns.tracking_reference !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="tracking_reference"
                                value={props.columns.tracking_reference}
                                onChange={props.onHandleToggle}
                                checked={props.columns.tracking_reference}
                                label="Tracking Reference"
                            />
                        </div> : ""
                    }
                    {props.columns.reference !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="reference"
                                value={props.columns.reference}
                                onChange={props.onHandleToggle}
                                checked={props.columns.reference}
                                label="Reference"
                            />
                        </div> : ""
                    }
                    {props.columns.lock_status !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="lock_status"
                                value={props.columns.lock_status}
                                onChange={props.onHandleToggle}
                                checked={props.columns.lock_status}
                                label="Lock Status"
                            />
                        </div> : ""
                    }
                    {props.columns.po !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="po"
                                value={props.columns.po}
                                onChange={props.onHandleToggle}
                                checked={props.columns.po}
                                label="PO"
                            />
                        </div> : ""
                    }
                    {props.columns.battery !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="battery"
                                value={props.columns.battery}
                                onChange={props.onHandleToggle}
                                checked={props.columns.battery}
                                label="Battery"
                            />
                        </div> : ""
                    }
                    {props.columns.glass_description !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="glass_description"
                                value={props.columns.glass_description}
                                onChange={props.onHandleToggle}
                                checked={props.columns.glass_description}
                                label="Glass Description"
                            />
                        </div> : ""
                    }
                    {props.columns.lcd_description !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="lcd_description"
                                value={props.columns.lcd_description}
                                onChange={props.onHandleToggle}
                                checked={props.columns.lcd_description}
                                label="LCD Description"
                            />
                        </div> : ""
                    }
                    {props.columns.housing_description !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="housing_description"
                                value={props.columns.housing_description}
                                onChange={props.onHandleToggle}
                                checked={props.columns.housing_description}
                                label="Housing Description"
                            />
                        </div> : ""
                    }
                    {props.columns.corner_description !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="corner_description"
                                value={props.columns.corner_description}
                                onChange={props.onHandleToggle}
                                checked={props.columns.corner_description}
                                label="Corner Description"
                            />
                        </div> : ""
                    }
                    {props.columns.first_name !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="first_name"
                                value={props.columns.first_name}
                                onChange={props.onHandleToggle}
                                checked={props.columns.first_name}
                                label="First Name"
                            />
                        </div> : ""
                    }
                    {props.columns.last_name !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="last_name"
                                value={props.columns.last_name}
                                onChange={props.onHandleToggle}
                                checked={props.columns.last_name}
                                label="Last Name"
                            />
                        </div> : ""
                    }
                    {props.columns.city !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="city"
                                value={props.columns.city}
                                onChange={props.onHandleToggle}
                                checked={props.columns.city}
                                label="City"
                            />
                        </div> : ""
                    }
                    {props.columns.state !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="state"
                                value={props.columns.state}
                                onChange={props.onHandleToggle}
                                checked={props.columns.state}
                                label="State"
                            />
                        </div> : ""
                    }
                    {props.columns.phone !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="phone"
                                value={props.columns.phone}
                                onChange={props.onHandleToggle}
                                checked={props.columns.phone}
                                label="Phone"
                            />
                        </div> : ""
                    }
                    {props.columns.email !== undefined ?
                        <div>
                            <Form.Check
                                type="switch"
                                name="email"
                                value={props.columns.email}
                                onChange={props.onHandleToggle}
                                checked={props.columns.email}
                                label="Email"
                            />
                        </div> : ""
                    }
                </div>
                <Row>
                    <button className="btn btn-pill btn-primary col-3"
                        onClick={onHandleUpdateColumns}
                    >
                        Update
                    </button>
                </Row>
            </div>

        </React.Fragment>
    )
}

export default AuditColumnsCard
