import React from "react";
import PropTypes from "prop-types";
import { Navbar, NavbarBrand } from "shards-react";

import { Dispatcher, Constants } from "../../../flux";

// import "../../../../css/style.css";

class SidebarMainNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
  }

  handleToggleSidebar() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR
    });
  }

  render() {
    const { hideLogoText } = this.props;
    const logo = {
      transform: "scale(0.9)",
      marginTop: "-3em",
      zIndex: "10000"
    }
    return (
      <div className="main-navbar">
        <Navbar
          className="align-items-stretch flex-md-nowrap p-0"
          type="light"
        >
          <NavbarBrand
            className="w-100 mr-0"
            href="#"
          >
            <div className="d-table m-auto">
              <img
                id="main-logo"
                className="d-inline-block align-top mr-1"
                src="/images/Moblyz_logo_V2.svg"
                alt="Moblyz"
              />
              {!hideLogoText && (
                // <span className="d-none d-md-inline ml-1">
                //   Shards Dashboard
                // </span>
                <div className="navlogo">
                  <span className="d-none d-md-inline ml-1">
                  <img style={logo} src="" />
                </span></div>
              )}
            </div>
          </NavbarBrand>
          {/* eslint-disable-next-line */}
          <a
            className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
            onClick={this.handleToggleSidebar}
          >
            <i className="material-icons">&#xE5C4;</i>
          </a>
        </Navbar>
      </div>
    );
  }
}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false
};

export default SidebarMainNavbar;
