import React, { useContext, useEffect } from "react";
import { authContext } from "../../../AuthProvider";
import { Container, Row, Col } from "shards-react";
import MainNavbar from "../../layout/mainNavbar/MainNavbar.js";
import MainSidebar from "../../layout/mainSidebar/MainSidebar";
import "bootstrap/dist/css/bootstrap.min.css";


import "../../../../src/shards-dashboard/styles/shards-dashboards.1.1.0.min.css";



const SuperDashboard = ({children, currentUser}) => {
    const { setUser } = useContext(authContext);
    useEffect (() =>{
        setUser(currentUser)
    }, [])

return (
    <React.Fragment>

        <Container fluid>
            <Row>
                <MainSidebar currentUser={currentUser} />
                <Col
                    className="main-content p-0"
                    lg={{ size: 10, offset: 2 }}
                    md={{ size: 9, offset: 3 }}
                    sm="12"
                    tag="main"
                >
                    <MainNavbar currentUser={currentUser} />
                    <div className="page-body">
                    {children}
    
                    </div>
                    {/* <MainFooter /> */}
                    
                </Col>
            </Row>
        </Container>
       </React.Fragment>

);
}

export default SuperDashboard;