import React, { useLayoutEffect, useReducer, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-select";
import { DatePicker } from "shards-react";
import DashboardStore from "../../../flux/DashboardStore";
import DeviceAddedCard from "./DeviceAddedCard";

const initialValue = {
  program_name: "",
  description: "",
  program_type_id: 0,
  start_date: "",
  deviceChosen: {},
  quantity: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "inputText":
      return { ...state, [action.payload.key]: action.payload.value };
    case "checkBox":
      return { ...state, [action.payload.key]: action.payload.value };
    case "datePicker":
      return { ...state, [action.payload.key]: action.payload.value };
    case "deviceChosen":
        return {...state, [action.payload.key]: action.payload.value}
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

function Program() {
  const [state, dispatch] = useReducer(reducer, initialValue);
  const [devices, setDevices] = useState([]);
  const [mappedDevices, setMappedDevices] = useState([])
  const [deviceList, setDeviceList] = useState([])

  useLayoutEffect(() => {
    const devices = DashboardStore.getPriceBook();
    setDevices(devices);
  }, [devices]);

  const handleChange = (e) => {
    dispatch({
      type: "inputText",
      payload: { key: e.target.name, value: e.target.value },
    });
  };

  const handleCheckBox = (e) => {
    let checked = parseInt(e.target.value);
    dispatch({
      type: "checkBox",
      payload: { key: e.target.name, value: checked },
    });
  };

  const handleStartDateChange = (e) => {
    dispatch({
      type: "datePicker",
      payload: { key: "start_date", value: e },
    });
  };

  const handleChangeSelected = (selectedOption) =>{
    if (selectedOption != null) {
        let device = {
          id: parseInt(selectedOption.value),
          type: selectedOption.label,
        };
        dispatch({
            type: "deviceChosen",
            payload: { key: "deviceChosen", value: device}
        })
      }
  }

  const handleBtnAddDevice = (e) => {
    e.preventDefault();
    let device = {
      id: state.deviceChosen.id,
      type: state.deviceChosen.type,
      quantity: parseInt(state.quantity),
    };
    let devicesArray = [...deviceList, device];
    setDeviceList(devicesArray)
    let newArray = deviceList.map((data) => {
        return(
            <DeviceAddedCard
            key={data.id}
            device={data}
            onHandleDelete={deleteDevice}
            />
        )
    })

    setMappedDevices(newArray)
    //this.getAddedDevices(devicesArray);
    // this.setState({
    //   program_device_list: devicesArray,
    //   deviceSelected: "",
    //   quantity: 0,
    // });
    // document.getElementById("qtybox").value = 0;
    // document.getElementById("selectDevice").selectedIndex = 0;
  };

  const deleteDevice = () =>{

  }

  return (
    <React.Fragment>
      <Container>
        <div className="graph-div">
          <Formik
            initialValues={{ program_name: "", email: "" }}
            //   validationSchema={validationSchema}
            onSubmit={(values) => {
              alert(JSON.stringify(values, null, 2));
            }}
            render={({
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
              validateField,
              validateForm,
            }) => (
              <Form>
                <Row>
                  <label htmlFor="program_name">Program Name</label>
                  <div>
                    <input
                      type="text"
                      name="program_name"
                      onChange={handleChange}
                      // values={this.state.formData.program_name}
                      required
                      placeholder="Name of New Program"
                      className="input-field w-input"
                    />
                    {errors.program_name && touched.program_name && (
                      <span className="input-feedback">{errors.program_name}</span>
                    )}
                    <ErrorMessage name="program_name" />
                  </div>
                </Row>

                <br />
                <Row>
                  <Col>
                    <label for="Start-Date">Expected Start Date</label>
                    <DatePicker
                      selected={state.start_date}
                      onChange={handleStartDateChange}
                      placeholderText="mm/dd/yyyy"
                      dropdownMode="select"
                      className="input-field inline-label w-input"
                    />
                  </Col>
                  <Col>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="program_type_id"
                        required
                        id="flexRadioDefault1"
                        value={1}
                        onChange={handleCheckBox}
                      />
                      <label className="form-check-label" htmlFor="flexRadioDefault1">
                        One-Time
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="program_type_id"
                        required
                        id="flexRadioDefault2"
                        value={2}
                        onChange={handleCheckBox}
                      />
                      <label className="form-check-label" htmlFor="flexRadioDefault2">
                        On-Going
                      </label>
                    </div>
                  </Col>
                </Row>

                <hr className="mt-4 mb-4" />
                <Row>
                  <div>
                    <input
                      type="text"
                      name="description"
                      onChange={handleChange}
                      // values={this.state.formData.program_name}
                      required
                      placeholder="Program Description"
                      className="input-field w-input"
                    />
                    {errors.description && touched.description && (
                      <span className="input-feedback">{errors.description}</span>
                    )}
                    <ErrorMessage name="username" />
                  </div>
                </Row>
                <Row>
                  <Col>
                    <Select
                      id="selectDevice"
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable="true"
                      isSearchable="true"
                      name="deviceSelected"
                      options={devices}
                      onChange={handleChangeSelected}
                    />
                  </Col>
                  <Col>
                    <label>Device Quantity</label>
                    <input
                      id="qtybox"
                      type="number"
                      name="quantity"
                      required
                      onChange={handleChange}
                      placeholder="Quantity"
                      className={
                        errors.quantity && touched.quantity ? "form-control" : "form-control"
                      }
                    />
                    {errors.quantity && touched.quantity && (
                      <span className="input-feedback">{errors.quantity}</span>
                    )}
                  </Col>
                  <Col>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={handleBtnAddDevice}
                      type="button"
                    >
                      +
                    </button>
                  </Col>
                </Row>
                <hr className="mt-4 mb-4" />
              </Form>
            )}
          />
          {mappedDevices && mappedDevices}
        </div>
      </Container>
    </React.Fragment>
  );
}

export default Program;

// const validationSchema = object().shape({
//     email: string().email('Invalid email').required('Field is required'),
//     username: string()
//       .min(4, 'Must be at least 4 characters')
//       .required('Field is required'),
//   });
