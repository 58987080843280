import React from 'react'


const AuditCarasoul = (props) => {
    console.log(props.data.imei, "IMEI")

    const activeImg = (keyImg) => {
        return props.active == keyImg ? 'carousel-item active' : 'carousel-item'
    }

    return (
        <React.Fragment>
            <div className={activeImg(props.keyImg)}>
                <img src={props.data.img} className="d-block w-100" alt="..." />
                <div className="carousel-caption d-none d-md-block">
                    <p>Grade: {props.data.grade}</p>
                    <p>Serial Number: {props.data.serial_number}</p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AuditCarasoul