import React, { useEffect, useState } from 'react';
import { Card } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import sampleImage from './logo192.png';
import './vendors.css';
import DashboardStore from '../../../flux/DashboardStore';
import * as vendorServices from "../../../services/vendorServices.js";
// import { Vendors } from './Vendors';

const VendorDetails = () => {
    const { id } = useParams();

    const data = DashboardStore.getVendorDetails();

    console.log(data, "grabbing vendor details from store")

    // function isVendorId(vendor) {
    //     console.log(vendor.id, "the vendors id");
    //     console.log(id, "the id from param");
    //     return vendor.id === parseInt(id);
    // }

    // console.log(data.filter(isVendorId), "using my find id function");

    // const singleVendor = data.find(isVendorId);

    const singleVendor = data.find((item) => item.id === parseInt(id))

    console.log(singleVendor, "this is single vendor");

    // data.map((item) => {
    //     console.log(item, "item in the map");
    //     let content = [];
    //     if (item.id === id) {
    //         content.push(item);
    //     }
    //     console.log("this is content", content)
    //     return content
    // });

    // const [vendorDetails, setVendorDetails] = useState(DashboardStore.getVendorDetails());

    // console.log("these are vendor details", vendorDetails);

    // const singleVendor = vendorDetails.find((item) => item.id === { id });

    // console.log("this is single vendor", singleVendor)

    // useEffect(() => {
    //     // const data = DashboardStore.getVendorDetails()

    //     // const singleVendor = data.find((item) => item.id === id);
    //     // console.log("single vendor here", singleVendor)
    //     setVendorDetails()
    // }, [])

    // const {id, name, description, website, address} = props.vendor;

    return (
        <React.Fragment>
            <div id="audit_container" className="container-fluid moblyz_table">
                <Card>
                    <img src={sampleImage} alt="Sample Image" height="150" />
                    <h4>{ singleVendor.name }</h4>
                    <p>Description: {singleVendor && singleVendor.description}</p>
                    <p>Website: {singleVendor && singleVendor.website}</p>
                    <p>Address: {singleVendor && singleVendor.address.address1}</p>
                </Card>
            </div>  
        </React.Fragment>
    )
}
export default VendorDetails;