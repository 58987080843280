import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import DashboardStore from "./flux/DashboardStore";
import * as DashboardActions from "./flux/DashboardActions";

export const authContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const navigate = useNavigate();

  const login = (userDetails) => {
    setUser(userDetails);
    // navigate("/home");
  };

  const logout = () => {
    setUser({});
    navigate("/login");
  };

  return (
    <authContext.Provider value={{ user, setUser, login, logout }}>
      {children}
    </authContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(authContext);
};
